import {useMutation} from "react-query";
import {Credentials} from "../../models/Credentials";
import {authService} from "../../services/auth.service";
import Button from "../utils/Inputs/Button";
import React, {useState} from "react";
import {Field} from "../utils/Inputs/Field";

// let logs: Credentials = {
//     username: "Chandler Hayes",
//     password: "0000",
// };
//
// let logs2: Credentials = {
//     username: "Luz Hoeger",
//     password: "0000",
// };
export default function LoginPage() {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const login = useMutation((data: Credentials) => authService.login(data))
    return (
        <div className="h-screen">
            <div className="h-full pt-12">
                <div className="h-full flex items-center justify-center">
                    {/*<Button isLoading={loggin.isLoading} trigger={() => loggin.mutate(logs)} styles="btn-outlined m-2" label={["User 1"]}/>*/}
                    <Field type='text'
                           name='username'
                           onChange={(e) => setUsername(e.target.value)}
                           value={username}/>
                    <Field type='password'
                           name='password'
                           onChange={(e) => setPassword(e.target.value)}
                           value={password}/>
                    <Button isLoading={login.isLoading} trigger={() => login.mutate({username, password})}
                            styles="btn-outlined m-2" label={["Valider"]}/>
                </div>
            </div>
        </div>

    );
}


