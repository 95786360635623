import {Select} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {Risk} from "../../../models/Risk";
import {Site} from "../../../models/Site";
import Api from "../../../services/api.service";
import {authService} from "../../../services/auth.service";
import {Endpoints} from "../../../services/endpoints";
import MultiSelect from "../../utils/MultiSelect";
import Button from "../../utils/Inputs/Button";

const RiskDuplicateModal = React.memo(function RiskDuplicateModal({
                                                                      toggleModal,
                                                                      operationId
                                                                  }: { toggleModal: (x: any, y: any, z: any) => void, operationId: number }) {
    const api = new Api();
    const queryClient = useQueryClient();
    const user = authService.getCurrentUser();
    const [formData, setFormData] = useState<number[]>([])
    const [risks, setRisks] = useState<Risk[]>([])
    const [site, setSite] = useState<number>(user.favoriteSite ? user.favoriteSite.id : 0)
    const [installation, setInstallation] = useState<number>(0)
    const [workUnit, setWorkUnit] = useState<number>(0)
    const [operation, setOperation] = useState<number>(0)

    const {data} = useQuery<any[]>(Endpoints.RISKS + '_duplicate',
        () => api.getAll(Endpoints.RISKS), {
            onSuccess: (result: Risk[]) => setRisks(result.filter(risk => risk.operation!.id == operationId)),
        })
    const {isLoading, mutate} = useMutation((postData: Risk) => api.post(Endpoints.RISKS, postData), {
        onSuccess: () => {
            queryClient.invalidateQueries(Endpoints.RISKS)
            toggleModal({}, 'add_ressource', 'Risque(s) dupliqué(s) avec succès!')
        }
    })

    function postRisks() {
        if (data) {
            formData.forEach((id, index) => {
                let newRisk = {...data.find(risk => risk.id === id)}
                delete newRisk.id
                delete newRisk.user
                newRisk.number = data.length + index + 1;
                newRisk.operation = "api/operations/" + operation
                newRisk.dangerFamily = "api/" + Endpoints.DANGER_SUB_FAMILIES + "/" + newRisk.dangerFamily?.id
                mutate(newRisk)

            })
        }
    }

    const handleChangeMultiple = (event: React.ChangeEvent<{ value: unknown }>) => {
        const {options} = event.target as HTMLSelectElement;
        const newFormData: number[] = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                newFormData.push(parseInt(options[i].value));
            }
        }
        setFormData(newFormData);
    };

    //Queries
    const sites = useQuery<Site[]>(Endpoints.SITES + '_duplicate', () => api.getAll(Endpoints.SITES), {
        onSuccess: (result) => site === 0 && result && result[0] && setSite(result[0].id + 0)
    })

    const installations = useQuery<Site[]>([Endpoints.INSTALLATIONS + '_duplicate', site],
        () => api.getAll(Endpoints.INSTALLATIONS, [["site.id", site + ""]]), {
            enabled: site !== 0,
            onSuccess: (result) => installation === 0 && result && result[0] && setInstallation(result[0].id + 0)
        })
    const workUnits = useQuery<Site[]>([Endpoints.WORK_UNIT + '_duplicate', installation],
        () => api.getAll(Endpoints.WORK_UNIT, [["installation.id", installation + ""]]), {
            enabled: installation !== 0,
            onSuccess: (result) => workUnit === 0 && result && result[0] && setWorkUnit(result[0].id + 0)
        })
    const operations = useQuery<Site[]>([Endpoints.OPERATIONS + '_duplicate', workUnit],
        () => api.getAll(Endpoints.OPERATIONS, [["workUnit.id", workUnit + ""]]), {
            enabled: workUnit !== 0,
            onSuccess: (result) => operation === 0 && result && result[0] && setOperation(result[0].id + 0)
        })

    useEffect(() => {
        setInstallation(0);
        setWorkUnit(0);
        setOperation(0);
    }, [site])

    useEffect(() => {
        setWorkUnit(0);
        setOperation(0);
    }, [installation])

    useEffect(() => {
        setOperation(0);
    }, [workUnit])

    const selectsProps = [
        {
            name: "site",
            collection: sites,
            item: site,
            setter: setSite,
            placeHolder: false,
            label: "Choisissez un site"
        },
        {
            name: "installation",
            collection: installations,
            item: installation,
            setter: setInstallation,
            enabler: site === 0,
            placeHolder: false,
            label: "Choisissez une installation"
        },
        {
            name: "workUnit",
            collection: workUnits,
            item: workUnit,
            setter: setWorkUnit,
            enabler: site === 0 || installation === 0,
            placeHolder: false,
            label: "Choisissez une unité de travail"
        },
        {
            name: "operation",
            collection: operations,
            item: operation,
            setter: setOperation,
            enabler: site === 0 || installation === 0 || workUnit === 0,
            placeHolder: false,
            label: "Choisissez une opération"
        }
    ]

    return (
        <>
            <h1 className="font-bold text-2xl text-center mt-5">Duplication de risques</h1>
            <h3 className="font-semibold text-lg mt-5">Choix de la destination:</h3>
            <MultiSelect selects={selectsProps} cols={"grid-col-2"}/>
            <h3 className="font-semibold text-lg mt-10">Risque(s) à dupliquer:</h3>
            <p className="text-sm">Les filtres de l'écran précédent s'appliquent ici.</p>
            <Select
                multiple
                native
                className="input border-0"
                value={formData}
                onChange={handleChangeMultiple}
                inputProps={{
                    id: 'select-multiple-native',
                }}
            >
                {data && risks.map((option) => (
                    <option key={option.id} value={option.id}>
                        {option.id} - {option.operation?.name} - {option.dangerFamily?.name}
                    </option>
                ))}
            </Select>
            <div className="flex justify-end mx-5 mt-8">
                <Button disabled={formData.length === 0} isLoading={isLoading} trigger={postRisks}
                        label={["Dupliquer"]}/>
            </div>
        </>
    );
})

export default RiskDuplicateModal;
