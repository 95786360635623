import React from 'react'
import {UseMutateFunction, useMutation, useQueryClient} from 'react-query';
import {PathType} from '../../helpers/PathType';
import {WarningIcon} from '../../Icons';
import Api from '../../services/api.service';
import Button from '../utils/Inputs/Button';
import {toggleModalType} from './Modal';

export interface ModalRemoveProps {
    path: PathType;
    item: any;
    label: string;
    children: JSX.Element | string
    toggleModal: toggleModalType;
}

export default function RemoveModal({item, path, label, children, toggleModal}: ModalRemoveProps) {
    const api = new Api();
    const queryClient = useQueryClient();
    const {isLoading, mutate} = useMutation(() => api.remove(path, item.id), {
        onSuccess: () => queryClient.invalidateQueries(path).then(() => toggleModal({}, 'delete_ressource', 'Ressource supprimée avec succès!')),
        onError: () => toggleModal({}, 'delete_ressource', 'Suppression impossible', 'error')
    });

    return (
        <div className="text-center">
            <DeleteHeaderModal label={label}>
                {children}
            </DeleteHeaderModal>
            <DeleteActionsModal disabled={false} toggleModal={toggleModal} isLoading={isLoading} mutate={mutate}/>
        </div>
    );
}


interface HeaderModalProps {
    method?: string;
    label: string;
    children: any;
}

export function DeleteHeaderModal({label, children}: HeaderModalProps) {
    return (
        <><h2 className="text-3xl mb-3">
            Suppression de <span className="font-semibold">{label}</span>
        </h2>
            <div className="w-max h-max mx-auto mb-3">
                <WarningIcon/>
            </div>
            <p className="mb-9 max-w-md">{children}</p>
        </>
    );
}

interface ActionModalProps {
    toggleModal: () => void;
    isLoading: boolean;
    method?: string;
    mutate: UseMutateFunction
    disabled?: boolean;
}

export function DeleteActionsModal({toggleModal, isLoading, mutate, disabled}: ActionModalProps) {
    return (
        <div className="flex justify-between">
            <Button trigger={toggleModal} label={["Annuler"]}/>
            <Button trigger={mutate} disabled={disabled} isLoading={isLoading} styles="btn-outline"
                    label={["Supprimer"]}/>
        </div>
    )
}
