import React, { useEffect, useState } from "react";
import "../../index.css";
import { ArrowRightIcon } from "../../Icons";
import { initPathName } from "../../helpers/Helpers";

export default function Toolbar({ path }: { path: string }) {
    const [pathName, setPathName] = useState(initPathName())

    useEffect(() => {
        setPathName(path !== "" ? path : initPathName())
    }, [path])

    return (
        <div className="w-full h-1/5 border-b border-gray-light text-4xl flex flex-row items-center">
            <div className="flex items-end">
                <h2 className="ml-20">Accueil</h2>
                <div className="text-gray-dark">
                    <ArrowRightIcon />
                </div>
                <h3>{pathName}</h3>
            </div>
        </div>
    );
}
