import {FieldTextArea} from "../../utils/Inputs/FieldTextArea";
import React from "react";
import {Rb, Ri, Rr} from "../../../models/Risk";
import IgnitionSources from "./IgnitionSource";
import {Prefix} from "../../../models/Prefix";

export interface RiskAtexProps {
    risk: Rb | Ri | Rr | any,
    setRisk: (value: Rb | Ri | Rr | any) => void,
    slug: Prefix;
}

export default function RiskStepAtex({risk, setRisk, slug}: RiskAtexProps) {
    return <>
        <div className="m-4">
            <FieldTextArea
                name='rbAtexZoning'
                onChange={(e) => setRisk({...risk, [slug + 'AtexZoning']: e.target.value})}
                value={risk[slug + 'AtexZoning'] ?? ''}>
                Zone ATEX
            </FieldTextArea>
            <p className="text-xs m-4">Zone 0 : ATEX en permanence, pendant de longues périodes ou fréquemment; Zone 1 :
                ATEX susceptible de se
                présenter occasionnelement en fonctionnement normal; Zone 2 : ATEX non susceptible de se présenter en
                fonctionnement normal ou n'est que de courte durée, s'il advient qu'elle se présente néanmoins et Hors
                zone</p>
        </div>

        <div className="m-4">
            <FieldTextArea
                name='rbPersistence'
                onChange={(e) => setRisk({...risk, [slug + 'Persistence']: e.target.value})}
                value={risk[slug + 'Persistence'] ?? ''}>
                Persistance
            </FieldTextArea>
            <p className="text-xs m-4">Temps durant lequel l'atmosphère explosive continue à être potentiellement
                présente
                après l'arrêt de la
                tâche
                générant l'ATEX. Cette information permet de définir la durée après laquelle la zone ATEX disparait et
                qu'il
                n'y a plus de restriction sur l'utilisation du matériel.</p>
        </div>

        <IgnitionSources risk={risk} setRisk={setRisk} slug={slug}/>
    </>
}
