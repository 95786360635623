import React, {MouseEvent} from "react";
import Api from "../../services/api.service";
import {useMutation, useQueryClient} from "react-query";
import {authService} from "../../services/auth.service";
import {displayCell} from "../../helpers/cellsDisplayer";
import {DataColumn} from "../../models/DataColumn";
import {PathType} from "../../helpers/PathType";
import {TableCell, TableRow} from "@material-ui/core";
import {CompanyEntity} from "../../models/User";
import {Endpoints} from "../../services/endpoints";

interface Props {
    rows: CompanyEntity[] | any;
    columns: DataColumn[];
    page: number;
    rowsPerPage: number;
    selectedItem: number;
    handleAction: (x: MouseEvent<HTMLDivElement>, y: CompanyEntity) => void;
    path: PathType;
    setSelectedItem: (x: number) => void;
    order: 'asc' | 'desc';
    orderBy: string;
    selectedRow?: number;
    changeColor?: (index: number) => void;
}

export default function TableTd({
                                    rows,
                                    columns,
                                    page,
                                    rowsPerPage,
                                    selectedItem,
                                    handleAction,
                                    path,
                                    setSelectedItem,
                                    order,
                                    orderBy,
                                    selectedRow,
                                    changeColor
                                }: Props) {
    console.log(rows);
    const api = new Api();
    const user = authService.getCurrentUser();
    const queryClient = useQueryClient();
    const {
        mutate: mutateFav,
        isLoading: favIsLoading,
        error: favIsError
    } = useMutation((postData: any) => api.patch(Endpoints.USERS, postData, user.id), {
        onSuccess: (result) => localStorage.setItem('user', JSON.stringify({...result, token: user.token}))
    });
    const {
        mutate: mutateActive,
        isLoading: activeIsLoading
    } = useMutation((postData: any) => api.patch(Endpoints.DANGER_CONFIGURATION, {active: postData.value}, postData.id), {
        onSuccess: () => {
            queryClient.invalidateQueries(Endpoints.DANGER_SUB_FAMILIES)
        }
    });

    function onFavClick(id: number) {
        let obj = null;
        switch (path) {
            case PathType.SITES:
                obj = {favoriteSite: "api/sites/" + id};
                break;
        }
        if (obj) {
            mutateFav(obj);
        }
        !favIsLoading && !favIsError && setSelectedItem(id);
    }

    function onActiveClick(value: boolean, id: number) {
        mutateActive({value: value, id: id})
    }

    type Order = 'asc' | 'desc';

    function getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key,
    ): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {

        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    function descendingComparator<T>(el1: T, el2: T, orderBy: keyof T) {
        if (el2[orderBy] < el1[orderBy]) {
            return -1;
        }
        if (el2[orderBy] > el1[orderBy]) {
            return 1;
        }
        return 0;
    }

    return (
        rows &&
        stableSort(rows, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row: any, index: number) => {
            // Specific style for archived risk
            let style = 'cursor-pointer';
            style += selectedRow === index ? ' bg-blue-light-50' : '';
            style += row && row.endDate && path === PathType.RISKS ? ' bg-gray-light' : '';

            return (

                <TableRow onClick={() => changeColor ? changeColor(index) : null} className={style} hover
                          role="checkbox"
                          tabIndex={-1}
                          key={row.id}>
                    {columns.map((column, index) => {
                        let value = displayCell(column, row, favIsLoading || activeIsLoading, selectedItem, onFavClick, onActiveClick, handleAction, path);
                        return (
                            !column.hide &&
                            <TableCell className={'p-1'} padding={"none"} key={column.id + '-' + index}
                                       align={column.align}>
                                {value}
                            </TableCell>
                        );
                    })}
                </TableRow>
            );
        })
    )
}
