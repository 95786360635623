import {DataColumn} from "../../models/DataColumn";
import {align, minWidth, required, type, modalTab, tab} from "../DataColumns/ColumnsConstants";
import { addActionToAllTab, groupBy } from "../Helpers";

const contractTypes = [
    {name: 'CDD', id: 'cdd'},
    {name: 'CDI', id: 'cdi'}
];

const sexe = [
    {name: 'Homme', id: 'male'},
    {name: 'Femme', id: 'female'},
    {name: 'Autre', id: 'other'},
]

const workCondition = [
    {name: 'Temps complet', id: 'fullTime'},
    {name: 'Temps partiel', id: 'partTime'},
    {name: 'Non renseigné', id: 'unknowned'}
]

const workerCategory = [
    {name: "Sans objet", id: "purposeless"},
    {name: "Femme enceinte", id: "pregnantWoman"},
    {name: "Jeune travailleur âgé de moins de 16 ans", id: "youngWorkerUnder16Years"},
    {name: "Jeune travailleur âgé de moins de 18 ans", id: "youngWorkerUnder18Years"},
    {name: "Mère allaitante", id: "nursingMother"},
    {name: "Mère dans les 6 mois suivant l'accouchement", id: "motherWithin6MonthsAfterChildBirth"},
    {name: "Salarié temporaire", id: "temporaryEmployee"},
    {name: "Salarié titulaire d'un CDD", id: "fixedTermContractEmployee"},
    {name: 'Non renseigné', id: 'unknowned'}
]

export const employeeColumns: DataColumn[] = [
    {id: "favorite", label: "Favori", minWidth: 100, align, type: 'favorite'},
    {id: "lastName", label: "Nom", minWidth, type, tab, required, modalTab},
    {id: "firstName", label: "Prénom", minWidth, type, required, tab, modalTab},
    {id: "gender", label: "Sexe", minWidth, type: "select", tab, modalTab, data: sexe},
    {id: "birthDate", label: "Date de naissance", minWidth, type: "date", tab, modalTab},
    {id: "nationality", label: "Nationalité", minWidth, type, tab, modalTab},
    {id: "workTitleType", label: "Type de titre de travail", minWidth, type, tab, modalTab},
    {id: "workTitleNb", label: "N° du titre de travail", minWidth, type, tab, modalTab},
    {id: "address", label: "Adresse", minWidth, type, tab, modalTab},
    {id: "job", label: "Emploi", minWidth, type, tab: 2, modalTab: 2},
    {id: "qualification", label: "Qualification", minWidth, type, tab: 2, modalTab: 2},
    {id: "coefficient", label: "Coefficient", minWidth, type: "number", tab: 2, modalTab: 2},
    {id: "hiringDate", label: "Date d'embauche", minWidth, type: "date", tab: 2, modalTab: 2},
    {id: "startDate", label: "Date d'entrée", minWidth, type: "date", tab: 2, modalTab: 2},
    {id: "endDate", label: "Date de sortie", minWidth, type: "date", tab: 2, modalTab: 2},
    {id: "contractType", label: "Type de contrat", minWidth, type: 'select', tab: 2, modalTab: 2, data: contractTypes},
    {id: "workCondition", label: "Condition de travail", minWidth, type: "select", tab: 2, modalTab: 2, data: workCondition},
    {
        id: "workerCategory",
        label: "Catégorie de travailleurs soumis à certaines dispositions",
        minWidth,
        type: "select",
        tab: 2,
        modalTab: 2,
        data: workerCategory
    },
    {id: "service.name", label: "Service / Département", minWidth, required, type: "relation", modalTab: 2, tab: 2},
    {
        id: "temporaryWorkCompanyContact",
        label: "Coordonnées de l'entreprise de travail temporaire",
        minWidth,
        type: "textarea",
        tab: 3,
        modalTab: 3
    },
    {
        id: "employerGroupInfos",
        label: "Dénomination et adresse du groupement d'employeurs en cas de mise à disposition du personnel",
        minWidth,
        type,
        tab: 3,
        modalTab: 3
    },
    {id: "year", label: "Année", minWidth, type, modalTab: 4},
    {id: "annualWorkingTime", label: "Temps de travail annuel", minWidth, type, tab: 4, modalTab: 4},
    {
        id: "timeSpentPercentage",
        label: "Pourcentage de temps de présence",
        minWidth,
        type: "number",
        tab: 4,
        modalTab: 4
    },
    {
        id: "cumulativeAbsenceTime",
        label: "Nombre d'heure cumulée dans l'année",
        minWidth,
        type: "number",
        tab: 4,
        modalTab: 4
    },
    {
        id: "nightShiftsNb",
        label: "Nombre de travail de nuit par année",
        minWidth,
        type: "number",
        tab: 4,
        modalTab: 4
    },
    {
        id: "alternatingShiftsNb",
        label: "Nombre de travail en équipe alternante par année",
        minWidth,
        type: "number",
        tab: 4,
        modalTab: 4
    },
    {id: "action", label: "Action", minWidth: 100, align, type: "action"},
];

const action = employeeColumns.find(el => el.id === 'action');
const object = groupBy(employeeColumns, 'tab');
export const employeeColumnsTab = addActionToAllTab(object, action);
