import React from "react";

interface FieldRadioProps {
    name?: string;
    value: any;
    onChange: (e: any) => void;
    children: any;
    className?: string;
    checked: boolean;
}

export function FieldRadio({ name = "", value, onChange, children, checked, className = "" }: FieldRadioProps) {
    return (
        <span className="flex items-center mr-2">
            <input type="radio" className={className + " cursor-pointer"} id={name} onChange={onChange} value={value} checked={checked} />
            <label className="text-xl mx-1" htmlFor={name}>
                {children}
            </label>
        </span>
    );
}
