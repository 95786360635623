import {useState} from "react";

export const useSelectedRow = () => {
    const [selectedRow, setSelectedRow] = useState<number | undefined>();

    function changeColor(index: number) {
        if (index !== undefined) {
            setSelectedRow(index);
        }
    }

    return {
        selectedRow,
        changeColor
    }
}
