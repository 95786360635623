import {ErrorIcon} from "../../Icons";
import React from "react";

export function ErrorQuery({children}: { children: string }) {
    return (
        <div className="flex justify-center items-center h-4/5">
            <div className="text-xl flex items-end justify-center">
                <ErrorIcon/>
                <p>{children}</p>
            </div>
        </div>
    );
}
