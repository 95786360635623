import React from "react";

interface FieldProps {
    name: string;
    type: string | undefined;
    value: any;
    className?: string
    onChange: (e: any) => void;
    children?: any;
    step?: number;
}

export const Field = ({name, type, value, className = 'input', onChange, children = '', step = 1}: FieldProps) => {
    return (
        <div>
            <label className="text-xl" htmlFor={name}>{children}</label>
            <input step={step} type={type} className={className} name={name} onChange={onChange} value={value}/>
        </div>
    )
}
