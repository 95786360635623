import React, {useEffect, useState} from "react";
import {cleanColumns, formRequirementValidation} from "../../helpers/Helpers";
import ModalTabs from "./ModalTabs";
import {methodType, ModalEditProps, post} from "./Modal";
import {ActionsModal, HeaderModal} from "../utils/ActionsModal";
import {Employee} from "../../models/Employee";
import {Tab} from "../../models/Tab";
import {employeeColumns} from "../../helpers/DataColumns/EmployeeColumns";
import {useLoadRelation} from "../hooks/useLoadRelation";
import {Endpoints} from "../../services/endpoints";
import {authService} from "../../services/auth.service";
import {LoaderIcon} from "../../Icons";
import RemoveModal from "./RemoveModal";


const tabs: Tab[] = [
    {name: "Fiche personnel*", index: 1},
    {name: "Fiche contrat*", index: 2},
    {name: "Fiche employeur", index: 3},
    {name: "Temps de travail", index: 4},
]

function postToFormEmployee(item: Employee, method: methodType): {} {
    if (method === 'add') {
        return {};
    }
    const temp = {...item, 'service.name': item.service.id};
    delete temp.service;
    return temp;
}

export default function EmployeesModal({item, path, method, toggleModal}: ModalEditProps) {
    switch (method) {
        case "edit":
        case "add":
            return <EditModal item={item} path={path} toggleModal={toggleModal} method={method}/>;
        case "delete":
            return <RemoveModal label={item?.firstName + ' ' + item?.lastName} item={item} path={path}
                                toggleModal={toggleModal}>
                Êtes-vous sur de vouloir supprimer cet employé ?
            </RemoveModal>;
        default:
            return null;
    }
}


function EditModal({item, path, toggleModal, method}: ModalEditProps) {
    const columns = cleanColumns([...employeeColumns]);
    const employee: Employee = item;
    const user = authService.getCurrentUser();

    const {
        isLoading,
        formData,
        setFormData
    } = useLoadRelation(path, Endpoints.SERVICES, [["user.id", user?.id + ""]], columns, 'service.name', method, item, 'service', postToFormEmployee(item, method));
    const {isLoading: isPostLoading, mutate} = post(path, formData, item?.id, toggleModal, method);

    const [isValidForm, setIsValidForm] = useState<boolean>(false);

    useEffect(() => {
        setIsValidForm(formRequirementValidation(columns, formData))
    }, [columns, formData]);


    if (!isLoading) {
        return (
            <div>
                <HeaderModal method={method} label={employee.lastName + '' + employee.firstName}>
                    Ajout d'un employé
                </HeaderModal>
                <ModalTabs tabs={tabs} inputs={columns} formData={formData} setFormData={setFormData} grid={true} />
                <ActionsModal disabled={!isValidForm} toggleModal={toggleModal} isLoading={isPostLoading}
                              method={method}
                              mutate={mutate}/>
            </div>
        );
    }


    return (
        <div>
            <LoaderIcon/>
            <p>Chargement...</p>
        </div>
    );

}
