import {Rb, Ri, Risk, Rr} from "../models/Risk";
import {DataColumn} from "../models/DataColumn";
import {DangerSubFamily} from "../models/DangerSubFamily";
import {ChemicalRisk} from "../models/ChemicalRisk";
import {Prefix} from "../models/Prefix";
import {contactClasses, frequenciesCriteria, quantitiesCriteria, ratingsCriteria} from "./DataColumns/RiskColumns";
import {getAtexProbability, getChemicalProbability} from "./cellsDisplayerFunctions";
import {RiskExposition} from "../models/RiskExposition";

export const MATRIX_POTENTIAL_EXPOSITION: any = {
    5: [4, 4, 4, 4],
    4: [3, 4, 4, 4],
    3: [3, 3, 3, 4],
    2: [2, 2, 2, 2],
    1: [1, 1, 1, 1],
}

export const MATRIX_CHEMICAL_RB: any = {
    4: [1000, 10000, 100000, 1000000],
    3: [300, 3000, 30000, 300000],
    2: [100, 1000, 10000, 100000],
    1: [30, 300, 3000, 30000],
}

export const MATRIX_CUTANEE: any = {
    1: [1, 1, 2, 2],
    2: [1, 2, 3, 3],
    3: [2, 3, 3, 4],
    4: [2, 3, 4, 4],
}

export const MATRIX_ATEX: any = {
    z0: [1, 3, 4, 4],
    z1: [1, 2, 3, 4],
    z2: [1, 2, 2, 3],
    hz: [1, 1, 1, 1],
}

export function pick(o: { [x: string]: any; hasOwnProperty: (arg0: any) => any; }, ...fields: any[]) {
    return fields.reduce((a, x) => {
        if (o.hasOwnProperty(x)) a[x] = o[x];
        return a;
    }, {});
}

export function getRiFromRisk(item: Risk): Ri {
    return pick(item, 'riInfos', 'riOrgaPrev', 'riOrgaProt', 'riCollPrev', 'riMasterPrev', 'riMasterProt',
        'riCollProt', 'riIndivPrev', 'riIndivProt', 'riFrequency', 'riIntensity', 'riAtexZoning', 'riPersistence', 'ignitionSources'
    );
}

export function getRrFromRisk(item: Risk): Rr {
    return pick(item, 'rrInfos', 'rrOrgaPrev', 'rrOrgaProt', 'rrCollPrev', 'rrMasterPrev', 'rrMasterProt',
        'rrCollProt', 'rrIndivPrev', 'rrIndivProt', 'rrFrequency', 'rrIntensity', 'rrAtexZoning', 'rrPersistence', 'ignitionSources'
    );
}

export function getRbFromRisk(item: Risk): Rb {
    return pick(item, 'rbInfos', 'rbFrequency', 'rbIntensity', 'rbProbability', 'rbGravity', 'rbAtexZoning', 'rbPersistence', 'ignitionSources');
}

export function getNewPorG(value: number, master: number, matrix: { value: number[][] }): number {
    const size = matrix.value.length;
    return matrix.value[size - value][master - 1];
}

export function getRiskFromItem(item: any, number?: number): Risk {
    return {
        //@ts-ignore
        operation: 'api/operations/' + item.operation.id,
        //@ts-ignore
        dangerFamily: 'api/danger_sub_families/' + item.dangerFamily.id,
        description: item.description,
        workingCondition: item.workingCondition,
        exposition: item.exposition,
        startDate: item.startDate,
        rbInfos: item.rbInfos,
        rbIntensity: Number(item.rbIntensity),
        rbFrequency: Number(item.rbFrequency),
        rbGravity: Number(item.rbGravity),
        rbProbability: Number(item.rbProbability),
        riInfos: item.riInfos,
        riOrgaPrev: item.riOrgaPrev,
        riOrgaProt: item.riOrgaProt,
        riCollPrev: item.riCollPrev,
        riCollProt: item.riCollProt,
        riIndivPrev: item.riIndivPrev,
        riIndivProt: item.riIndivProt,
        riFrequency: Number(item.riFrequency),
        riIntensity: Number(item.riIntensity),
        riMasterPrev: Number(item.riMasterPrev),
        riMasterProt: Number(item.riMasterProt),
        rrInfos: item.rrInfos,
        rrOrgaPrev: item.rrOrgaPrev,
        rrOrgaProt: item.rrOrgaProt,
        rrCollPrev: item.rrCollPrev,
        rrCollProt: item.rrCollProt,
        rrIndivPrev: item.rrIndivPrev,
        rrIndivProt: item.rrIndivProt,
        rrFrequency: Number(item.rrFrequency),
        rrIntensity: Number(item.rrIntensity),
        rrMasterPrev: Number(item.rrMasterPrev),
        rrMasterProt: Number(item.rrMasterProt),
        number: number ? number : 0
    };
}

export function getChemicalRisk(item: any, slug: Prefix): ChemicalRisk {
    return item.chemicalRisk.find((r: ChemicalRisk) => r.slug === slug);
}

function getParent(id: string, riskColumnsSpan: DataColumn[], tab: number) {
    switch (id) {
        // TAB 1 : IDENTIFICATION DES DANGERS
        case 'operation.workUnit.number' :
            return getColumn(riskColumnsSpan, 'ut');
        case 'operation.workUnit.name' :
            return getColumn(riskColumnsSpan, 'ut');
        case 'operation.number' :
            return getColumn(riskColumnsSpan, 'operation');
        case 'operation.name' :
            return getColumn(riskColumnsSpan, 'operation');
        case 'rb' :
            return getColumn(riskColumnsSpan, 'risk');
        case 'ri' :
            if (tab === 1) {
                return getColumn(riskColumnsSpan, 'risk');
            } else {
                return getColumn(riskColumnsSpan, 'riCotation');
            }
        case 'rr' :
            if (tab === 1) {
                return getColumn(riskColumnsSpan, 'risk');
            } else {
                return getColumn(riskColumnsSpan, 'rrCotation');
            }

        // TAB 2 : RISQUE BRUT
        case 'rbFrequency' :
            return getColumn(riskColumnsSpan, 'rbDifficulty');
        case 'rbIntensity' :
            return getColumn(riskColumnsSpan, 'rbDifficulty');
        // TAB 3 : RISQUE INITIAL
        case 'riType' :
            return getColumn(riskColumnsSpan, 'measureRi');
        case 'riOrga' :
            return getColumn(riskColumnsSpan, 'measureRi');
        case 'riColl' :
            return getColumn(riskColumnsSpan, 'measureRi');
        case 'riIndiv' :
            return getColumn(riskColumnsSpan, 'measureRi');

        case 'riFrequency' :
            return getColumn(riskColumnsSpan, 'riDifficulty');
        case 'riIntensity' :
            return getColumn(riskColumnsSpan, 'riDifficulty');

        case 'riMaster' :
            return getColumn(riskColumnsSpan, 'riCotation');
        case 'riProbability' :
            return getColumn(riskColumnsSpan, 'riCotation');
        case 'riGravity' :
            return getColumn(riskColumnsSpan, 'riCotation');
        // TAB 4 : RISQUE RESIDUEL
        case 'rrType' :
            return getColumn(riskColumnsSpan, 'measureRr');
        case 'rrOrga' :
            return getColumn(riskColumnsSpan, 'measureRr');
        case 'rrColl' :
            return getColumn(riskColumnsSpan, 'measureRr');
        case 'rrIndiv' :
            return getColumn(riskColumnsSpan, 'measureRr');

        case 'rrFrequency' :
            return getColumn(riskColumnsSpan, 'rrDifficulty');
        case 'rrIntensity' :
            return getColumn(riskColumnsSpan, 'rrDifficulty');

        case 'rrMaster' :
            return getColumn(riskColumnsSpan, 'rrCotation');
        case 'rrProbability' :
            return getColumn(riskColumnsSpan, 'rrCotation');
        case 'rrGravity' :
            return getColumn(riskColumnsSpan, 'rrCotation');

    }
}

function getBrothers(id: string, columns: DataColumn[], tab: number) {
    let brothers = []
    switch (id) {
        // TAB 1 : IDENTIFICATION DES DANGERS
        case 'operation.workUnit.number' :
            brothers.push(getColumn(columns, 'operation.workUnit.name'));
            return brothers;
        case 'operation.workUnit.name' :
            brothers.push(getColumn(columns, 'operation.workUnit.number'));
            return brothers;
        case 'operation.number' :
            brothers.push(getColumn(columns, 'operation.name'));
            return brothers;
        case 'operation.name' :
            brothers.push(getColumn(columns, 'operation.number'));
            return brothers;
        case 'rb' :
            brothers.push(getColumn(columns, 'ri'));
            brothers.push(getColumn(columns, 'rr'));
            return brothers;
        case 'ri' :
            if (tab === 1) {
                brothers.push(getColumn(columns, 'rb'));
                brothers.push(getColumn(columns, 'rr'));
                return brothers;
            } else {
                brothers.push(getColumn(columns, 'riMaster'));
                brothers.push(getColumn(columns, 'riProbability'));
                brothers.push(getColumn(columns, 'riGravity'));
                return brothers;
            }
        case 'rr' :
            if (tab === 1) {
                brothers.push(getColumn(columns, 'rb', tab));
                brothers.push(getColumn(columns, 'ri', tab));
                return brothers;
            } else {
                brothers.push(getColumn(columns, 'rrMaster'));
                brothers.push(getColumn(columns, 'rrProbability'));
                brothers.push(getColumn(columns, 'rrGravity'));
                return brothers;
            }

        // TAB 2 : RISQUE BRUT
        case 'rbFrequency' :
            brothers.push(getColumn(columns, 'rbIntensity'));
            return brothers;
        case 'rbIntensity' :
            brothers.push(getColumn(columns, 'rbFrequency'));
            return brothers;
        // TAB 3 : RISQUE INITIAL
        case 'riType' :
            brothers.push(getColumn(columns, 'riOrga'));
            brothers.push(getColumn(columns, 'riColl'));
            brothers.push(getColumn(columns, 'riIndiv'));
            return brothers;
        case 'riOrga' :
            brothers.push(getColumn(columns, 'riType'));
            brothers.push(getColumn(columns, 'riColl'));
            brothers.push(getColumn(columns, 'riIndiv'));
            return brothers;
        case 'riColl' :
            brothers.push(getColumn(columns, 'riType'));
            brothers.push(getColumn(columns, 'riOrga'));
            brothers.push(getColumn(columns, 'riIndiv'));
            return brothers;
        case 'riIndiv' :
            brothers.push(getColumn(columns, 'riType'));
            brothers.push(getColumn(columns, 'riOrga'));
            brothers.push(getColumn(columns, 'riColl'));
            return brothers;
        case 'riFrequency' :
            brothers.push(getColumn(columns, 'riIntensity'));
            return brothers;
        case 'riIntensity' :
            brothers.push(getColumn(columns, 'riFrequency'));
            return brothers;
        case 'riMaster' :
            brothers.push(getColumn(columns, 'riProbability'));
            brothers.push(getColumn(columns, 'riGravity'));
            brothers.push(getColumn(columns, 'ri', tab));
            return brothers;
        case 'riProbability' :
            brothers.push(getColumn(columns, 'riMaster'));
            brothers.push(getColumn(columns, 'riGravity'));
            brothers.push(getColumn(columns, 'ri', tab));
            return brothers;
        case 'riGravity' :
            brothers.push(getColumn(columns, 'riMaster'));
            brothers.push(getColumn(columns, 'riProbability'));
            brothers.push(getColumn(columns, 'ri', tab));
            return brothers;

        // TAB 4 : RISQUE RESIDUEL
        case 'rrType' :
            brothers.push(getColumn(columns, 'rrOrga'));
            brothers.push(getColumn(columns, 'rrColl'));
            brothers.push(getColumn(columns, 'rrIndiv'));
            return brothers;
        case 'rrOrga' :
            brothers.push(getColumn(columns, 'rrType'));
            brothers.push(getColumn(columns, 'rrColl'));
            brothers.push(getColumn(columns, 'rrIndiv'));
            return brothers;
        case 'rrColl' :
            brothers.push(getColumn(columns, 'rrType'));
            brothers.push(getColumn(columns, 'rrOrga'));
            brothers.push(getColumn(columns, 'rrIndiv'));
            return brothers;
        case 'rrIndiv' :
            brothers.push(getColumn(columns, 'rrType'));
            brothers.push(getColumn(columns, 'rrOrga'));
            brothers.push(getColumn(columns, 'rrColl'));
            return brothers;
        case 'rrFrequency' :
            brothers.push(getColumn(columns, 'rrIntensity'));
            return brothers;
        case 'rrIntensity' :
            brothers.push(getColumn(columns, 'rrFrequency'));
            return brothers;
        case 'rrMaster' :
            brothers.push(getColumn(columns, 'rrProbability'));
            brothers.push(getColumn(columns, 'rrGravity'));
            brothers.push(getColumn(columns, 'rr', tab));
            return brothers;
        case 'rrProbability' :
            brothers.push(getColumn(columns, 'rrMaster'));
            brothers.push(getColumn(columns, 'rrGravity'));
            brothers.push(getColumn(columns, 'rr', tab));
            return brothers;
        case 'rrGravity' :
            brothers.push(getColumn(columns, 'rrMaster'));
            brothers.push(getColumn(columns, 'rrProbability'));
            brothers.push(getColumn(columns, 'rr', tab));
            return brothers;
    }
}

function getParentColSpan(configsTab: { id: string; value: boolean }[], brothers: any): number {
    let colSpan = brothers.length;
    brothers.forEach((column: DataColumn) => {
        const found = configsTab.find(config => config.id === column.id);
        if (found && !found.value) {
            colSpan--;
        }
    })
    return colSpan;
}

export const mixConfigAndDefault = (configs: { id: string; value: boolean }[][], columns: DataColumn[], riskColumnsSpan: DataColumn[]): void => {
    configs.forEach((configsTab, i) => {
        configsTab.forEach((config) => {
            const current = getColumn(columns, config.id, i + 1);
            const needHide = !config.value;
            if (current && needHide) {
                // Have a parent (a th on top to handle)
                const hasParentTh = current.hideTh === undefined;
                current.hide = true;
                current.hideTh = true;

                if (hasParentTh) {
                    const parent = getParent(config.id, riskColumnsSpan, i + 1);
                    const brothers = getBrothers(config.id, columns, i + 1);
                    // Hide current

                    const colSpan = getParentColSpan(configsTab, brothers);
                    if (parent) {
                        colSpan ? parent.colSpan = colSpan : parent.hideTh = true;
                    }
                } else {
                    // Parent has same id on other array
                    const parent = riskColumnsSpan.find(c => (c.id === config.id) && (c.tab === i + 1));
                    if (parent) {
                        parent.hideTh = true
                    }
                }

            }
        })
    });
}

export function getColumn(columns: DataColumn[], id: string, tab?: number): DataColumn | undefined {
    return columns.find((c: DataColumn) => tab ? (c.id === id && c.tab === tab) : c.id === id);
}

// TODO : Test function for this method
export function getPotentialExposition(chemicalRisk: ChemicalRisk) {
    const quantity = getChemicalQuoting(chemicalRisk, 'quantityCriteria', quantitiesCriteria);
    const frequency = getChemicalQuoting(chemicalRisk, 'frequencyCriteria', frequenciesCriteria);
    return quantity && frequency ? MATRIX_POTENTIAL_EXPOSITION[quantity][frequency - 1] : 'N/A';
}

// TODO : Test function for this method
export function getCutaneeExposition(chemicalRisk: ChemicalRisk) {
    const frequency = getChemicalQuoting(chemicalRisk, 'frequencyCriteria', frequenciesCriteria);
    const contactClass = getChemicalQuoting(chemicalRisk, 'contactClass', contactClasses);
    return contactClass && frequency ? MATRIX_CUTANEE[contactClass][frequency - 1] : null;
}

// TODO : Test function for this method
export function getChemicalQuoting(c: ChemicalRisk, i: string, l: any[]) {
    if (!c) {
        return null;
    }
    const item = l.find((e: any) => e.id === c[i as keyof ChemicalRisk]);
    return item ? item.quoting! : null;
}

export function getProbability(frequency: number, danger: DangerSubFamily,
                               chemicalRisk?: ChemicalRisk | null, atexRisk?: Rb | null): number | null {
    switch (danger.name) {
        case 'Bruit acoustique moyen' :
            switch (true) {
                case(frequency < 300) :
                    return 1;
                case(frequency < 600) :
                    return 2;
                case(frequency < 900) :
                    return 3;
                case(frequency >= 900) :
                    return 4;
            }
            return 0;
        case 'Bruit acoustique crête' :
            switch (true) {
                case(frequency < 40) :
                    return 1;
                case(frequency < 120) :
                    return 2;
                case(frequency < 160) :
                    return 3;
                case(frequency >= 160) :
                    return 4;
            }
            return 0;
        case 'Températures extrêmes froides' :
            switch (true) {
                case(frequency < 300) :
                    return 1;
                case(frequency < 600) :
                    return 2;
                case(frequency < 900) :
                    return 3;
                case(frequency >= 900) :
                    return 4;
            }
            return 0;
        case 'Températures extrêmes chaudes' :
            switch (true) {
                case(frequency < 300) :
                    return 1;
                case(frequency < 600) :
                    return 2;
                case(frequency < 900) :
                    return 3;
                case(frequency >= 900) :
                    return 4;
            }
            return 0;
        case 'Vibrations mécaniques : mains et bras' :
            switch (true) {
                case(frequency < 300) :
                    return 1;
                case(frequency < 600) :
                    return 2;
                case(frequency < 900) :
                    return 3;
                case(frequency >= 900) :
                    return 4;
            }
            return 0;
        case 'Vibrations mécaniques : corps entier' :
            switch (true) {
                case(frequency < 300) :
                    return 1;
                case(frequency < 600) :
                    return 2;
                case(frequency < 900) :
                    return 3;
                case(frequency >= 900) :
                    return 4;
            }
            return 0;
        case 'Activité en milieu hyperbare' :
            switch (true) {
                case(frequency < 40) :
                    return 1;
                case(frequency < 60) :
                    return 2;
                case(frequency < 80) :
                    return 3;
                case(frequency >= 80) :
                    return 4;
            }
            return 0;
        case 'Manutention manuelle : Lever ou porter' :
            switch (true) {
                case(frequency < 300) :
                    return 1;
                case(frequency < 600) :
                    return 2;
                case(frequency < 900) :
                    return 3;
                case(frequency >= 900) :
                    return 4;
            }
            return 0;
        case 'Manutention manuelle : Pousser ou tirer' :
            switch (true) {
                case(frequency < 300) :
                    return 1;
                case(frequency < 600) :
                    return 2;
                case(frequency < 900) :
                    return 3;
                case(frequency >= 900) :
                    return 4;
            }
            return 0;
        case 'Manutention manuelle : Déplacement du travailleur avec la charge ou prise de la charge au sol ou à une hauteur située au dessus des épaules' :
            switch (true) {
                case(frequency < 300) :
                    return 1;
                case(frequency < 600) :
                    return 2;
                case(frequency < 900) :
                    return 3;
                case(frequency >= 900) :
                    return 4;
            }
            return 0;
        case 'Manutention manuelle : Cumul de manutention de charges' :
            switch (true) {
                case(frequency < 40) :
                    return 1;
                case(frequency < 120) :
                    return 2;
                case(frequency < 160) :
                    return 3;
                case(frequency >= 160) :
                    return 4;
            }
            return 0;
        case 'Posture pénible' :
            switch (true) {
                case(frequency < 300) :
                    return 1;
                case(frequency < 600) :
                    return 2;
                case(frequency < 900) :
                    return 3;
                case(frequency >= 900) :
                    return 4;
            }
            return 0;
        case 'Travail répétitif - cycle inférieur ou égal à 30 secondes' :
        case 'Travail répétitif - cycle supérieur à 30 secondes' :
            switch (true) {
                case(frequency < 300) :
                    return 1;
                case(frequency < 600) :
                    return 2;
                case(frequency < 900) :
                    return 3;
                case(frequency >= 900) :
                    return 4;
            }
            return 0;
        case 'Agent chimique dangereux, y compris poussières et fumées' :
            return chemicalRisk ?
                (chemicalRisk.expositionType === 'inhalation' ? getPotentialExposition(chemicalRisk!) : getCutaneeExposition(chemicalRisk!)) :
                null;
        case 'Explosion ATEX : gaz, vapeurs et brouillards' :
        case 'Explosion ATEX : poussières' :
            return getAtexProbability(atexRisk?.ignitionSources, Prefix.RB);
        default :
            return 0;
    }
}

export function getGravity(intensity: number, danger: DangerSubFamily, chemicalRisk?: ChemicalRisk | null): number | string {
    switch (danger.name) {
        case 'Bruit acoustique crête' :
            switch (true) {
                case(intensity < 135) :
                    return 1;
                case(intensity < 137) :
                    return 2;
                case(intensity < 140) :
                    return 3;
                case(intensity >= 140) :
                    return 4;
            }
            return 0;
        case 'Bruit acoustique moyen' :
            switch (true) {
                case(intensity < 80) :
                    return 1;
                case(intensity < 85) :
                    return 2;
                case(intensity < 87) :
                    return 3;
                case(intensity >= 87) :
                    return 4;
            }
            return 0;
        case 'Températures extrêmes froides' :
            switch (true) {
                case(intensity > 10) :
                    return 1;
                case(intensity > 5) :
                    return 2;
                case(intensity > 0) :
                    return 3;
                case(intensity <= 0) :
                    return 4;
            }
            return 0;
        case 'Températures extrêmes chaudes' :
            switch (true) {
                case(intensity < 25) :
                    return 1;
                case(intensity < 30) :
                    return 2;
                case(intensity < 35) :
                    return 3;
                case(intensity >= 35) :
                    return 4;
            }
            return 0;
        case 'Vibrations mécaniques : mains et bras' :
            switch (true) {
                case(intensity > 1.25) :
                    return 1;
                case(intensity > 2.5) :
                    return 2;
                case(intensity < 5) :
                    return 3;
                case(intensity >= 5) :
                    return 4;
            }
            return 0;
        case 'Vibrations mécaniques : corps entier' :
            switch (true) {
                case(intensity < 0.25) :
                    return 1;
                case(intensity < 0.5) :
                    return 2;
                case(intensity < 1.15) :
                    return 3;
                case(intensity >= 1.15) :
                    return 4;
            }
            return 0;
        case 'Activité en milieu hyperbare' :
            switch (true) {
                case(intensity < 800) :
                    return 1;
                case(intensity < 1200) :
                    return 2;
                case(intensity < 1600) :
                    return 3;
                case(intensity >= 1600) :
                    return 4;
            }
            return 0;
        case 'Manutention manuelle : Lever ou porter' :
            switch (true) {
                case(intensity < 10) :
                    return 1;
                case(intensity < 15) :
                    return 2;
                case(intensity < 20) :
                    return 3;
                case(intensity >= 20) :
                    return 4;
            }
            return 0;
        case 'Manutention manuelle : Pousser ou tirer' :
            switch (true) {
                case(intensity < 200) :
                    return 1;
                case(intensity < 250) :
                    return 2;
                case(intensity < 300) :
                    return 3;
                case(intensity >= 300) :
                    return 4;
            }
            return 0;
        case 'Manutention manuelle : Déplacement du travailleur avec la charge ou prise de la charge au sol ou à une hauteur située au dessus des épaules' :
            switch (true) {
                case(intensity < 5) :
                    return 1;
                case(intensity < 10) :
                    return 2;
                case(intensity < 15) :
                    return 3;
                case(intensity >= 15) :
                    return 4;
            }
            return 0;
        case 'Manutention manuelle : Cumul de manutention de charges' :
            switch (true) {
                case(intensity < 5) :
                    return 1;
                case(intensity < 7.5) :
                    return 2;
                case(intensity < 10) :
                    return 3;
                case(intensity >= 10) :
                    return 4;
            }
            return 0;
        case 'Posture pénible' :
            return 3;
        case 'Travail répétitif - cycle inférieur ou égal à 30 secondes' :
            switch (true) {
                case(intensity < 5) :
                    return 1;
                case(intensity < 10) :
                    return 2;
                case(intensity < 15) :
                    return 3;
                case(intensity >= 15) :
                    return 4;
            }
            return 0;
        case 'Travail répétitif - cycle supérieur à 30 secondes' :
            switch (true) {
                case(intensity < 25) :
                    return 1;
                case(intensity < 30) :
                    return 2;
                case(intensity < 35) :
                    return 3;
                case(intensity >= 35) :
                    return 4;
            }
            return 0;
        case 'Agent chimique dangereux, y compris poussières et fumées' :
            return getChemicalQuoting(chemicalRisk!, 'ratingCriteria', ratingsCriteria) ?? null;
        case 'Explosion ATEX : gaz, vapeurs et brouillards' :
        case 'Explosion ATEX : poussières' :
            return 4;
        default :
            return 0;
    }
}

export function isRiskAtex(name: string): boolean {
    return name ? name.startsWith('Explosion ATEX') : false;
}

export function isRiskChemical(name: string, exposition: RiskExposition): boolean {
    return name ? name.startsWith('Agent chimique') && exposition === RiskExposition.CHRONIC : false;
}

export function isRiskVibrate(name: string): boolean {
    return name ? name.startsWith('Vibrations') : false;
}

/**
 * Filter risk with, site, installation, workUnit, operation and dangerSubFamily
 * @returns Risk[] | []
 * @param risks
 * @param site
 * @param installation
 * @param workUnit
 * @param operation
 * @param dangerFamily
 */
export function filterRisks(
    risks: Risk[] | undefined,
    site: number,
    installation: number,
    workUnit: number,
    operation: number,
    dangerFamily: number
) {
    if (risks) {
        let temp: Risk[] = [...risks];
        // filter by site
        if (site !== 0) {
            temp = temp.filter((risk: Risk) => Number(risk.operation?.workUnit?.installation?.site?.id) === Number(site));
        }
        // filter by installation
        if (installation !== 0) {
            temp = temp.filter((risk: Risk) => Number(risk.operation?.workUnit?.installation?.id) === Number(installation));
        }
        // filter by workUnit
        if (workUnit !== 0) {
            temp = temp.filter((risk: Risk) => Number(risk.operation?.workUnit?.id) === Number(workUnit));
        }
        // filter by operation
        if (operation !== 0) {
            temp = temp.filter((risk: Risk) => Number(risk.operation?.id) === Number(operation));
        }
        // filter by dangersubfamily
        if (dangerFamily !== 0) {
            temp = temp.filter((risk: Risk) => Number(risk.dangerFamily?.id) === Number(dangerFamily));
        }
        return temp;
    }
    return [];
}

export function createChemicalRisk(risk: ChemicalRisk): ChemicalRisk {
    return {
        ...risk,
        gravity: getChemicalQuoting(risk, 'ratingCriteria', ratingsCriteria),
        probability: getChemicalProbability(risk)
    };
}
