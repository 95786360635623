import React, {useEffect, useState} from "react"
import {useMutation, useQuery} from "react-query"
import {DangerConfiguration} from "../../models/DangerConfiguration"
import {DangerFamily} from "../../models/DangerFamily"
import {DangerSubFamily} from "../../models/DangerSubFamily"
import Api from "../../services/api.service"
import {Endpoints} from "../../services/endpoints"
import MultiSelect from "../utils/MultiSelect"
import Button from "../utils/Inputs/Button"

export default function BubblesInfosPage() {
    const api = new Api()
    const [family, setFamily] = useState<string>("")
    const [subFamily, setSubFamily] = useState<string>("")
    const [bubbleInfo, setBubbleInfo] = useState<string>("")
    const [config, setConfig] = useState<DangerConfiguration["bubblesInfos"]>()
    const [configID, setConfigID] = useState<any>()
    const [formData, setFormData] = useState<DangerConfiguration["bubblesInfos"]>()

    // Queries
    const families = useQuery<DangerFamily[]>(Endpoints.DANGER_FAMILIES, () => api.getAll(Endpoints.DANGER_FAMILIES))
    const subFamilies = useQuery<DangerSubFamily[]>([Endpoints.DANGER_SUB_FAMILIES, family], () => api.getAll(Endpoints.DANGER_SUB_FAMILIES, [["family.id", family]]), {
        enabled: family !== "",
    });
    const configs = useQuery<DangerConfiguration[]>([Endpoints.DANGER_CONFIGURATION, subFamily], () => api.get(Endpoints.DANGER_CONFIGURATION, [["dangerSubFamily.id", subFamily]]), {
        enabled: subFamily !== "",
        onSuccess: (result: DangerConfiguration[]) => {
            if (result && result[0]) {
                setConfig(result[0].bubblesInfos)
                setFormData(result[0].bubblesInfos)
                setConfigID(result[0].id);
            }
        }
    })
    const {
        isLoading: updateIsLoading,
        mutate: updateConfig
    } = useMutation(([Endpoints.DANGER_CONFIGURATION + "patch", configID]), () => api.patch(Endpoints.DANGER_CONFIGURATION, {bubblesInfos: formData}, configID), {
        onSuccess: (result: DangerConfiguration) => {
            setConfig(result.bubblesInfos)
        }
    })

    // On Change 
    function changeBubbleText(indexBubble: any, index: any, text: string) {
        let temp: any = JSON.parse(JSON.stringify(config))
        temp[indexBubble].data[index] = text
        setFormData(temp);
    }

    useEffect(() => {
        if (family !== "") {
            setSubFamily("")
            setBubbleInfo("")
        }
    }, [family])

    const selectsProps = [
        {
            name: "family",
            collection: families,
            item: family,
            setter: setFamily,
            label: "Choisissez une famille de danger"
        },
        {
            name: "subFamily",
            collection: subFamilies,
            item: subFamily,
            setter: setSubFamily,
            label: "Choisissez une sous-famille de danger"
        },
        {
            name: "config",
            collection: {isLoading: configs.isLoading, data: config},
            item: bubbleInfo,
            setter: setBubbleInfo,
            label: "Choisissez un texte d'info-bulle"
        }
    ]

    return (
        <div className="w-11/12 m-auto h-4/5 relative pt-3">
            <MultiSelect selects={selectsProps}/>
            <section className="mt-20">
                {bubbleInfo !== "" && !configs.isLoading && config?.map((bubble: any, indexBubble: number) => {
                    if (bubble.id === bubbleInfo) {
                        return (
                            <div key={bubble.name}>
                                <h3 className="text-xl font-bold mb-5 pl-3">{bubble.name.charAt(0).toUpperCase() + bubble.name.slice(1)}</h3>
                                <ul>
                                    {bubble.data.map((text: string, index: number) => (
                                        <li key={text + index} className="m-3 flex items-center">
                                            <label
                                                className="mr-5 bg-blue-light-75 text-white font-bold hover:bg-blue-light rounded-full h-10 w-10 flex items-center justify-center cursor-pointer"
                                                htmlFor={indexBubble + index + ""}>{index + 1}</label>
                                            <input type="text" id={indexBubble + index + ""} className="input"
                                                   defaultValue={text}
                                                   onChange={(e) => changeBubbleText(indexBubble, index, e.target.value)}/>
                                        </li>
                                    ))}
                                </ul>
                                <div className="flex items-center justify-end">
                                    <Button trigger={updateConfig} disabled={updateIsLoading}
                                            isLoading={updateIsLoading} styles={"m-3"} label={["Valider"]}/>
                                </div>
                            </div>
                        )
                    } else {
                        return null
                    }
                })}
            </section>
        </div>
    )
}
