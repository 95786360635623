import React, { useEffect, useState } from "react";
import { LoaderIcon } from "../../Icons";
import { authService } from "../../services/auth.service";
import { Endpoints } from "../../services/endpoints";
import { chemicalColumnsSpanTab, chemicalColumnsTab } from "../../helpers/DataColumns/ChemicalColumns";
import { DataTableTabs } from "../layout/DataTableTabs";
import { PathType } from "../../helpers/PathType";
import { ChemicalProduct } from "../../models/ChemicalProduct";
import { ErrorQuery } from "../utils/ErrorQuery";
import { useQuery } from "react-query";
import Api from "../../services/api.service";

const tabs = [
    { name: "Identification du produit", index: 1 },
    { name: "Propriétés physico-chimiques", index: 2 },
    { name: "Composition du produit", index: 3 },
];

export default function ChemicalProductsPage() {
    const api = new Api();
    const [data, setData] = useState<ChemicalProduct[] | any>([]);
    const user = authService.getCurrentUser();

    const { isLoading, data: chemicalProducts, isError } =
        useQuery<ChemicalProduct[]>([Endpoints.CHEMICAL_PRODUCTS, Endpoints.CHEMICAL_PRODUCTS], () => api.getAll(Endpoints.CHEMICAL_PRODUCTS))

    useEffect(() => {
        if (chemicalProducts) {
            setData([...chemicalProducts])
        }
    }, [chemicalProducts])

    if (isLoading)
        return (
            <div className="h-4/5 flex items-center justify-center">
                <LoaderIcon />
            </div>
        );
    if (isError || data === undefined) {
        return (
            <ErrorQuery>Erreur de chargement</ErrorQuery>
        );
    }

    return <div className="h-4/5 overflow-auto">{user &&
        <DataTableTabs path={PathType.CHEMICAL_PRODUCTS} tabs={tabs} dataTabs={chemicalColumnsTab} data={data}
            dataSpanTabs={chemicalColumnsSpanTab} />}</div>;
}
