import React from "react";

interface FielSelectProps {
    name: string;
    options: { id: number, name: string | number }[] | any[] | undefined;
    value: any;
    className?: string
    isLoading?: boolean;
    onChange: (e: any) => void;
    disabled?: boolean;
    placeHolder?: boolean;
    children?: string;
    multiple?: boolean;
}

export const FieldSelect = React.memo(function Select({
                                                          name,
                                                          options,
                                                          value,
                                                          className,
                                                          isLoading = false,
                                                          onChange,
                                                          multiple = false,
                                                          disabled = false,
                                                          placeHolder = true,
                                                          children = ''
                                                      }: FielSelectProps) {
    const classes = "input " + className

    /* SPECIFIC TO WORK_UNIT AND OPERATION */
    function getOptionName(option: any) {
        let display = '';
        if (option.site && option.site.name) {
            display += (option.site.name + ' - ');
        }
        if (option.installation && option.installation.site && option.installation.site.name) {
            display += option.installation.site.name + ' - ' + option.installation.name + ' - ';
        }
        display += (option.name);
        return display;
    }

    return (
        <div>
            <label className="text-xl" htmlFor={name}>{children}</label>
            {isLoading &&
                <select disabled={true} id={name}
                        className="input animate-pulse rounded-lg bg-gray-light text-gray-dark border-transparent"
                >
                    <option defaultChecked value={""}>Recherche en
                        cours...
                    </option>
                </select>}
            {!isLoading && (
                <select multiple={multiple} disabled={disabled} id={name}
                        className={disabled ? classes + " cursor-not-allowed" : classes + " cursor-pointer"}
                        value={value} onChange={onChange}>
                    {placeHolder && <option defaultChecked value={""}>Choisissez une option</option>}
                    {options?.map(option => (
                        <option key={option.id} title={option.title ?? null}
                                value={option.id}>{getOptionName(option)}</option>
                    ))}
                    {options && options?.length < 1 && (
                        <option value="">Aucun résultats</option>
                    )}
                </select>
            )}
        </div>
    )
})
