export class Routes {
    public static ADMIN = '/admin';
    public static LOGIN = '/login';
    public static HOME = '/';
    public static NOT_FOUND = Routes.ADMIN + '/*';
    public static COMPANY_DESCRIPTION = Routes.ADMIN + '/description-entreprise';
    public static CHEMICAL_PRODUCTS = Routes.ADMIN + '/produits-chimiques';
    public static SITES = Routes.ADMIN + '/sites';
    public static INSTALLATIONS = Routes.ADMIN + '/installations';
    public static WORK_UNIT = Routes.ADMIN + '/unite-de-travail';
    public static OPERATIONS = Routes.ADMIN + '/operations';
    public static SERVICES = Routes.ADMIN + '/services';
    public static EMPLOYEES = Routes.ADMIN + '/registre-du-personnel';
    public static FAMILY_DANGER = Routes.ADMIN + '/famille-de-danger';
    public static FAMILY_SUB_DANGER = Routes.ADMIN + '/sous-famille-de-danger';
    public static COTATION = Routes.ADMIN + '/matrice-cotation-risques';
    public static COTATION_CONTROLED = Routes.ADMIN + '/matrice-cotation-risques-controles';
    public static COUPLE_BUBBLES_INFOS = Routes.ADMIN + '/infos-bulles';
    public static COUPLE_PROBABILITY_GRAVITY = Routes.ADMIN + '/couple-probabilite-gravite';
    public static COLUMNS_DU = Routes.ADMIN + '/colonnes-DU';
    public static HELP_CSV = Routes.ADMIN + '/aide-import-csv';
    public static CSV_REFERENCE = '/references-csv';
    public static IMPORT_RISK = Routes.ADMIN + '/import-risk';
}
