import {Field} from "../../utils/Inputs/Field";
import React from "react";
import {IgnitionSource} from "../../../models/IgnitionSource";
import Button from "../../utils/Inputs/Button";
import {atexZones, sourceProbalities} from "../../../helpers/DataColumns/RiskColumns";
import {FieldSelect} from "../../utils/Inputs/FieldSelect";
import {getMultipleSelectValues} from "../../../helpers/Helpers";
import {Rb, Ri, Rr} from "../../../models/Risk";
import {TrashIcon} from "../../../Icons";
import {Prefix} from "../../../models/Prefix";
import {useSources} from "../../hooks/useSources";


export interface IgnitionSourceProps {
    risk: Rb | Ri | Rr,
    setRisk: (value: Rb | Ri | Rr) => void
    slug: Prefix;
}

export default function IgnitionSources({risk, setRisk, slug}: IgnitionSourceProps) {

    const {sources, addSource, removeSource, handleChange} = useSources(risk, setRisk, slug);

    return (
        <div className="m-4">
            <p className="text-xl mb-3">Inventaire des sources d'ignitions</p>
            <div className="grid grid-cols-4 gap-4">
                <p>Sources d'ignitions identifiées (matériels, ...)</p>
                <p>Probabilité d'ignition</p>
                <p>Zone(s) ATEX associée(s) à la source d'ignition</p>
                <p>Action</p>
                {sources.map((source: IgnitionSource, index: number) => {
                    // Label
                    return (
                        <>
                            <Field name="label" type="text" value={source.label}
                                   onChange={(e) => handleChange(e.target.value, index, 'label')}/>
                            <FieldSelect
                                name={"sourceProbalities"}
                                options={sourceProbalities}
                                value={source.probability}
                                onChange={(e) => handleChange(e.target.value, index, 'probability')}/>
                            <FieldSelect
                                name={"atexZones"}
                                options={atexZones}
                                value={source.atexZone}
                                multiple={true}
                                onChange={(e) => handleChange(getMultipleSelectValues(e), index, 'atexZone')}/>
                            <Button styles="btn-outline m-5" trigger={() => removeSource(index)}
                                    label={[<TrashIcon/>, "Supprimer"]}/>
                        </>
                    )
                })}
                <Button trigger={addSource} label={['Ajouter une source']}/>
            </div>
        </div>
    )
}
