import React from "react";
import {BubblesInfosType} from "../../../models/DangerConfiguration";

interface FielSelectProps {
    name: string;
    options?: any[];
    bubbleInfos?: BubblesInfosType[];
    value: any;
    className?: string
    isLoading?: boolean;
    onChange: (e: any) => void;
    disabled?: boolean;
    children: string;
}

export const FieldSelectArray = React.memo(function Select({
                                                               name,
                                                               options,
                                                               bubbleInfos,
                                                               value,
                                                               className,
                                                               isLoading = false,
                                                               onChange,
                                                               disabled = false,
                                                               children,
                                                           }: FielSelectProps) {
    const classes = "input " + className;
    const bubbleInfo = bubbleInfos?.find(b => b.id === name);
    let labels: any = [];
    if (bubbleInfo) {
        labels = bubbleInfo['data'];
    }

    return (
        <div>
            <label htmlFor={name}>{children}</label>
            {isLoading &&
            <p className="input animate-pulse rounded-lg bg-gray-light text-gray-dark border-transparent">Recherche en
                cours...</p>}
            {!isLoading && (
                <select disabled={disabled} id={name}
                        className={disabled ? classes + " cursor-not-allowed" : classes + " cursor-pointer"}
                        value={value} onChange={onChange}>
                    <option defaultChecked value={""}>Choisissez une option</option>
                    {options?.map((option, key) => (
                        option && <option key={option + '-' + key}
                                          value={key + 1}>{key + 1} - {labels[key]}</option>
                    ))}
                </select>
            )}
        </div>
    )
})
