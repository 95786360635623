import React from "react";
import {StyledTab, StyledTabs, TabPanel} from "../utils/TabsComponents";
import {DataTable} from "./DataTable";
import {PathType} from "../../helpers/PathType";
import {DataColumn} from "../../models/DataColumn";
import {Tab} from "../../models/Tab";
import {useSelectedRow} from "../hooks/useSelectedRow";

export interface DataTableTabsProps {
    dataTabs: { [key: string]: DataColumn[] };
    dataSpanTabs?: { [key: string]: DataColumn[] };
    data: any;
    path: PathType;
    tabs: Tab[];
    setDisplay?: (e: boolean) => void
    display?: boolean
}

export const DataTableTabs = React.memo(function DataTableTabs({
                                                                   tabs,
                                                                   path,
                                                                   dataTabs,
                                                                   dataSpanTabs,
                                                                   data,
                                                                   setDisplay,
                                                                   display
                                                               }: DataTableTabsProps) {
    const [currentTab, setCurrentTab] = React.useState<number>(0);
    const {selectedRow, changeColor} = useSelectedRow();
    const handleChange = (event: React.ChangeEvent<{}>, tab: number) => {
        setCurrentTab(tab);
    };

    let index = 0;

    function isEmpty(dataTab: DataColumn[]) {
        return dataTab.every(d => d.id !== 'action' ? d.hide : true);

    }

    return (
        <div className="capse-table-container">
            <div className="capse-table-tabs">
                <StyledTabs value={currentTab} onChange={handleChange} aria-label="styled tabs">
                    {tabs.map((tab: any) => (
                        !isEmpty(dataTabs[tab.index]) &&
                        <StyledTab key={tab.index} label={tab.name}/>
                    ))}
                </StyledTabs>
            </div>
            <div className="capse-table-inner h-full overflow-auto">
                {
                    Object.keys(dataTabs).map((key: string) => {
                        if (!isEmpty(dataTabs[key])) {
                            const elem = (<TabPanel index={index} value={currentTab} key={key}>
                                <DataTable data={data} columnsSpan={dataSpanTabs ? dataSpanTabs[key] : undefined}
                                           columns={dataTabs[key]}
                                           path={path}
                                           setDisplay={setDisplay}
                                           display={display}
                                           selectedRow={selectedRow}
                                           changeColor={changeColor}
                                />
                            </TabPanel>)
                            index++;
                            return elem;
                        }
                    })}
            </div>
        </div>
    );
});

