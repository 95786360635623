import {useEffect, useRef, useState} from "react";
import Api from "../../services/api.service";
import {authService} from "../../services/auth.service";
import {useQuery} from "react-query";
import {Endpoints} from "../../services/endpoints";
import {LoaderIcon} from "../../Icons";
import {ErrorQuery} from "../utils/ErrorQuery";
import {DataTableTabs} from "../layout/DataTableTabs";
import {PathType} from "../../helpers/PathType";
import {riskColumn, riskColumnsSpan, riskColumnsSpanTab, riskColumnsTab} from "../../helpers/DataColumns/RiskColumns";
import Banner from "../utils/blocks/Banner";
import {Risk} from "../../models/Risk";
import {filterRisks, mixConfigAndDefault} from "../../helpers/riskHelpers";
import {useDangers} from "../hooks/useDangers";
import {useEffectResetMultiSelect} from "../hooks/useEffectResetMultiSelect"
import {useMultiSelectEntities} from "../hooks/useMultiSelectEntities";
import Filters from "../utils/blocks/Filters";

const tabs = [
    {name: "Identification des dangers", index: 1},
    {name: "Risque brut", index: 2},
    {name: "Risque initial", index: 3},
    {name: "Risque résiduel", index: 4},
];

export default function HomePage() {
    const api = new Api();
    const user = authService.getCurrentUser();
    const [data, setData] = useState<Risk[] | any>([]);
    const [display, setDisplay] = useState<boolean>(true); // TODO: useContext
    const [dangerFamily, setDangerFamily] = useState<number>(0)

    const {
        site,
        sites,
        setSite,
        installation,
        installations,
        setInstallation,
        workUnit,
        workUnits,
        setWorkUnit,
        operation,
        operations,
        setOperation
    } = useMultiSelectEntities()
    const {families, subFamilies, isLoadingFamilies, isLoadingSubFamilies} = useDangers();

    // Banner responsive
    const [bannerSize, setBannerSize] = useState(0)
    const bannerDiv = useRef(null);
    user?.columnsConfiguration && mixConfigAndDefault(user.columnsConfiguration, riskColumn, riskColumnsSpan);
    const {isLoading, data: risks, isError} =
        useQuery<Risk[]>([Endpoints.RISKS, display], () => api.getAll(Endpoints.RISKS, !display ? [['exists[endDate]', String(display)]] : null))

    useEffect(() => {
        if (risks) {
            setData([...risks])
        }
    }, [risks])

    useEffectResetMultiSelect(site, installation, workUnit, [setInstallation, setWorkUnit, setOperation]);

    useEffect(() => {
        //@ts-ignore
        setBannerSize(bannerDiv.current?.clientHeight)
        //@ts-ignore
    }, [bannerDiv.current?.clientHeight])

    const selectsProps = [
        {
            name: "site",
            collection: sites,
            item: site,
            setter: setSite,
            placeHolder: true,
        },
        {
            name: "installation",
            collection: installations,
            item: installation,
            setter: setInstallation,
            enabler: site === 0,
            placeHolder: true,
        },
        {
            name: "workUnit",
            collection: workUnits,
            item: workUnit,
            setter: setWorkUnit,
            enabler: site === 0 || installation === 0,
            placeHolder: true,
        },
        {
            name: "operation",
            collection: operations,
            item: operation,
            setter: setOperation,
            enabler: site === 0 || installation === 0 || workUnit === 0,
            placeHolder: true,
        }
    ];

    const filterR = () => filterRisks(risks, site, installation, workUnit, operation, dangerFamily);

    if (isLoading)
        return (
            <div className="h-screen flex items-center justify-center">
                <LoaderIcon/>
            </div>
        );
    if (isError || data === undefined) {
        return (<div className="h-screen flex items-center justify-center">
                <ErrorQuery>Erreur de chargement</ErrorQuery>
            </div>
        );
    }
    return (
        <div className="relative h-screen w-full">
            <div className="pt-2" ref={bannerDiv}>
                <Banner/>
            </div>
            <div className="capse-container">
                <div className="capse-header p-2">
                    <p>ACCUEIL - SYNTHÈSE GÉNÉRALE DES EVALUATIONS PAR OPERATIONS
                    </p>
                </div>
                <div className="capse-table" style={{height: `calc(100% - ${bannerSize}px)`}}>
                    <Filters selectsProps={selectsProps}
                             subFamilies={subFamilies}
                             families={families}
                             dangerFamily={dangerFamily}
                             isLoadingFamilies={isLoadingFamilies}
                             isLoadingSubFamilies={isLoadingSubFamilies}
                             setDangerFamily={setDangerFamily}
                             operation={operation}/>
                    {user &&
                        <DataTableTabs path={PathType.RISKS} tabs={tabs} dataTabs={riskColumnsTab} data={filterR()}
                                       dataSpanTabs={riskColumnsSpanTab} setDisplay={setDisplay} display={display}/>}
                </div>
            </div>

        </div>
    );
}


