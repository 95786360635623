export enum PathType {
    USERS = "users",
    SITES = "sites",
    EMPLOYEES = "employees",
    INSTALLATIONS = "installations",
    WORK_UNITS = "work_units",
    OPERATIONS = "operations",
    SERVICES = "services",
    CHEMICAL_PRODUCTS = "chemical_products",
    DANGER_FAMILIES = "danger_families",
    RISKS = "risks",
    RISKS_DUPLICATION = "risks_duplication",
    EXCEL = "excel",
    DANGER_SUB_FAMILIES = "danger_sub_families",
    COTATION = "cotation",
    COTATION_CONTROLED = "cotationControled",
    DANGER_CONFIGURATION= "danger_configurations"
}

export enum NameType {
    USER = "user",
    SITE = "site",
    INSTALLATION = "installation",
    WORK_UNIT = "workUnit",
    OPERATION = "operation",
    SERVICES = "service",
    CHEMICAL_PRODUCT = "chemical_product",
    COTATION = "matrixCotationRisk",
    COTATION_CONTROLED = "matrixCotationRiskControled"
}
