import {Credentials} from "../models/Credentials";
import Api from "./api.service";
import {Endpoints} from "./endpoints";
import {User} from "../models/User";


export const authService = {
    login,
    logout,
    externalLogin,
    getCurrentUser
};

/**
 * Login to the api
 * @param logs credentials infos
 * @returns Promise<any>
 */
function login(logs: Credentials): Promise<any> {
    const api = new Api();
    const url = api._entryPoint + "login";

    const requestOptions = {
        method: 'POST',
        headers: new Headers({"Content-Type": "application/json"}),
        body: JSON.stringify({username: logs.username, password: logs.password})
    };

    return fetch(url, requestOptions)
        .then(res => res.json())
        .then(data => {
                me(data.token).then((user) => {
                    getInfos(user.id, data.token).then((fullUser) => {
                        fullUser.token = data.token;
                        // store user details and jwt token in local storage to keep user logged in between page refreshes
                        localStorage.setItem('user', JSON.stringify(fullUser));
                        return fullUser;
                    });
                })
            }
        );
}

function me(token: string): Promise<any> {
    const api = new Api();
    const url = api._entryPoint + "me";
    const requestOptions = {
        method: 'GET',
        headers: new Headers({"Content-Type": "application/json", Authorization: "Bearer " + token}),
    };
    return fetch(url, requestOptions).then((res) => res.json());
}

function getInfos(id: number, token: string): Promise<any> {
    const api = new Api();
    const url = api._entryPoint + Endpoints.USERS + '/' + id;
    const requestOptions = {
        method: 'GET',
        headers: new Headers({"Content-Type": "application/json", Authorization: "Bearer " + token}),
    };
    return fetch(url, requestOptions).then((res) => res.json());
}

function externalLogin(token: string) {
    return me(token).then((user) => {
        getInfos(user.id, token).then((fullUser) => {
            fullUser.token = token;
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(fullUser));
            return fullUser;
        });
    })
}


function logout(): void {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    window.location.replace(process.env.REACT_APP_CAPSE_URL + 'bdd/clients/logout.php');
}

function getCurrentUser(): User {
    const user = localStorage.getItem('user') as string
    return JSON.parse(user)
}

