import React, {useState} from 'react'
import {expositions, workingConditions} from "../../helpers/DataColumns/RiskColumns";
import {useDangers} from "../hooks/useDangers";
import {FieldSelectGroup} from "../utils/Inputs/FieldSelectGroup";
import Button from "../utils/Inputs/Button";
import {useMutation, useQueryClient} from "react-query";
import {Endpoints} from "../../services/endpoints";
import {cleanObj, decode_utf8} from "../../helpers/Helpers";
import Api from "../../services/api.service";
import {Field} from "../utils/Inputs/Field";
import {DangerSubFamily} from "../../models/DangerSubFamily";
import {DangerConfiguration} from "../../models/DangerConfiguration";
import {Configuration} from "../../helpers/Constants";
import {DangerFamily} from "../../models/DangerFamily";
import {createChemicalRisk, isRiskAtex, isRiskChemical} from "../../helpers/riskHelpers";
import {RiskExposition} from "../../models/RiskExposition";
import {Prefix} from "../../models/Prefix";
import {OldAtexRisk} from "../../models/OldAtexRisk";
import {IgnitionSource} from "../../models/IgnitionSource";


export default function ImportRisk() {
    const [risks, setRisks] = useState<any[]>([]);
    const [username, setUsername] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const {families, subFamilies, isLoadingFamilies, isLoadingSubFamilies} = useDangers();
    const api = new Api();
    const queryClient = useQueryClient();
    const post = useMutation((postData: any) => api.post(Endpoints.RISKS, cleanObj(postData)));

    // TODO : Factorize in a service
    function addDefaultConfiguration(danger: DangerSubFamily): Promise<DangerConfiguration> {
        const data = {
            dangerSubFamily: 'api/danger_sub_families/' + danger.id,
            coupleProbabilityGravity: {...Configuration.COUPLE_PG_DEFAULT},
            bubblesInfos: Configuration.BUBBLES_INFOS,
            active: true
        };
        return api.post(Endpoints.DANGER_CONFIGURATION, data);
    }

    async function findParent(subFamily: any, families: any[]) {
        const parent = families.find(f => f.f_id === subFamily.f_id);
        if (parent) {
            //If parent is found, check if parent exists in the database
            const existing = familyExists(parent.f_libelle);
            if (existing && typeof existing === 'object') {
                return existing.id;
            } else {
                //If parent doesn't exist, create it and return its id
                const c = {
                    name: parent.f_libelle ?? 'Famille générique : Incohérence import'
                }
                const createdFamily = await api.post(Endpoints.DANGER_FAMILIES, c);
                families && families.push(createdFamily);
                return createdFamily.id;
            }

        }
        // Fallback : Family 'Divers'
        return 19;
    }

    async function createRisk(r: any): Promise<boolean> {
        await api.post(Endpoints.RISKS, cleanObj(r));
        return new Promise((resolve) => resolve(true));
    }

    async function createSubFamily(isSubFamily: boolean, family: any, subFamily: any, item: any, families: any[]): Promise<boolean> {
        //If the old risk is associated with a family
        if (!isSubFamily) {
            //Get the name of the family
            const c = {
                name: (family && family.f_libelle) ?? 'Famille générique : Incohérence import'
            }
            //Create the family in new database
            const createdFamily = await api.post(Endpoints.DANGER_FAMILIES, c);
            console.warn('Created family : ', c.name);
            //Push in the list af existing families to avoid duplicates
            families && families.push(c);
            //Create the sub family in new database
            const d = {
                name: (family && family.f_libelle) ?? 'Famille générique : Incohérence import',
                family: 'api/danger_families/' + createdFamily.id,
                color: (family && family.f_couleur) ?? '',
                isPainful: (family && family.f_penibilite) === '1',
            }

            const createdSubFamily = await api.post(Endpoints.DANGER_SUB_FAMILIES, d);
            console.warn('Created sub family : ', d.name);
            //Push in the list af existing sub families to avoid duplicates
            subFamilies && subFamilies.push(createdSubFamily);
            //Add default configuration for subfamilies
            await addDefaultConfiguration(createdSubFamily);
            await queryClient.invalidateQueries(Endpoints.DANGER_FAMILIES);
            await queryClient.invalidateQueries(Endpoints.DANGER_SUB_FAMILIES);
            //Associate the risk to the new sub family
            if (createdSubFamily) {
                item.sf_id = createdSubFamily.id;
                return new Promise((resolve) => resolve(true));
            }
        } else {
            //If the old risk is associated with a sub family
            //Find the parent family
            //TODO : Factorize in a function with precedent if
            const parentId = await findParent(subFamily, families);
            const d = {
                name: subFamily.sf_libelle ?? 'Famille générique : Incohérence import',
                family: 'api/danger_families/' + parentId,
                color: subFamily.sf_couleur ?? '',
                isPainful: subFamily.sf_penibilite === '1',
            }
            const createdSubFamily = await api.post(Endpoints.DANGER_SUB_FAMILIES, d);
            console.warn('Created sub family : ', d.name);
            subFamilies && subFamilies.push(createdSubFamily);
            await addDefaultConfiguration(createdSubFamily);
            await queryClient.invalidateQueries(Endpoints.DANGER_FAMILIES);
            await queryClient.invalidateQueries(Endpoints.DANGER_SUB_FAMILIES);
            item.sf_id = createdSubFamily.id;
            return new Promise((resolve) => resolve(true));
        }
        return new Promise((resolve) => resolve(true));
    }

    async function setAndGetAtexRisk(item: any) {
        // Get the chemical risk from old database
        const data = await api.get(Endpoints.ATEX_RISKS_CAPSE, [['id', item.r_id]]);
        const dataRef = data && data[0];
        const sources: IgnitionSource[] = [];

        function buildAtexZone(atex_classement: string) {
            let data = [];
            switch (atex_classement) {
                case '1':
                    data.push('z0');
                    break;
                case '2':
                    data.push('z1');
                    break;
                case '3':
                    data.push('z2');
                    break;
                case '0':
                    data.push('hz');
                    break;
                default:
                    data.push('');
            }
            return data;
        }

        if (dataRef) {
            item.rb_atex_zoning = dataRef.atex_etendue;
            item.rb_persistence = dataRef.atex_persistance;
            item.ri_atex_zoning = dataRef.atex_etendue_initial;
            item.ri_persistence = dataRef.atex_persistance_initial;
            item.rr_atex_zoning = dataRef.atex_etendue_residual;
            item.rr_persistence = dataRef.atex_persistance_residual;
            data.forEach((r: OldAtexRisk) => {
                const aR: IgnitionSource = {
                    slug: Prefix.RB,
                    label: r.atex_inventaire ?? '',
                    probability: r.atex_probabilite ? 'p' + r.atex_probabilite : '',
                    atexZone: buildAtexZone(r.atex_classement),
                };
                const aRi: IgnitionSource = {
                    slug: Prefix.RI,
                    label: r.atex_inventaire_initial ?? '',
                    probability: r.atex_probabilite_initial ? 'p' + r.atex_probabilite_initial : '',
                    atexZone: buildAtexZone(r.atex_classement_initial),
                }
                const aRr: IgnitionSource = {
                    slug: Prefix.RR,
                    label: r.atex_inventaire_residual ?? '',
                    probability: r.atex_probabilite_residual ? 'p' + r.atex_probabilite_residual : '',
                    atexZone: buildAtexZone(r.atex_classement_residual),
                }
                sources.push(aR, aRi, aRr);
            });
            item.ignitionSources = sources;
        }

    }

    async function handleFamily(families: any, sub_families: any, item: any): Promise<boolean> {
        //Old risk are associated with a family OR a sub family. Find the family or sub family.
        const family = families.find((f: any) => f.f_id === item.f_id);
        const subFamily = sub_families.find((sf: any) => sf.sf_id === item.sf_id);

        let isSubFamily = false;
        let label: string = '';

        if (family) {
            label = decode_utf8(family.f_libelle);
        }
        if (subFamily) {
            label = decode_utf8(subFamily.sf_libelle);
            isSubFamily = true;
        }
        if (!family && !subFamily) {
            //Skip case where the risk is not associated with a family or a sub family.
            console.error('Risk not associated with a family or a sub family. Skipping. ');
            console.error('Family id: ' + item.f_id);
            console.error('Sub family id: ' + item.sf_id);
            return new Promise((resolve) => resolve(true));
        }
        //Check if the family or sub family already exists in our database
        const existingFamily = subFamilyExists(label);
        //If family exist in DB , associate it with the subFamily
        if (existingFamily && typeof existingFamily == 'object') {
            //Associate the subFamily with the existing one
            item.sf_id = existingFamily.id;
            //Check if the subFamily is chemical
            const isChemical = isRiskChemical(existingFamily.name!, getExposition(item.r_exposition));
            if (isChemical) {
                setMessage('Chemical risk found in family ' + existingFamily.name! + ' for item ' + item.r_id);
                console.warn('Chemical risk found in family ' + existingFamily.name! + ' for item ' + item.r_id);
                await setAndGetChemicalRisk(item);
            }

            //Check if the subFamily is atex
            const isAtex = isRiskAtex(existingFamily.name!);
            if (isAtex) {
                setMessage('Atex risk found in family ' + existingFamily.name! + ' for item ' + item.r_id);
                console.warn('Atex risk found in family ' + existingFamily.name! + ' for item ' + item.r_id);
                await setAndGetAtexRisk(item);
            }
            return new Promise((resolve) => resolve(true));
        } else {
            //Create the family only for current user
            return createSubFamily(isSubFamily, family, subFamily, item, families);
        }
    }

    async function setAndGetChemicalRisk(item: any) {
        // Get the chemical risk from old database
        const data = await api.get(Endpoints.CHEMICAL_RISKS_CAPSE, [['id', item.r_id]]);

        function getMethodOfUse(procede: string) {
            switch (procede) {
                case '1':
                    return 'open';
                case '2':
                    return 'dispersif';
                case '3':
                    return 'open_closed';
                case '4':
                    return 'close';
                default:
                    return null;
            }
        }

        //Risque brut
        const cR = {
            slug: Prefix.RB,
            ratingCriteria: data.danger,
            quantityCriteria: data.quantite,
            frequencyCriteria: data.frequence,
            expositionType: data.exposition === '1' ? 'inhalation' : 'cutanee',
            emissionClass: data.emission,
            methodOfUse: getMethodOfUse(data.procede),
            expositionSituation: data.situation,
            contactClass: data.classe
        };
        //Risque initial
        const cRi = {
            slug: Prefix.RI,
            ratingCriteria: data.danger_i ?? null,
            quantityCriteria: data.quantite_i ?? null,
            frequencyCriteria: data.frequence_i ?? null,
            expositionType: data.exposition_i === '1' ? 'inhalation' : 'cutanee',
            emissionClass: data.emission_i ?? null,
            methodOfUse: getMethodOfUse(data.procede_i),
            expositionSituation: data.situation_i ?? null,
            contactClass: data.classe_i ?? null
        };
        //Risque residuel
        const crR = {
            slug: Prefix.RR,
            ratingCriteria: data.danger_r ?? null,
            quantityCriteria: data.quantite_r ?? null,
            frequencyCriteria: data.frequence_r ?? null,
            expositionType: data.exposition_r === '1' ? 'inhalation' : 'cutanee',
            emissionClass: data.emission_r ?? null,
            methodOfUse: getMethodOfUse(data.procede_r),
            expositionSituation: data.situation_r ?? null,
            contactClass: data.classe_r ?? null
        };
        //Associate the chemical risk to the risk
        item.chemicalRisk = [
            cleanObj(cR),
            createChemicalRisk(cleanObj(cRi)),
            createChemicalRisk(cleanObj(crR))
        ]
    }

    /**
     * Check if the sub family already exists in our database
     * Return the sub family if it exists
     * Return false if it doesn't exist
     * @param label
     */
    function subFamilyExists(label: string): DangerSubFamily | boolean {
        if (subFamilies) {
            const family = subFamilies.find((f: DangerSubFamily) => f.name === label);
            if (family) {
                return family;
            } else {
                return false;
            }
        }
        return false;
    }

    /**
     * Check if the family already exists in our database
     * Return the family if it exists
     * Return false if it doesn't exist
     * @param label
     */
    function familyExists(label: string): DangerFamily | boolean {
        if (families) {
            const family = families.find((f: DangerFamily) => f.name === label);
            if (family) {
                return family;
            } else {
                return false;
            }
        }
        return false;
    }

    function getExposition(r_exposition: any) {
        return r_exposition && r_exposition !== '0' ? expositions[parseInt(r_exposition) - 1].id : RiskExposition.ACCIDENTAL;
    }

    async function transform(results: any[]) {
        //Get old families and subFamilies
        const risks = await api.get(Endpoints.RISKS);
        const families = await api.get(Endpoints.FAMILIES_CAPSE);
        const sub_families = await api.get(Endpoints.SUB_FAMILIES_CAPSE);
        //Decode subfamilies & families
        //TODO : Decode family here (Done in PHP for now)
        families.map((f: any) => {
            f.f_libelle = decode_utf8(f.f_libelle);
            return f;
        });
        sub_families.map((sf: any) => {
            sf.sf_libelle = decode_utf8(sf.sf_libelle);
            return sf;
        });
        const temp: any[] = [];
        for (const item of results) {
            const exist = risks.find((r: any) => r.r_id === item.r_id);
            if (exist) {
                console.log(`Risk ${item.r_id} already exists`);
                continue;
            }
            //Init chemical risk
            item.chemicalRisk = [];
            //Associate with good subfamily (create if not exists)
            await handleFamily(families, sub_families, item);
            //Create risk from item
            const risk = buildRiskfromItem(item);
            setMessage('Nouveau Risque importé: ' + risk.id);
            console.log('Nouveau Risque importé: ' + risk.id);
            const t = {...risk};
            delete t.id;
            t.dangerFamily = 'api/danger_sub_families/' + item.sf_id;
            //TODO : Await here. Risk by risk.
            await createRisk(t);
            temp.push(risk);
        }
        return temp;
    }

    function buildRiskfromItem(item: any) {
        return {
            id: item.r_id,
            operation: 'api/operations/' + item.o_id,
            dangerFamily: item.sf_id,
            startDate: item.date_debut && item.date_debut !== 'NULL' ? item.date_debut : new Date().toISOString().substr(0, 10),
            endDate: item.date_fin,
            description: decode_utf8(item.r_situation),
            workingCondition: item.r_condition && item.r_condition !== '0' ? workingConditions[parseInt(item.r_condition) - 1].id : null,
            exposition: getExposition(item.r_exposition),
            rbInfos: decode_utf8(item.r_information),
            rbFrequency: Number(decode_utf8(item.r_fi_unit)),
            rbIntensity: Number(decode_utf8(item.r_brut_intensite)),
            rbProbability: Number(decode_utf8(item.r_p)),
            rbGravity: Number(decode_utf8(item.r_g)),
            riOrgaPrev: decode_utf8(item.r_mre_orga_prev),
            riOrgaProt: decode_utf8(item.r_mre_orga_prot),
            riCollPrev: decode_utf8(item.r_mre_coll_prev),
            riCollProt: decode_utf8(item.r_mre_coll_prot),
            riIndivPrev: decode_utf8(item.r_mre_indi_prev),
            riIndivProt: decode_utf8(item.r_mre_indi_prot),
            riInfos: decode_utf8(item.r_points_faibles),
            riFrequency: Number(decode_utf8(item.r_mi_unit_prev)),
            riIntensity: Number(decode_utf8(item.r_mi_unit_prot)),
            riMasterPrev: Number(decode_utf8(item.r_mi_prev)),
            riMasterProt: Number(decode_utf8(item.r_mi_prot)),
            rrOrgaPrev: decode_utf8(item.r_mrp_orga_prev),
            rrOrgaProt: decode_utf8(item.r_mrp_orga_prot),
            rrCollPrev: decode_utf8(item.r_mrp_coll_prev),
            rrCollProt: decode_utf8(item.r_mrp_coll_prot),
            rrIndivPrev: decode_utf8(item.r_mrp_indi_prev),
            rrIndivProt: decode_utf8(item.r_mrp_indi_prot),
            rrInfos: "",
            rrFrequency: Number(decode_utf8(item.r_mr_unit_prev)),
            rrIntensity: Number(decode_utf8(item.r_mr_unit_prot)),
            rrMasterPrev: Number(decode_utf8(item.r_mr_prev)),
            rrMasterProt: Number(decode_utf8(item.r_mr_prot)),
            number: Number(item.r_numero),
            chemicalRisks: item.chemicalRisk,
            rbAtexZoning: item.rb_atex_zoning,
            rbPersistence: item.rb_persistence,
            riAtexZoning: item.ri_atex_zoning,
            riPersistence: item.ri_persistence,
            rrAtexZoning: item.rr_atex_zoning,
            rrPersistence: item.rr_persistence,
            ignitionSources: item.ignitionSources
        };
    }

    const getRisks = useMutation(() => api.get(Endpoints.RISKS_CAPSE, [["username", username]]), {
        onSuccess: async (result) => {
            result.length > 0 ? setMessage(`${result.length} sont en cours d'import`) : setMessage(`Aucun risque n'a été importé`);
            setRisks(await transform(result));
        }
    });

    function handleChange(value: number, id: number) {
        const temp = [...risks];
        const currentRisk = temp.find(risk => risk.id === id);
        currentRisk.dangerFamily = value;
        setRisks(temp);
    }

    // function validate() {
    //     debugger;
    //     risks.map(async risk => {
    //         const temp = {...risk};
    //         delete temp.id;
    //         risk.dangerFamily = 'api/danger_sub_families/' + temp.dangerFamily;
    //         //TODO : Await here. Risk by risk.
    //         await post.mutate(temp);
    //         // return temp;
    //     });
    //
    // }

    function allRiskHaveFamilies() {
        return risks.every(risk => risk.dangerFamily !== null);
    }

    async function updateMaster() {
        const risks = await api.get(Endpoints.RISKS);
        console.log(risks);
        for (const r of risks) {
            const temp: any = {};
            if ((r.rrMasterPrev == r.rrMasterProt) && r.id > 2814) {
                temp.rrMasterPrev = Math.min((Number(r.riMasterPrev) + Number(r.rrMasterPrev - 1)), 4);
                temp.rrMasterProt = Math.min((Number(r.riMasterProt) + Number(r.rrMasterProt - 1)), 4);
                await api.patch(Endpoints.RISKS, temp, Number(r.id));
            }
        }
    }

    return (
        <div className="m-4">
            <p>{message}</p>

            <div className="grid grid-cols-4 gap-4">
                <Field name="username" type="text" value={username}
                       onChange={(e) => setUsername(e.target.value)}>Identifiant Client CAPSE </Field>
                <Button styles={"mt-5"} isLoading={getRisks.isLoading} trigger={getRisks.mutate}
                        label={["Importer"]}/>
                <Button styles={"mt-5"} trigger={() => updateMaster()}
                        label={["Update"]}/>
            </div>

            <div className="m-4">
                {risks.map(risk => {
                    return (
                        <div key={risk.id} className="grid grid-cols-3 gap-4">
                            <p className="p-2">Date : {risk.startDate !== 'NULL' ? risk.startDate : 'Pas de date'}</p>
                            <p className="p-2">Description : {risk.description ?? 'Pas de description'}</p>
                            <FieldSelectGroup
                                className=""
                                name={"ImportSubFamily"}
                                options={subFamilies}
                                groups={families}
                                value={risk.dangerFamily ? risk.dangerFamily : null}
                                isLoading={isLoadingFamilies || isLoadingSubFamilies}
                                onChange={(e) => handleChange(e.target.value, risk.id)}/>
                        </div>
                    )
                })}
            </div>
            {/*<Button disabled={!risks.length || !allRiskHaveFamilies()} trigger={validate}*/}
            {/*        label={["Valider toutes les lignes"]}/>*/}

        </div>

    )
}


