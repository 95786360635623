import React from "react";
import {TableCell, TableRow, TableSortLabel} from "@material-ui/core";
import {DataColumn} from "../../models/DataColumn";

interface TableThProps {
    columns: DataColumn[];
    columnsSpan?: DataColumn[];
    order: 'asc' | 'desc';
    orderBy: string;
    createSortHandler: (x: any) => (y: React.MouseEvent<unknown>) => void;
}

export default function TableTh({columns, columnsSpan, order, orderBy, createSortHandler}: TableThProps) {
    function styles(minWidth: number, isSecondRow: boolean = false): any {
        let classes = {
            minWidth: minWidth,
            backgroundColor: "#3B9DC0",
            color: "#FFFFFF",
            fontWeight: "600",
            border: "none"
        }
        return isSecondRow ? {...classes, borderTop: "1px solid white"} : classes
    }

    return (
        <>
            {columnsSpan ? <TableRow>
                {columnsSpan.map((column: DataColumn, index: number) => (
                    !column.hideTh &&
                    <Cell
                        key={column.id + index}
                        column={column}
                        order={order}
                        orderBy={orderBy}
                        index={index}
                        styles={styles}
                        createSortHandler={createSortHandler}
                    />
                ))}
            </TableRow> : null}
            {columns ? <TableRow>
                {columns.map((column: DataColumn, index: number) => (
                    !column.hideTh &&
                    <Cell
                        key={column.id + index}
                        column={column}
                        order={order}
                        orderBy={orderBy}
                        index={index}
                        styles={styles}
                        createSortHandler={createSortHandler}
                        isSecondRow={columnsSpan !== undefined}
                    />
                ))}
            </TableRow> : null}

        </>
    )
}

interface CellProps {
    column: DataColumn;
    order: 'asc' | 'desc';
    orderBy: string;
    index: number;
    styles: (x: number, y: boolean) => any;
    createSortHandler: (x: any) => (y: React.MouseEvent<unknown>) => void;
    isSecondRow?: boolean;
}

function Cell({column, order, orderBy, index, styles, createSortHandler, isSecondRow = false}: CellProps) {
    return (<TableCell
        className={'p-1'} padding={"none"}
        colSpan={column.colSpan}
        rowSpan={column.rowSpan}
        key={column.id + '_' + index}
        align={column.align}
        sortDirection={orderBy === column.id ? order : false}
        style={styles(column.minWidth ?? 100, isSecondRow)}>
        {column.id === "favorite" || column.id === "action" || column.id === "active" ?
            column.label
            :
            <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : 'asc'}
                onClick={createSortHandler(column.id)}
            >
                {column.label}
            </TableSortLabel>
        }
    </TableCell>)
}
