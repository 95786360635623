import React, { useState } from "react";
import { FieldTextArea } from "../utils/Inputs/FieldTextArea";
import { authService } from "../../services/auth.service";
import { useMutation } from "react-query";
import Api from "../../services/api.service";
import { Endpoints } from "../../services/endpoints";
import { addToLocalStorageObject } from "../../helpers/Helpers";
import Button from "../utils/Inputs/Button";
import { withSnackbar } from "../utils/blocks/SnackBar";

function CompanyDescriptionPageWrapped({ snackbar }: { snackbar?: any }) {
    const user = authService.getCurrentUser();
    const api = new Api();
    const [postDescription, setPostDescription] = useState<any>({ description: user.description });
    const mutation = useMutation(() => api.patch(Endpoints.USERS, postDescription, user.id), {
        //Update local storage
        onSuccess: () => {
            addToLocalStorageObject('user', 'description', postDescription.description)
            snackbar('Description modifié')
        }, onError: () => {
            snackbar('Une erreur est survenue', 'error')
        }
    });

    function handleChange(e: any) {
        setPostDescription({ description: e.target.value });
    }

    function handleSubmit() {
        if (user.description !== postDescription.description) {
            mutation.mutate();
        }
    }

    return (
        <div className="m-8"><FieldTextArea name="description" onChange={handleChange}
            value={postDescription.description}>Description</FieldTextArea>
            <Button disabled={user.description === postDescription.description} isLoading={mutation.isLoading} trigger={handleSubmit} styles="btn-outlined mt-2" label={["Valider"]} />
        </div>
    )
}

const CompanyDescriptionPage = withSnackbar(CompanyDescriptionPageWrapped);
export default CompanyDescriptionPage;