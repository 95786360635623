import React from "react";
import {FieldTextArea} from "../../utils/Inputs/FieldTextArea";
import {Field} from "../../utils/Inputs/Field";
import {DangerSubFamily} from "../../../models/DangerSubFamily";
import {useQuery} from "react-query";
import {Endpoints} from "../../../services/endpoints";
import {DangerConfiguration} from "../../../models/DangerConfiguration";
import Api from "../../../services/api.service";
import {FieldSelectArray} from "../../utils/Inputs/FieldSelectArray";
import {Rb} from "../../../models/Risk";
import RiskStepChemical from "./RiskStepChemical";
import {ChemicalRisk} from "../../../models/ChemicalRisk";
import RiskStepAtex from "./RiskStepAtex";
import {isRiskAtex, isRiskChemical, isRiskVibrate} from "../../../helpers/riskHelpers";
import {Prefix} from "../../../models/Prefix";
import {RiskExposition} from "../../../models/RiskExposition";

export interface RiskStepFiveProps {
    rb: Rb,
    setRb: (value: Rb) => void,
    chemicalRisk: ChemicalRisk,
    setChemicalRisk: (value: ChemicalRisk) => void,
    subFamily: DangerSubFamily,
    exposition: RiskExposition,
}

//TODO : Factorize with Step 6 && 7
export default function RiskStepFive({
                                         rb,
                                         setRb,
                                         subFamily,
                                         chemicalRisk,
                                         setChemicalRisk,
                                         exposition
                                     }: RiskStepFiveProps) {
    const api = new Api();
    const isChemicalRisk = subFamily.name && isRiskChemical(subFamily.name, exposition);
    const isAtex = subFamily.name && isRiskAtex(subFamily.name);

    const configuration = useQuery<DangerConfiguration[]>(
        Endpoints.DANGER_CONFIGURATION,
        () => api.getAll(Endpoints.DANGER_CONFIGURATION, [["dangerSubFamily.id", subFamily.id + '']])
    )
    return (
        <div className="grid grid-cols-2 gap-4">
            {!isChemicalRisk && !isAtex && <FieldTextArea
                name='rbInfos'
                onChange={(e) => setRb({...rb, rbInfos: e.target.value})}
                value={rb.rbInfos ?? ''}
                rows={5}>
                Informations complémentaires
            </FieldTextArea>}

            {isChemicalRisk && <RiskStepChemical chemicalRisk={chemicalRisk} setChemicalRisk={setChemicalRisk}/>}
            {isAtex && <RiskStepAtex risk={rb} setRisk={setRb} slug={Prefix.RB}/>}
            {subFamily.isPainful && !isChemicalRisk && !isAtex &&
                <div>
                    <Field type='number'
                           name='rbFrequency'
                           onChange={(e) => setRb({...rb, rbFrequency: e.target.value})}
                           value={rb.rbFrequency ?? ''}>
                        Fréquence en {subFamily.frequencyUnit}
                    </Field>

                    <Field type='number'
                           step={isRiskVibrate(subFamily.name!) ? 0.01 : 1}
                           name='rbIntensity'
                           onChange={(e) => setRb({...rb, rbIntensity: e.target.value})}
                           value={rb.rbIntensity ?? ''}>
                        Intensité en {subFamily.intensityUnit}
                    </Field>
                </div>}
            {!configuration.isLoading && configuration.data && !subFamily.isPainful && !isChemicalRisk && !isAtex &&
                <>
                    <FieldSelectArray
                        className="mb-2"
                        name="probabilityCriteria"
                        bubbleInfos={configuration.data[0].bubblesInfos}
                        options={configuration.data[0].coupleProbabilityGravity.G}
                        value={rb.rbProbability}
                        onChange={(e) => setRb({...rb, rbProbability: e.target.value})}>
                        Choisissez une probablité
                    </FieldSelectArray>

                    <FieldSelectArray
                        className="mb-2"
                        name="severityCriteria"
                        bubbleInfos={configuration.data[0].bubblesInfos}
                        options={configuration.data[0].coupleProbabilityGravity.PF}
                        value={rb.rbGravity}
                        onChange={(e) => setRb({...rb, rbGravity: e.target.value})}>
                        Choisissez une gravité
                    </FieldSelectArray>
                </>
            }
        </div>

    )
}
