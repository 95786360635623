import {Color} from "@material-ui/lab/Alert";
import React, {useState} from 'react'
import {PathType} from '../../helpers/PathType'
import {DuplicateIcon} from '../../Icons'
import ModalComponent from '../modals/Modal'
import Button from "./Inputs/Button"
import {withSnackbar} from "./blocks/SnackBar";

function DuplicateRisksWrapped({snackbar, operation}: { snackbar?: any, operation: number }) {
    const [modalVisibility, setModalVisibility] = useState(false)

    function toggleModal(event?: object, reason?: string, message?: string, severity: Color = "success") {
        setModalVisibility(!modalVisibility);
        if (message) {
            snackbar(message, severity);
        }
    }

    return (
        <div title="Dupliquer des risques">
            <ModalComponent
                modalVisibility={modalVisibility}
                path={PathType.RISKS_DUPLICATION}
                toggleModal={toggleModal}
                method={"duplicate"}
                data={operation}
            />
            <Button styles="mx-2" trigger={toggleModal} label={[<DuplicateIcon/>]}/>
        </div>
    )
}

export const DuplicateRisk = withSnackbar(DuplicateRisksWrapped);
