import {Fade, Popper, Typography} from "@material-ui/core";
import React from "react";
import {ArchiveIcon, CorrectIcon, PencilIcon, TrashIcon} from "../../Icons";
import "../../index.css";
import {methodType, toggleModalType} from "../modals/Modal";
import {PathType} from "../../helpers/PathType";

const Popup = React.memo(function PopupComponent({
                                                     popperVisibility,
                                                     popperAnchor,
                                                     toggleModal,
                                                     changeMethod,
                                                     path,
                                                     item
                                                 }: {
    popperVisibility: boolean;
    popperAnchor: HTMLDivElement | null;
    toggleModal: toggleModalType;
    changeMethod: (x: methodType) => void;
    path: PathType;
    item: any
}) {
    function handleOnClick(method: methodType) {
        changeMethod(method);
        toggleModal();
    }

    const isRisk = path === PathType.RISKS;
    const isDisabledRisk = isRisk && item && item.endDate;
    const style = "flex items-center cursor-pointer text-gray-dark hover:text-blue-text";
    const disabledStyle = "flex items-center cursor-not-allowed text-gray-dark";
    return (
        <Popper open={popperVisibility} anchorEl={popperAnchor} placement={"left-start"} transition>
            {({TransitionProps}) => (
                <Fade {...TransitionProps} timeout={150}>
                    <Typography
                        className="border border-gray-light bg-white p-2 rounded-2xl divide-y divide-gray-light shadow-sm">
                        <span onClick={() => handleOnClick("edit")} className={style + ' pb-1'}>
                            <PencilIcon/>
                            <span className="pl-2">Modifier</span>
                        </span>
                        <span onClick={!isDisabledRisk ? () => handleOnClick("delete") : undefined}
                              className={
                                  !isRisk ? style + ' pb-1' :
                                      isDisabledRisk ? disabledStyle + ' pb-1 pt-1' :
                                          style + ' pb-1 pt-1'}>
                            <TrashIcon/>
                            <span className="pl-2">Supprimer</span>
                        </span>
                        {isRisk &&
                        <>
                            <span onClick={() => handleOnClick("evolve")} className={style + ' pb-1 pt-1'}>
                                <CorrectIcon/>
                                <span className="pl-2">Évoluer</span>
                            </span>
                            <span onClick={!isDisabledRisk ? () => handleOnClick("archive") : undefined}
                                  className={isDisabledRisk ? disabledStyle + ' pt-1' : style + ' pt-1'}>
                                <ArchiveIcon/>
                                <span className="pl-2">Archiver</span>
                            </span>
                        </>

                        }
                    </Typography>
                </Fade>
            )}
        </Popper>
    );
});
export default Popup;
