import {User} from "../models/User";
import {DataColumn} from "../models/DataColumn";
import {
    getChemicalQuoting,
    getChemicalRisk,
    getCutaneeExposition,
    getGravity,
    getNewPorG,
    getPotentialExposition,
    getProbability,
    MATRIX_ATEX,
    MATRIX_CHEMICAL_RB
} from "./riskHelpers";
import {Prefix} from "../models/Prefix";
import {ChemicalRisk} from "../models/ChemicalRisk";
import {
    atexInfosLabels,
    emissionClasses,
    expositionSituations,
    methodsOfUse,
    ratingsCriteria,
    riskInfosLabels
} from "./DataColumns/RiskColumns";
import {IgnitionSource} from "../models/IgnitionSource";
import React from "react";
import {authService} from "../services/auth.service";


export function getDate(date: string): string {
    if (date) {
        return (new Date(date)).toLocaleDateString();
    }
    return '';
}

export function getTwoTypeRow(valueOne: any, valueTwo: any, isPainful: boolean = false,
                              isMasterIndex: boolean = false, row?: any, prefix = Prefix.RI): JSX.Element {
    const user = authService.getCurrentUser();
    const div = <div className="flex flex-col justify-around content-center">
        <p className="capse-m-h border-b">{valueOne}</p>
        <p className="capse-m-h">{valueTwo}</p>
    </div>;
    const [a, b] = getMasterIndexFromRisk(row, prefix, user);

    return isPainful && isMasterIndex ?
        getTwoTypeRow(a, b) :
        div;
}

export function getMasterIndexFromRisk(row: any, prefix: Prefix, user: User): [mF: number | string, mI: number | string] {
    if (row && prefix) {
        const p = row.rbProbability;
        const g = row.rbGravity;

        if (p && g) {
            const pX = getProbability(Number(row[prefix + 'Frequency']), row.dangerFamily)
            const gX = getGravity(Number(row[prefix + 'Intensity']), row.dangerFamily);

            if (pX && gX) {
                const size = user.matrixCotationRiskControled.value.length;
                const pYs = user.matrixCotationRiskControled.value[size - p];
                const gYs = user.matrixCotationRiskControled.value[size - g];

                const mP = pYs.findIndex((p: number) => p === pX) !== -1 ? pYs.findIndex((p: number) => p === pX) + 1 : 1;
                const mG = gYs.findIndex((g: number) => g === gX) !== -1 ? gYs.findIndex((g: number) => g === gX) + 1 : 1;
                return [mP, mG];
            }

        }
    }
    return ['N/A', 'N/A']
}

export function getRiskValueFromRiskBrut(row: any, user: User, column: DataColumn) {
    const prefix = column.id.substr(0, 2);
    if (row.rbProbability && row.rbGravity) {
        return column.id.includes('Probability') ?
            getNewPorG(row.rbProbability, row[prefix + 'MasterPrev'], user.matrixCotationRiskControled) :
            getNewPorG(row.rbGravity, row[prefix + 'MasterProt'], user.matrixCotationRiskControled);
    }
}

// TODO : Test function for this method
export function getRiskValueWhenIsPainful(row: any, column: DataColumn) {
    const prefix = column.id.substr(0, 2);
    return column.id.includes('Probability') ?
        getProbability(Number(row[prefix + 'Frequency']), row.dangerFamily) :
        getGravity(Number(row[prefix + 'Intensity']), row.dangerFamily);
}

// TODO : Test function for this method
export function getRiskValueWhenIsChemical(row: any, column: DataColumn) {
    const prefix = column.id.substr(0, 2) as Prefix;
    const chemicalRisk = getChemicalRisk(row, prefix);
    if (column.id.includes('Probability')) {
        return chemicalRisk['probability'] ?? 'N/A';
    } else {
        return chemicalRisk['gravity'] ?? 'N/A';
    }
}


export function getRiskValueWhenIsAtex(row: any, column: DataColumn, prefix: Prefix) {
    if (column.id.includes('Probability')) {
        return getAtexProbability(row.ignitionSources, prefix);
    } else {
        return 4;
    }
}

export function displayChemicalInfos(column: DataColumn, row: any): JSX.Element[] {
    let result: string[] = [];
    let prefix: Prefix = column.id.substr(0, 2) as Prefix;
    const chemicalRisk = row.chemicalRisk.find((config: ChemicalRisk) => config.slug === prefix)
    Object.entries(chemicalRisk).forEach(([key, value]) => {
        result.push(riskInfosLabels[key]?.find((item: { id: string }) => item.id === value)?.name ?? "");
    })
    return result.map((item, index) => <p key={item + row.id + index}>{item}</p>);
}

export function displayAtexInfos(column: DataColumn, row: any) {
    let result: string[] = [];
    let prefix: Prefix = column.id.substr(0, 2) as Prefix;
    const sources = row.ignitionSources
        .filter((a: IgnitionSource) => a.slug === prefix)
        .sort((a: any, b: any) => a.probability < b.probability ? -1 : a.probability > b.probability ? 1 : 0);
    const source = sources.length ? sources[(sources.length) - 1] : '';
    Object.entries(source).forEach(([key, value]) => {
        result.push(atexInfosLabels[key]?.find((item: { id: string }) => item.id === value)?.name ?? "");
    })
    return source ? result.map((item, index) => <p key={item + row.id + index}>{item}</p>) : '';
}

export function getImage(style: {}, src: string): JSX.Element {
    return <img className="border-2 border-gray-light h-10 w-10 rounded" style={style}
                src={process.env.PUBLIC_URL + "/pictograms/" + src} alt={src}/>
}

export function getAtexProbability(sources: IgnitionSource[] | undefined, prefix: Prefix) {
    let probabilities: number[] = [];
    sources && sources.filter((s: IgnitionSource) => s.slug === prefix).forEach((s: IgnitionSource) => {
        if (s.probability && s.atexZone?.length) {
            const z = s.atexZone[0];
            // id : p1 ==> 1
            const p = Number(s.probability.charAt(1)) - 1;
            probabilities.push(MATRIX_ATEX[z][p]);
        }
    });
    return probabilities.length && sources ? Math.max(...probabilities) : null;
}

export function getChemicalProbability(chemicalRisk: ChemicalRisk) {
    if (chemicalRisk!.expositionType === 'inhalation') {
        //STEP 1 : CALCUL RB
        const pE = getPotentialExposition(chemicalRisk);
        const danger = getChemicalQuoting(chemicalRisk, 'ratingCriteria', ratingsCriteria);
        const rB = danger && pE && MATRIX_CHEMICAL_RB[pE][danger - 1] ? MATRIX_CHEMICAL_RB[pE][danger - 1] : null;

        //STEP 2 : rb x volatite x procede x protection collective
        const v = getChemicalQuoting(chemicalRisk, 'emissionClass', emissionClasses);
        const p = getChemicalQuoting(chemicalRisk, 'methodOfUse', methodsOfUse);
        const pC = getChemicalQuoting(chemicalRisk, 'expositionSituation', expositionSituations);
        const index = rB * v * p * pC;

        switch (true) {
            case (index === 0) :
                return 'N/A';
            case (index < 100) :
                return 1;
            case (index >= 100 && index < 1000) :
                return 2;
            case (index >= 1000 && index < 1000) :
                return 3;
            case (index >= 10000) :
                return 4;
            default :
                return 'N/A'
        }
    } else {
        return getCutaneeExposition(chemicalRisk!);
    }
}
