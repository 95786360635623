import React from "react";
import "../../index.css";
import { StyledTab, StyledTabs, TabPanel } from "../utils/TabsComponents";
import { groupBy } from "../../helpers/Helpers";
import { DataColumn } from "../../models/DataColumn";
import { Field } from "../utils/Inputs/Field";
import { Tab } from "../../models/Tab";
import { FieldTextArea } from "../utils/Inputs/FieldTextArea";
import { FieldRadio } from "../utils/Inputs/FieldRadio";
import { FieldSelect } from "../utils/Inputs/FieldSelect";
import { Select } from "@material-ui/core";
import { PICTOGRAMS } from "../../helpers/Constants";

export interface ModalTabsProps {
    tabs?: Tab[];
    inputs: any;
    formData: any;
    setFormData: (x: any) => void;
    grid?: boolean;
}

const ModalTabs = React.memo(function ModalTabs({
    tabs,
    inputs,
    formData,
    setFormData,
    grid
}: ModalTabsProps) {
    const [currentTab, setCurrentTab] = React.useState<number>(0);
    const handleChange = (event: React.ChangeEvent<{}>, tab: number) => {
        setCurrentTab(tab);
    };

    const dataTabs = groupBy(inputs, "modalTab");

    return (
        <div>
            <StyledTabs value={currentTab} onChange={handleChange} aria-label="styled tabs">
                {tabs && tabs.map((tab: any, index: number) => (
                    <StyledTab key={index} label={tab.name} />
                ))}
            </StyledTabs>
            {
                Object.keys(dataTabs).map((key: string, index: any) => {
                    return (
                        <TabPanel key={key} value={currentTab} index={index}>
                            <RenderInputs elements={dataTabs[key]} formData={formData} setFormData={setFormData} grid={grid} />
                        </TabPanel>
                    )
                })}
        </div>
    );
});
export default ModalTabs;

export interface RenderInputsProps {
    elements: DataColumn[];
    formData: any;
    setFormData: (x: any) => void;
    grid?: boolean;
}

export function RenderInputs({ elements, formData, setFormData, grid }: RenderInputsProps) {

    function handleRadioChange(e: any) {
        setFormData({ ...formData, isPainful: e.target.value === "true" });
    }

    function handleChange(e: any, attribute: string) {
        setFormData({ ...formData, [attribute]: e.target.value });
    }

    const handleChangeMultiple = (event: React.ChangeEvent<{ value: unknown }>, attribute: string) => {
        const { options } = event.target as HTMLSelectElement;
        const value: string[] = [];
        for (let i = 0, l = options.length; i < l; i += 1) {
            if (options[i].selected) {
                value.push(options[i].value);
            }
        }
        setFormData({ ...formData, [attribute]: value });
    };

    return (
        <div className={grid ? "grid grid-cols-2 gap-4" : ""}>
            {elements.map((item: DataColumn) => {
                if (!item.hideModal) {
                    return <RenderElement
                        key={item.id}
                        item={item}
                        formData={formData}
                        handleChange={handleChange}
                        handleRadioChange={handleRadioChange}
                        handleChangeMultiple={handleChangeMultiple}
                    />
                }
                return null;
            })}
        </div>
    );
}

interface RenderElementInterface {
    item: DataColumn;
    formData: any;
    handleChange: (e: any, attribute: string) => void;
    handleRadioChange: (e: any) => void;
    handleChangeMultiple: (e: any, attribute: string) => void;
}

export function RenderElement({ item, formData, handleChange, handleRadioChange, handleChangeMultiple }: RenderElementInterface) {
    switch (item.type) {
        case ('string'):
        case ('number'):
        case ('date'):
            return (
                <div key={item.id}>
                    <Field type={item.type}
                        name={item.id}
                        onChange={(e) => handleChange(e, item.id)}
                        value={formData[item.id] ? formData[item.id] : ""}>
                        {(item.modalLabel ?? item.label) + (item.required ? "*" : "")}
                    </Field>
                </div>
            )
        case ('textarea'):
            return (
                <div key={item.id}>
                    <FieldTextArea
                        name={item.id}
                        onChange={(e) => handleChange(e, item.id)}
                        value={formData[item.id] ? formData[item.id] : ""}>
                        {(item.modalLabel ?? item.label) + (item.required ? "*" : "")}
                    </FieldTextArea>
                </div>
            )
        case ('boolean'):
            return (
                <div key={item.id} className="mt-2 mb-6">
                    <label className="text-xl block">{item.label + (item.required ? "*" : "")}</label>
                    <div className="flex">
                        <FieldRadio name="yes" onChange={handleRadioChange} value="true"
                            checked={formData.isPainful}>
                            Oui
                        </FieldRadio>
                        <FieldRadio name="no" onChange={handleRadioChange} value="false"
                            checked={!formData.isPainful}>
                            Non
                        </FieldRadio>
                    </div>
                </div>
            )
        case ('image'):
            return (
                <div key={item.id} className="mt-2 mb-6">
                    <label className="text-xl block">{item.label}</label>
                    <div className="w-full grid grid-cols-6 mt-3">
                        {PICTOGRAMS.map((picto: string) => {
                            return (<div key={picto}
                                className="flex items-center justify-center p-2 border border-collapse">
                                <FieldRadio onChange={(e: any) => handleChange(e, "image")}
                                    value={picto}
                                    checked={formData.image === picto}>
                                    <img src={process.env.PUBLIC_URL + "/pictograms/" + picto}
                                        alt="pictogram" />
                                </FieldRadio>
                            </div>
                            );
                        })}
                    </div>
                </div>
            )
        case ('color'):
            return (
                <div className="mt-2 mb-6">
                    <label className="text-xl " htmlFor="">{(item.modalLabel ?? item.label) + (item.required ? "*" : "")}</label>
                    <input
                        className="input w-20 h-8 block cursor-pointer"
                        key={item.id}
                        type={item.type}
                        name={item.id}
                        onChange={(e) => handleChange(e, item.id)}
                        value={formData[item.id] ?? ""} />
                </div>
            )
        case 'select':
        case 'relation':
            return (
                <div key={item.id} className="mt-2 mb-6">
                    <FieldSelect options={item.data}
                        name={item.id}
                        onChange={(e) => handleChange(e, item.id)}
                        value={formData[item.id] ?? ''}>
                        {(item.modalLabel ?? item.label) + (item.required ? "*" : "")}
                    </FieldSelect>
                </div>
            )
        case 'multi-select':
            return (
                <div key={item.id} className="mt-2 mb-6">
                    <label className="text-xl" htmlFor="select-multiple-native">
                        {(item.modalLabel ?? item.label) + (item.required ? "*" : "")}
                    </label>
                    <Select
                        multiple
                        native
                        className="input border-0"
                        value={formData[item.id] ?? [""]}
                        onChange={(e) => handleChangeMultiple(e, item.id)}
                        inputProps={{
                            id: 'select-multiple-native',
                        }}
                    >
                        <option disabled value="">Choissiez une ou plusieurs option(s)</option>
                        {item?.data && !item.data[0].options && item.data.map((option) => (
                            <option key={option.id} value={option.id}>
                                {option.label}
                            </option>
                        ))}
                        {item?.data && item.data[0].options && item.data.map((option: any) => {
                            let currentPicto = formData.pictogram ? [...formData.pictogram] : [""]
                            if (formData.pictogram && currentPicto.includes(option.id)) {
                                return (
                                    option.options.map((element: string) => (
                                        <option key={element} value={element}>
                                            {element}
                                        </option>)
                                    )
                                )
                            } else {
                                return null
                            }
                        })}
                    </Select>
                </div>
            )
        default:
            return null
    }
}
