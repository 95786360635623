export enum Endpoints {
    USERS = 'users',
    SITES = 'sites',
    INSTALLATIONS = 'installations',
    WORK_UNIT = 'work_units',
    OPERATIONS = 'operations',
    SERVICES = 'services',
    EMPLOYEES = 'employees',
    CHEMICAL_PRODUCTS = 'chemical_products',
    RISKS = 'risks',
    EXCEL = 'excel',
    RISKS_CAPSE = 'capse',
    FAMILIES_CAPSE = 'capse_family',
    SUB_FAMILIES_CAPSE = 'capse_sub_family',
    CHEMICAL_RISKS_CAPSE = 'capse_chemical_risks',
    ATEX_RISKS_CAPSE = 'capse_atex_risks',
    DANGER_FAMILIES = 'danger_families',
    DANGER_SUB_FAMILIES = 'danger_sub_families',
    DANGER_CONFIGURATION = 'danger_configurations'
}
