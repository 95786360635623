import React from "react";
import {authService} from "../../services/auth.service";
import {LoaderIcon} from "../../Icons";
import {DataTable} from "../layout/DataTable";
import {DataColumn} from "../../models/DataColumn";
import {ErrorQuery} from "../utils/ErrorQuery";
import {PathType} from "../../helpers/PathType";
import {useLoadData} from "../hooks/useLoadData";

interface DangerPageProps {
    columns: DataColumn[];
    endpoint: string;
    path: PathType;
}


export default function DangerSubFamilyPage({columns, path}: DangerPageProps) {
    const user = authService.getCurrentUser();
    const {isLoading, data, isError} = useLoadData(path, path);

    if (isLoading)
        return (
            <div className="h-4/5 flex items-center justify-center">
                <LoaderIcon/>
            </div>
        );
    if (isError || data === undefined)
        return (
            <ErrorQuery>Erreur de chargement</ErrorQuery>
        );

    return <div className="h-4/5 overflow-auto">{user &&
        <DataTable data={data} columns={columns} path={path}/>}</div>;
}
