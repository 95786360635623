import {useQuery} from "react-query";
import {addOptionsToSelect} from "../../helpers/Helpers";
import Api from "../../services/api.service";
import {DataColumn} from "../../models/DataColumn";
import {useState} from "react";
import {methodType} from "../modals/Modal";

export const useLoadRelation = (
    path: string,
    endpoint: any,
    params: [string, string][],
    columns: DataColumn[] | undefined,
    attribute: string,
    method: methodType,
    item: any = {},
    parent: any,
    initFormData: {}
) => {
    const api = new Api();
    const [formData, setFormData] = useState(initFormData);
    const {isLoading} = useQuery(
        ["relation_" + path],
        () => {
            return api.getAll(endpoint, params);
        },
        {
            onSuccess: (results: any) => {
                if (results && results[0]) {
                    addOptionsToSelect(results, columns, attribute);
                    const value = method === "add" ? results[0].id : item[parent].id;
                    setFormData({...formData, [attribute]: value});
                } else {
                    addOptionsToSelect([], columns, attribute);
                }
            },
        }
    );

    return {
        isLoading,
        formData,
        setFormData
    }
}


