import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { NameType } from "../../helpers/PathType";
import { ErrorIcon, LoaderIcon, ResetIcon } from "../../Icons";
import Api from "../../services/api.service";
import { Cotation } from "../../helpers/Constants";
import { User } from "../../models/User";
import { authService } from "../../services/auth.service";
import Button from "./Inputs/Button";
import { Endpoints } from "../../services/endpoints";
import { DangerConfiguration } from "../../models/DangerConfiguration";

interface MartixSizeHandlerProps {
    isControlled: boolean;
    user: User;
    setUser: (x: User) => void;
}

export default function MartixSizeHandler({ isControlled, user, setUser }: MartixSizeHandlerProps) {
    const api = new Api();
    const [matrixSize, setMatrixSize] = useState<number>(user.matrixSize)
    const [matrixCotationRisk, setMatrixCotationRisk] = useState(user.matrixCotationRisk)
    const [matrixCotationRiskControled, setMatrixCotationRiskControled] = useState(user.matrixCotationRiskControled)

    const { data: dangerConfigurations } = useQuery((Endpoints.DANGER_CONFIGURATION), () => api.getAll(Endpoints.DANGER_CONFIGURATION));
    const { isLoading, isError, mutate } = useMutation(() => api.patch(Endpoints.USERS, {
        matrixSize: matrixSize,
        matrixCotationRisk: matrixCotationRisk,
        matrixCotationRiskControled: matrixCotationRiskControled
    }, user.id), {
        onSuccess: (result: any) => {
            let difference = matrixSize - user.matrixSize;
            setUser(result)
            dangerConfigurations?.forEach((config: DangerConfiguration) => {
                let temp = { ...config }
                if (difference > 0) {
                    for (let i = 0; i < difference; i++) {
                        temp.coupleProbabilityGravity.PF.push(1)
                        temp.coupleProbabilityGravity.G.push(1)
                    }
                }
                for (let i = difference; i < 0; i++) {
                    temp.coupleProbabilityGravity.PF.pop()
                    temp.coupleProbabilityGravity.G.pop()
                }
                mutateDangerConfiguration({ ...config, coupleProbabilityGravity: temp.coupleProbabilityGravity })
            })
        }
    });
    const { mutate: mutateDangerConfiguration } = useMutation((config: DangerConfiguration) => api.patch(Endpoints.DANGER_CONFIGURATION, config, config.id));

    useEffect(() => {
        let difference = matrixSize - user.matrixSize;
        if (difference !== 0) {
            setMatrixCotationRisk(updateArray(difference, matrixCotationRisk.value))
            setMatrixCotationRiskControled(updateArray(difference, matrixCotationRiskControled.value))
            mutate()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [matrixSize])

    useEffect(() => {
        setMatrixSize(user.matrixSize)
        setMatrixCotationRisk(user.matrixCotationRisk)
        setMatrixCotationRiskControled(user.matrixCotationRiskControled)
    }, [user])

    return (
        <>
            <div className="input w-max font-semibold text-black absolute top-0 left-0 rounded-lg border-2 border-gray-light bg-white flex m-3">
                <label htmlFor="matrix" className="my-auto">Modifier le format de la matrice:</label>
                <div className="ml-2 my-1">
                    {isLoading && !isError && <div className="mx-2 my-1"><LoaderIcon /></div>}
                    {!isLoading && isError && <div title=""><ErrorIcon /></div>}
                    {!isLoading && !isError &&
                        <select className="cursor-pointer hover:bg-gray-slight rounded-lg p-1" id="matrix" value={matrixSize} onChange={(e) => setMatrixSize(parseInt(e.target.value))}>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                        </select>
                    }
                </div>
            </div>
            <Reset nameType={isControlled ? NameType.COTATION_CONTROLED : NameType.COTATION} user={user} setUser={setUser} />
        </>
    );
}
interface ResetProps {
    nameType: NameType.COTATION_CONTROLED | NameType.COTATION;
    user: User;
    setUser: (x: User) => void;
}

function Reset({ nameType, user, setUser }: ResetProps) {
    const api = new Api();
    const [secondCotation, setSecondCotation] = useState<NameType.COTATION | NameType.COTATION_CONTROLED>(NameType.COTATION)
    const { isLoading: isLoadingMain, mutate: mutateMain } = useMutation(() => api.patch(Endpoints.USERS, {
        matrixSize: Cotation.COTATION_DEFAULT.matrixSize,
        [nameType]: Cotation.COTATION_DEFAULT[nameType]
    }, user.id), {
        onSuccess: (result: any) => {
            setUser(result)
            mutateSecond()
        }
    });
    const { isLoading: isLoadingSecond, mutate: mutateSecond } = useMutation(() => api.patch(Endpoints.USERS, {
        [secondCotation]: updateArray(Cotation.COTATION_DEFAULT.matrixSize - user.matrixSize, Cotation.COTATION_DEFAULT[secondCotation].value)
    }, user.id), {
        onSuccess: (result: any) => {
            setUser(result)
        }
    });

    function handleReset() {
        switch (nameType) {
            case NameType.COTATION:
                setSecondCotation(NameType.COTATION_CONTROLED)
                break;
            case NameType.COTATION_CONTROLED:
                setSecondCotation(NameType.COTATION)
                break;
        }
        mutateMain();
    }

    return <Button trigger={handleReset} isLoading={isLoadingMain || isLoadingSecond} styles="btn-outline absolute top-0 right-0 m-3" label={[<ResetIcon />, "Réinitialisation"]} />
}


function updateArray(difference: number, array: number[][]) {
    handleRows(difference, array)
    handleColumns(difference, array);
    return { value: array };
}

/**
 * Add or remove array on a given array of arrays, depending on the difference 
 * @param difference 
 * @param updatedArray 
 * 
 */
function handleRows(difference: number, updatedArray: number[][]) {
    const user = authService.getCurrentUser();
    if (difference > 0) {
        for (let i = 0; i < difference; i++) {
            let newRow = [];
            for (let y = 0; y < user.matrixSize; y++) {
                newRow.push(1)
            }
            updatedArray.push(newRow)
        }
        return updatedArray
    }
    for (let i = difference; i < 0; i++) {
        updatedArray.pop()
    }
}

/**
 * Add or remove elements on a given array, depending on the difference 
 * @param difference 
 * @param updatedArray 
 * 
 */
function handleColumns(difference: number, updatedArray: number[][]) {
    updatedArray.forEach((row) => {
        if (difference > 0) {
            for (let i = 0; i < difference; i++) {
                row.push(1)
            }
            return row;
        }
        for (let i = difference; i < 0; i++) {
            row.pop()
        }
        return row;
    })
}
