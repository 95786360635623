import MultiSelect from "../MultiSelect";
import {FieldSelectGroup} from "../Inputs/FieldSelectGroup";
import {parseIntIfNumber} from "../../../helpers/Helpers";
import ExportToXlsxPdf from "../ExportToXlsxPdf";
import {DuplicateRisk} from "../DuplicateRisks";

export default function Filters({
                                    selectsProps,
                                    subFamilies,
                                    families,
                                    dangerFamily,
                                    isLoadingFamilies,
                                    isLoadingSubFamilies,
                                    setDangerFamily,
                                    operation
                                }: any) {
    return (
        <div className="capse-filters">
            <div className="grid grid-cols-6 w-full">
                <MultiSelect selects={selectsProps} cols={"grid-cols-4 col-span-4 mr-5"}/>
                <FieldSelectGroup
                    className=""
                    name={"HomeSubFamily"}
                    options={subFamilies}
                    groups={families}
                    value={dangerFamily}
                    isLoading={isLoadingFamilies || isLoadingSubFamilies}
                    onChange={(e) => setDangerFamily(parseIntIfNumber(e.target.value))}/>
                <div className="flex items-center justify-end">
                    <ExportToXlsxPdf/>
                    <DuplicateRisk operation={operation}/>
                </div>
            </div>
        </div>

    )
}
