import {DataColumn} from "../../models/DataColumn";
import {align, disabled, hide, hideModal, hideTh, required} from "./ColumnsConstants";

export const sites: DataColumn[] = [
    {id: "favorite", label: "Favori", minWidth: 100, align, type: "favorite"},
    {id: "name", label: "Libellé", minWidth: 200, type: "string", required},
    {id: "description", label: "Description", minWidth: 200, type: "string"},
    {id: "action", label: "Action", minWidth: 100, align, type: "action"},
];

export const installations: DataColumn[] = [
    {id: "site.name", label: "Sites", minWidth: 200, type: "relation", required},
    {id: "name", label: "Libellé", minWidth: 200, type: "string", required},
    {id: "action", label: "Action", minWidth: 100, align, type: "action"},
];

export const work_units: DataColumn[] = [
    {id: "installation.site.name", label: "Site", minWidth: 200, type: "string", hideModal},
    {id: "installation.name", label: "Installation", minWidth: 200, type: "relation", required},
    {id: "name", label: "Libellé", minWidth: 200, type: "string", required},
    {id: "action", label: "Action", minWidth: 100, align, type: "action"},
];

export const operations: DataColumn[] = [
    {id: "workUnit.installation.site.name", label: "Site", minWidth: 200, type: "string", hideModal},
    {id: "workUnit.installation.name", label: "Installation", minWidth: 200, type: "string", hideModal},
    {id: "workUnit.name", label: "Unité de travail", minWidth: 200, type: "relation", required},
    {id: "name", label: "Libellé", minWidth: 200, type: "string", required},
    {id: "action", label: "Action", minWidth: 100, align, type: "action"},
];


export const dangerFamilyColumns: DataColumn[] = [
    {id: "name", label: "Libellé", minWidth: 200, type: "string", required},
    {id: "action", label: "Action", minWidth: 100, align, type: "action", disabled},
];

export const dangerSubFamilyColumns: DataColumn[] = [
    {id: "family.name", label: "Famille", minWidth: 200, type: "relation", required},
    {id: "name", label: "Libellé", minWidth: 200, type: "string", required},
    {id: "frequencyUnit", label: "Unité de fréquence", align, minWidth: 200, type: 'string'},
    {id: "intensityUnit", label: "Unité d'intensité", align, minWidth: 200, type: 'string'},
    {id: "isPainful", label: "Pénibilité", minWidth: 200, type: "boolean", required},
    {id: "color", label: "Couleur du pictogramme", minWidth: 200, align, type: "color", hideTh, hide},
    {id: "image", label: "Pictogramme", minWidth: 200, align, type: "image"},
    {id: "active", label: "Actif", minWidth: 100, align, type: "active", hideModal},
    {id: "action", label: "Action", minWidth: 100, align, type: "action", disabled},
];


export const COLUMNS: any = {installations, operations, work_units};
