const tabs1 = [
    {
        id: "operation.workUnit.number",
        value: true,
    },
    {
        id: "operation.workUnit.name",
        value: true,
    },
    {
        id: "operation.number",
        value: true,
    },
    {
        id: "operation.name",
        value: true,
    },
    {
        id: "number",
        value: true,
    },
    {
        id: "dangerFamily.name",
        value: true,
    },
    {
        id: "period",
        value: true,
    },
    {
        id: "description",
        value: true,
    },
    {
        id: "workingCondition",
        value: true,
    },
    {
        id: "exposition",
        value: true,
    },
    {
        id: "rb",
        value: true,
    },
    {
        id: "ri",
        value: true,
    },
    {
        id: "rr",
        value: true,
    },
];
const tabs2 = [
    {
        id: "number",
        value: true,
    },
    {
        id: "dangerFamily.name",
        value: true,
    },
    {
        id: "description",
        value: true,
    },
    {
        id: "workingCondition",
        value: true,
    },
    {
        id: "exposition",
        value: true,
    },
    {
        id: "rbInfos",
        value: true,
    },
    {
        id: "rbFrequency",
        value: true,
    },
    {
        id: "rbIntensity",
        value: true,
    },
    {
        id: "rbProbability",
        value: true,
    },
    {
        id: "rbGravity",
        value: true,
    },
    {
        id: "rb",
        value: true,
    },
];
const tabs3 = [
    {
        id: "number",
        value: true,
    },
    {
        id: "description",
        value: true,
    },
    {
        id: "workingCondition",
        value: true,
    },
    {
        id: "exposition",
        value: true,
    },
    {
        id: "riType",
        value: true,
    },
    {
        id: "riOrga",
        value: true,
    },
    {
        id: "riColl",
        value: true,
    },
    {
        id: "riIndiv",
        value: true,
    },
    {
        id: "riInfos",
        value: true,
    },
    {
        id: "riFrequency",
        value: true,
    },
    {
        id: "riIntensity",
        value: true,
    },
    {
        id: "riMaster",
        value: true,
    },
    {
        id: "riProbability",
        value: true,
    },
    {
        id: "riGravity",
        value: true,
    },
    {
        id: "ri",
        value: true,
    },
];
const tabs4 = [
    {
        id: "number",
        value: true,
    },
    {
        id: "description",
        value: true,
    },
    {
        id: "workingCondition",
        value: true,
    },
    {
        id: "exposition",
        value: true,
    },
    {
        id: "rrType",
        value: true,
    },
    {
        id: "rrOrga",
        value: true,
    },
    {
        id: "rrColl",
        value: true,
    },
    {
        id: "rrIndiv",
        value: true,
    },
    {
        id: "rrInfos",
        value: true,
    },
    {
        id: "rrFrequency",
        value: true,
    },
    {
        id: "rrIntensity",
        value: true,
    },
    {
        id: "rrMaster",
        value: true,
    },
    {
        id: "rrProbability",
        value: true,
    },
    {
        id: "rrGravity",
        value: true,
    },
    {
        id: "rr",
        value: true,
    },
];

export const columnDU = [tabs1, tabs2, tabs3, tabs4];

export const columnDULabels: any = {
    rbInfos: "Information complémentaire",
    rrInfos: "Information complémentaire",
    riInfos: "Informations complémentaires",
    'dangerFamily.name': "Famille ou sous-famille de dangers",
    description: "Description de la situation dangereuse",
    riOrga: "Mesures de maîtrise des risques existantes : organisationnelles",
    riColl: "Mesures de maîtrise des risques existantes : collectives",
    riIndiv: "Mesures de maîtrise des risques existantes : individuelles",
    rrOrga: "Mesures de maîtrise des risques existantes : organisationnelles",
    rrColl: "Mesures de maîtrise des risques existantes : collectives",
    rrIndiv: "Mesures de maîtrise des risques existantes : individuelles",
    exposition: "Exposition",
    period: "Période d'exposition",
    rbFrequency: "Fréquence",
    riFrequency: "Fréquence",
    rrFrequency: "Fréquence",
    rbGravity: "G/I",
    riGravity: "Gi/Ii",
    rrGravity: "G/I",
    rbIntensity: "Intensité",
    riIntensity: "Intensité",
    rrIntensity: "Intensité",
    riMaster: "Maitrise",
    rrMaster: "Maitrise",
    'operation.workUnit.number': "Numéro UT",
    'operation.name': "Opération",
    workingCondition: "Conditions de fonctionnement",
    'operation.number': "Numéro opération",
    rbProbability: "P/F",
    riProbability: "Pi/Fi",
    rrProbability: "P/F",
    ri: "Risque initial",
    rb: "Risque brut",
    rr: "Risque résiduel",
    number: "Numéro risque",
    riType: "Type",
    rrType: "Type",
    'operation.workUnit.name': "Unité de travail",
};
