import {Checkbox, FormControlLabel, FormGroup, TablePagination} from "@material-ui/core";
import React from "react";
import {PathType} from "../../helpers/PathType";
import {PlusIcon} from "../../Icons";
import Button from "./Inputs/Button";
import {CompanyEntity} from "../../models/User";
import ExportToCsv from "./ExportToCsv";

interface Props {
    children: JSX.Element;
    onAdd: () => void;
    data: CompanyEntity[];
    path: PathType;
    page: number;
    rowsPerPage: number;
    onChangePage: (x: unknown, y: number) => void;
    onChangeRowsPerPage: (e: any) => void;
    display?: boolean
    setDisplay?: (e: boolean) => void
}

export default function Footer({
                                   children,
                                   onAdd,
                                   data,
                                   path,
                                   page,
                                   rowsPerPage,
                                   onChangePage,
                                   onChangeRowsPerPage,
                                   setDisplay,
                                   display
                               }: Props) {
    return (
        <div
            className="bottom-0 right-0 h-full flex items-center justify-between bg-white border-t border-gray-light sticky w-full">
            <div className="flex">
                <Button trigger={onAdd} styles="btn-outline mx-3 2xl:mx-5" label={[<PlusIcon/>, "Ajouter"]}/>
                <ExportToCsv data={data} path={path}/>
                {path === PathType.RISKS &&
                <FormGroup>
                    <FormControlLabel control={
                        <Checkbox
                            onChange={(e) => setDisplay ? setDisplay(e.target.checked) : e.target.checked}
                            color="primary"
                            checked={display ? display : false}
                        />
                    } label="Afficher les risques archivés"/>
                </FormGroup>}
            </div>
            <div className="flex items-center">
                {children}
                <TablePagination
                    rowsPerPageOptions={[50, 100]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage={'Résultats par page'}
                    className=""
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                />
            </div>
        </div>
    )
}
