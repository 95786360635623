import Checkbox from '../utils/Inputs/CheckBox'
import React, { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { Configuration } from "../../helpers/Constants"
import { LoaderIcon } from "../../Icons"
import { DangerConfiguration } from "../../models/DangerConfiguration"
import { DangerFamily } from "../../models/DangerFamily"
import { DangerSubFamily } from "../../models/DangerSubFamily"
import Api from "../../services/api.service"
import { Endpoints } from "../../services/endpoints"
import MultiSelect from "../utils/MultiSelect"
import Button from "../utils/Inputs/Button"

// TODO: why checkbox are rerendering?
export default function CouplePGPage() {
    const api = new Api()
    const [family, setFamily] = useState<string>("")
    const [subFamily, setSubFamily] = useState<string>("")
    const [config, setConfig] = useState<DangerConfiguration["coupleProbabilityGravity"] | null>(null)
    const [configID, setConfigID] = useState<any>()
    const [formData, setFormData] = useState<DangerConfiguration["coupleProbabilityGravity"]>(Configuration.COUPLE_PG_DEFAULT)


    const families = useQuery<DangerFamily[]>(Endpoints.DANGER_FAMILIES, () => api.getAll(Endpoints.DANGER_FAMILIES))
    const subFamilies = useQuery<DangerSubFamily[]>([Endpoints.DANGER_SUB_FAMILIES, family], () => api.getAll(Endpoints.DANGER_SUB_FAMILIES, [["family.id", family]]), {
        enabled: family !== "",
    });
    const familyConfig = useQuery<DangerConfiguration[]>([Endpoints.DANGER_CONFIGURATION, subFamily], () => api.get(Endpoints.DANGER_CONFIGURATION, [["dangerSubFamily.id", subFamily]]), {
        enabled: subFamily !== "",
        onSuccess: (result: DangerConfiguration[]) => {
            if (result[0]) {
                setConfig(result[0].coupleProbabilityGravity)
                setFormData(result[0].coupleProbabilityGravity)
                setConfigID(result[0].id)
            }
        }
    })

    const { isLoading: updateIsLoading, mutate: updateConfig } = useMutation(([Endpoints.DANGER_CONFIGURATION + "patch", configID, formData]), () => api.patch(Endpoints.DANGER_CONFIGURATION, { coupleProbabilityGravity: formData }, configID), {
        onSuccess: (result: DangerConfiguration) => {
            setConfig(result.coupleProbabilityGravity)
            setFormData(result.coupleProbabilityGravity)
        }
    })

    function handleCheck(type: "PF" | "G", index: number) {
        let temp = { ...formData }
        temp[type][index] = temp[type][index] === 1 ? 0 : 1
        setFormData(temp)
        setConfig(temp)
    }

    function resetConfig(isFull: boolean) {
        let temp: DangerConfiguration["coupleProbabilityGravity"] = isFull ? JSON.parse(JSON.stringify(Configuration.COUPLE_PG_DEFAULT_NULL)) : JSON.parse(JSON.stringify(Configuration.COUPLE_PG_DEFAULT))
        setFormData(temp)
        setConfig(temp)
    }

    useEffect(() => {
        if (family !== "") {
            setSubFamily("")
            setConfig(null)
        }
    }, [family])

    const selectsProps = [
        {
            name: "family",
            collection: families,
            item: family,
            setter: setFamily,
            label: "Choisissez une famille de danger"
        },
        {
            name: "subFamily",
            collection: subFamilies,
            item: subFamily,
            setter: setSubFamily,
            label: "Choisissez une sous-famille de danger"
        }
    ]

    return (
        <div className="m-auto w-11/12  h-4/5 relative pt-3">
            <MultiSelect selects={selectsProps} />
            {config && !familyConfig.isLoading && (<>
                <ResetConfig config={config} resetConfig={resetConfig} />
                <div className="max-h-full">
                    <div className="m-auto border-2 border-gray-light rounded-lg flex flex-row w-1/3 justify-around my-5">
                        <Column config={config} handleCheck={handleCheck} title={"PF"} type={"PF"} />
                        <Column config={config} handleCheck={handleCheck} title={"G"} type={"G"} />
                    </div>
                </div>
                <div className="flex items-center justify-end mt-10">
                    <Button trigger={updateConfig} disabled={updateIsLoading} isLoading={updateIsLoading} styles={"m-3"} label={["Valider"]} />
                </div>
            </>)}
            {familyConfig.isLoading &&
                <div className="flex items-center justify-center h-5/6">
                    <LoaderIcon />
                </div>
            }
        </div>
    )
}

type config = DangerConfiguration["coupleProbabilityGravity"];
type handleCheck = (type: "PF" | "G", z: number) => void;

interface ColumnProps {
    config: config;
    handleCheck: handleCheck;
    title: string;
    type: "PF" | "G";
}
function Column({ config, handleCheck, title, type }: ColumnProps) {
    return (
        <div className="flex flex-col px-16 py-5">
            <h3 className="m-auto font-semibold">{title}</h3>
            {config[type].map((value, index) => (
                <div className="flex flex-row items-center mx-5" key={index}>
                    <span>{index + 1}</span>
                    <Checkbox value={value === 1} callBack={() => handleCheck(type, index)} />
                </div>
            ))}
        </div>
    )
}

function ResetConfig({ config, resetConfig }: { config: config, resetConfig: (x: boolean) => void }) {
    const [isFull, setIsFull] = useState(isConfigFull(config))
    function handleClick() {
        resetConfig(isConfigFull(config)) // If true, arrays are full, we uncheck every checkbox, If false, arrays are not full, we check every checkbox
        setIsFull(!isFull)
    }
    useEffect(() => {
        setIsFull(isConfigFull(config))
    }, [config])
    return (<div className="w-maw h-max absolute top-0 right-0 m-5">
        <Button trigger={handleClick} disabled={false} styles={"btn-outline"} label={[isFull ? "Tout décocher" : "Tout cocher"]} />
    </div>
    )
}

/**
 * Return true if all arrays of configuration are full
 * @param config 
 * @returns 
 */
function isConfigFull(config: DangerConfiguration["coupleProbabilityGravity"]) {
    let full: boolean = true;
    let configs = [config.PF, config.G]
    for (let col of configs) {
        full = checkFn(col)
        if (!full) {
            break
        }
    }
    return full
}
function checkFn(col: number[]) {
    let isFull = true;
    for (let value of col) {
        if (value === 0) {
            isFull = false
            break;
        }
    }
    return isFull
}
