import React from "react";
import {DataTable} from "../layout/DataTable";
import "../../index.css";
import {LoaderIcon} from "../../Icons";
import {PathType} from "../../helpers/PathType";
import {authService} from "../../services/auth.service";
import {COLUMNS} from "../../helpers/DataColumns/Columns";
import {ErrorQuery} from "../utils/ErrorQuery";
import {useLoadData} from "../hooks/useLoadData";
import { buildUserPath } from "../../helpers/Helpers";


export function FacilitiesPage({path}: { path: PathType }) {
    const user = authService.getCurrentUser();
    const {isLoading, data, isError} = useLoadData(path, path, [[buildUserPath(path), user?.id + '']]);

    if (isLoading) {
        return (
            <div className="flex justify-center items-center h-4/5">
                <LoaderIcon/>
            </div>
        );
    }
    if (isError || data === undefined) {
        return (
            <ErrorQuery>Erreur de chargement</ErrorQuery>
        );
    }
    return <div className="h-4/5 overflow-auto">{user &&
    <DataTable data={data} path={path} columns={COLUMNS[path]}/>}</div>;
}
