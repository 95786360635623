import React, {useState} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Routes} from "./Routes";
import {PathType} from "../../helpers/PathType";
import Sidebar from "../layout/Sidebar";
import Toolbar from "../layout/Toolbar";
import Nav from "../utils/Nav";
import BubblesInfosPage from "../pages/BubblesInfosPage";
import ChemicalProductsPage from "../pages/ChemicalProductsPage";
import CompanyDescriptionPage from "../pages/CompanyDescriptionPage";
import CotationPage from "../pages/CotationPage";
import CouplePGPage from "../pages/CouplePGPage";
import DangerPage from "../pages/DangerPage";
import EmployeesPage from "../pages/EmployeesPage";
import ServicesPage from "../pages/ServicesPage";
import {FacilitiesPage} from "../pages/FacilitiesPage";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import {dangerFamilyColumns, dangerSubFamilyColumns} from "../../helpers/DataColumns/Columns";
import {Endpoints} from "../../services/endpoints";
import {ColumnDUPage} from "../pages/ColumnDUPage";
import {SitesPage} from "../pages/SitesPage";
import HelpCSV from "../pages/HelpCSV";
import ImportRisk from "../pages/ImportRisk";
import CSVReferencePage from "../pages/CSVReferencePage";

export function MyRouter() {
    const [path, setPath] = useState<string>("");

    return <Router>
        <Nav setPath={setPath}/>
        <Switch>
            <Route path={Routes.ADMIN}>
                <div className="flex flex-row h-screen w-full">
                    <Sidebar setLocation={(e: any) => setPath(e.target.innerText)} isLoading={false}
                             isError={false}/>
                    <div className="bg-white w-5/6">
                        <Toolbar path={path}/>
                        <Switch>
                            <Route exact path={Routes.COMPANY_DESCRIPTION}>
                                <CompanyDescriptionPage/>
                            </Route>
                            <Route exact path={Routes.SITES}>
                                <SitesPage/>
                            </Route>
                            <Route exact path={Routes.INSTALLATIONS}>
                                <FacilitiesPage path={PathType.INSTALLATIONS}/>
                            </Route>
                            <Route exact path={Routes.WORK_UNIT}>
                                <FacilitiesPage path={PathType.WORK_UNITS}/>
                            </Route>
                            <Route exact path={Routes.OPERATIONS}>
                                <FacilitiesPage path={PathType.OPERATIONS}/>
                            </Route>
                            <Route exact path={Routes.SERVICES}>
                                <ServicesPage/>
                            </Route>
                            <Route exact path={Routes.EMPLOYEES}>
                                <EmployeesPage/>
                            </Route>
                            <Route exact path={Routes.CHEMICAL_PRODUCTS}>
                                <ChemicalProductsPage/>
                            </Route>
                            <Route exact path={Routes.FAMILY_DANGER}>
                                <DangerPage columns={dangerFamilyColumns} endpoint={Endpoints.DANGER_FAMILIES}
                                            path={PathType.DANGER_FAMILIES}/>
                            </Route>
                            <Route exact path={Routes.FAMILY_SUB_DANGER}>
                                <DangerPage columns={dangerSubFamilyColumns} endpoint={Endpoints.DANGER_SUB_FAMILIES}
                                            path={PathType.DANGER_SUB_FAMILIES}/>
                            </Route>
                            <Route exact path={Routes.COTATION}>
                                <CotationPage isControlled={false}/>
                            </Route>
                            <Route exact path={Routes.COTATION_CONTROLED}>
                                <CotationPage isControlled={true}/>
                            </Route>
                            <Route exact path={Routes.COUPLE_BUBBLES_INFOS}>
                                <BubblesInfosPage/>
                            </Route>
                            <Route exact path={Routes.COUPLE_PROBABILITY_GRAVITY}>
                                <CouplePGPage/>
                            </Route>
                            <Route exact path={Routes.COLUMNS_DU}>
                                <ColumnDUPage/>
                            </Route>
                            <Route exact path={Routes.IMPORT_RISK}>
                                <ImportRisk/>
                            </Route>
                            <Route exact path={Routes.HELP_CSV}>
                                <HelpCSV setPath={setPath}/>
                            </Route>
                        </Switch>
                    </div>
                </div>
            </Route>
            <Route exact path={Routes.LOGIN}>
                <LoginPage/>
            </Route>
            <Route exact path={Routes.HOME}>
                <HomePage/>
            </Route>
            <Route exact path={Routes.CSV_REFERENCE}>
                <CSVReferencePage/>
            </Route>
        </Switch>
    </Router>
}
