import React from "react";
import {createStyles, Tab, Tabs, Theme, withStyles} from "@material-ui/core";

interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
}

function TabPanel(props: TabPanelProps) {
    const {children, value, index, ...other} = props;

    return (
        <div className="h-full" role="tabpanel" id={`simple-tabpanel-${index}`}
             aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <>{children}</>}
        </div>
    );
}

interface StyledTabsProps {
    value: number;
    onChange: (event: React.ChangeEvent<{}>, newValue: number) => void;
}

const StyledTabs = withStyles({
    indicator: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "transparent",
        "& > span": {
            maxWidth: 40,
            width: "100%",
            backgroundColor: "#ACACAC",
        },
    },
})((props: StyledTabsProps) => <Tabs {...props} TabIndicatorProps={{children: <span/>}}/>);

interface StyledTabProps {
    label: string;
}

const StyledTab = withStyles((theme: Theme) =>
    createStyles({
        root: {
            textTransform: "none",
            fontWeight: 600,
            fontSize: theme.typography.pxToRem(15),
            marginRight: theme.spacing(1),
            "&:focus": {
                opacity: 1,
            },
        },
    })
)((props: StyledTabProps) => <Tab disableRipple {...props} />);

export {TabPanel, StyledTabs, StyledTab};
