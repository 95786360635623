import React from "react";
import grid from "../../../assets/grid.png";

export default function Banner({children}: { children?: JSX.Element | string }) {
    return (

        <div className="capse-banner">
            <div className="img-container">
                <img src={grid} alt="Bannière"/>
            </div>

            <h3 className="text-lg 2xl:text-2xl font-semibold text-white uppercase">
                évaluation des risques professionnels
            </h3>

        </div>

    )
}

