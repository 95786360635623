import React, {MouseEvent} from 'react'
import {getValueByReduce} from './Helpers';
import {DotsIcon, LoaderIcon, StarIcon} from '../Icons';
import {DataColumn} from "../models/DataColumn";
import {CompanyEntity} from '../models/User';
import {PathType} from './PathType';
import {authService} from "../services/auth.service";
import {Cotation} from "./Constants";
import CheckBox from '../components/utils/Inputs/CheckBox';
import {getChemicalRisk, getNewPorG, isRiskAtex, isRiskChemical} from "./riskHelpers";
import {Prefix} from '../models/Prefix';
import {
    displayAtexInfos,
    displayChemicalInfos,
    getAtexProbability,
    getDate,
    getImage,
    getRiskValueFromRiskBrut,
    getRiskValueWhenIsAtex,
    getRiskValueWhenIsChemical,
    getRiskValueWhenIsPainful,
    getTwoTypeRow
} from "./cellsDisplayerFunctions";


export const displayCell = (column: DataColumn, row: any, isLoading: boolean, selectedItem: number, onFavClick: (x: number) => void, onActiveClick: (x: boolean, y: number) => void, handleAction: (x: MouseEvent<HTMLDivElement>, y: CompanyEntity) => void, path: PathType) => {
    // Create value with sub entity
    const user = authService.getCurrentUser();
    const value = getValueByReduce(column, row);
    const prefix = column.id.substr(0, 2) as Prefix;
    const isPainFul: boolean = row.dangerFamily?.isPainful;
    const isChemical: boolean = isRiskChemical(row.dangerFamily?.name, row.exposition);
    const isAtex: boolean = isRiskAtex(row.dangerFamily?.name);

    switch (column.type) {
        case 'favorite':
            return (
                <>
                    {isLoading && (
                        row.id === selectedItem ? (
                            <div className="w-max h-max mx-auto">
                                <LoaderIcon/>
                            </div>
                        ) : (
                            <div className="w-max h-max mx-auto opacity-30 cursor-default">
                                <StarIcon selected={row.id === selectedItem}/>
                            </div>
                        )
                    )}
                    {!isLoading &&
                        <div onClick={() => onFavClick(row.id)} className="cursor-pointer">
                            <StarIcon selected={row.id === selectedItem}/>
                        </div>
                    }
                </>
            );
        case 'action':
            const isNotArchived = path === PathType.RISKS && !row.endDate;
            return (
                <>{(path !== PathType.RISKS && (!column.disabled || (column.disabled && row.user))) || isNotArchived ?
                    <div className="w-max mx-auto cursor-pointer" onClick={(e) => handleAction(e, row)}>
                        <DotsIcon/>
                    </div>
                    :
                    <div className="w-max mx-auto opacity-50 cursor-not-allowed"
                         title={isNotArchived || path !== PathType.RISKS ? "Action indisponible" : "Risque archivé"}>
                        <DotsIcon/>
                    </div>
                }
                </>
            );
        case 'active':
            if (row.dangerConfigurations && row.dangerConfigurations[0]) {
                return <>
                    {isLoading && <CheckBox value={row.dangerConfigurations[0].active} disabled={true}/>}
                    {!isLoading && <CheckBox value={row.dangerConfigurations[0].active}
                                             callBack={() => onActiveClick(!row.dangerConfigurations[0].active, row.dangerConfigurations[0].id)}/>}
                </>
            }
            return null;
        case 'boolean':
            return value ? column.label : 'Non ' + column.label;
        case 'date':
            return getDate(value);
        case 'image':
            let style = path === PathType.DANGER_SUB_FAMILIES ? {backgroundColor: row.dangerFamily ? row.dangerFamily.color : row.color} : {};
            return value && <div className="flex justify-center">{getImage(style, value)}</div>
        case 'number':
        case 'relation':
        case 'string':
        case 'textarea':
            return value
        case 'select':
            const x = column.data?.find(d => d.id === value);
            return x ? x.name : '';
        case 'multi-select':
            if (value[0]) {
                return value.map((item: string, index: number) => (
                    <p key={item + '-' + index}>{item}</p>
                ))
            }
            return null;
        /***
         * TYPE SPECIFIC TO RISK
         */
        case 'danger':
            return (<div className="grid grid-cols-4 min-w-xxs">
                <div
                    className="h-max w-max">{row.dangerFamily.image && getImage({backgroundColor: row.dangerFamily.color}, row.dangerFamily.image)}</div>
                <p className="pl-2 col-span-3 flex items-center justify-center">{value}</p>
            </div>)
        case 'period':
            return getDate(row.startDate) + '-' + getDate(row.endDate);
        case 'difficulty':
            if (isPainFul && !isChemical) {
                const unit = column.id.includes('Frequency') ? row.dangerFamily.frequencyUnit : row.dangerFamily.intensityUnit;
                return (<div className="flex justify-around">
                    <div>{value} {unit}</div>
                </div>)
            } else {
                return 'N/A';
            }
        case 'matrix':
            const size = user.matrixCotationRisk.value.length;
            let rowProbability = row.rbProbability;
            let rowGravity = row.rbGravity;

            // We need to recalculate P and G with M if we are in RI or RR and not painful Family
            if (rowProbability && rowGravity && prefix !== Prefix.RB) {
                if (!isPainFul && !isAtex && !isChemical) {
                    rowProbability = getNewPorG(rowProbability, row[prefix + 'MasterPrev'], user.matrixCotationRiskControled);
                    rowGravity = getNewPorG(rowGravity, row[prefix + 'MasterProt'], user.matrixCotationRiskControled);
                }
                if (isAtex) {
                    rowProbability = getAtexProbability(row.ignitionSources, prefix);
                    rowGravity = 4;
                }

                if (isChemical) {
                    rowProbability = getChemicalRisk(row, prefix) && getChemicalRisk(row, prefix)['probability'] ? getChemicalRisk(row, prefix)['probability'] : 'N/A';
                    rowGravity = getChemicalRisk(row, prefix) && getChemicalRisk(row, prefix)['gravity'] ? getChemicalRisk(row, prefix)['gravity'] : 'N/A';
                }
            }

            if (rowProbability && rowGravity && rowProbability !== 'N/A' && rowGravity !== 'N/A') {
                let cells = []
                let classes = " hover:bg-transparent cursor-default"
                cells.push(<span className={"chip-green" + classes}>{Cotation.LOW}</span>);
                cells.push(<span className={"chip-yellow" + classes}>{Cotation.MEDIUM}</span>);
                cells.push(<span className={"chip-red" + classes}>{Cotation.HIGH}</span>);
                const result = user.matrixCotationRisk.value[size - rowProbability][rowGravity - 1];
                return cells[result - 1];
            } else {
                return 'N/A';
            }
        case 'riskInfos':
            if (row.chemicalRisk && row.chemicalRisk.length) {
                return displayChemicalInfos(column, row)
            }
            if (row.ignitionSources && row.ignitionSources.length) {
                return displayAtexInfos(column, row)
            }
            return value;
        case 'risk':
            if (prefix !== Prefix.RB) {
                return isPainFul ?
                    (isChemical ? getRiskValueWhenIsChemical(row, column) : getRiskValueWhenIsPainful(row, column)) :
                    (isAtex ? getRiskValueWhenIsAtex(row, column, prefix) : getRiskValueFromRiskBrut(row, user, column));
            }
            return isPainFul && !isChemical ? getRiskValueWhenIsPainful(row, column) : value
        case 'riType':
            return getTwoTypeRow('Prev.', 'Prot.');
        case 'prevProt':
            const isMasterIndex = column.id.slice(2) === 'Master'
            return getTwoTypeRow(row[column.id + 'Prev'], row[column.id + 'Prot'], isPainFul, isMasterIndex, row, prefix);
        case 'wuNumber':
            return 'UT' + value;
        case 'opNumber':
            return 'O' + value;
        case 'riskNumber':
            return 'UT' + row.operation.workUnit.number + '-O' + row.operation.number + '-R' + value;
        default:
            return 'N/A';
    }
};



