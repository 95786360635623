import {FieldSelect} from "../../utils/Inputs/FieldSelect";
import {FieldSelectGroup} from "../../utils/Inputs/FieldSelectGroup";
import React, {useState} from "react";
import {ChemicalRisk} from "../../../models/ChemicalRisk";
import {
    contactClasses,
    emissionClasses,
    emissionClassesGroups,
    expositionSituations,
    expositionsType,
    frequenciesCriteria,
    methodsOfUse,
    quantitiesCriteria,
    quantitiesCriteriaGroups,
    ratingsCriteria
} from "../../../helpers/DataColumns/RiskColumns";
import {QuestionIcon} from "../../../Icons";
import chemical_method from "../../../assets/chemical_method.png";

export interface RiskStepChemicalProps {
    chemicalRisk: ChemicalRisk,
    setChemicalRisk: (value: ChemicalRisk) => void,
}

export default function RiskStepChemical({chemicalRisk, setChemicalRisk}: RiskStepChemicalProps) {
    const [hover, setHover] = useState<boolean>(false);
    return (
        <>
            <FieldSelect
                className="mb-2"
                name={"expositionType"}
                options={expositionsType}
                value={chemicalRisk.expositionType ?? ''}
                onChange={(e) => setChemicalRisk({...chemicalRisk, expositionType: e.target.value})}>
                Type d'exposition
            </FieldSelect>

            {chemicalRisk.expositionType === 'inhalation' &&
            <>
                <FieldSelect
                    className="mb-2"
                    name={"ratingCriteria"}
                    options={ratingsCriteria}
                    value={chemicalRisk.ratingCriteria ?? ''}
                    onChange={(e) => setChemicalRisk({...chemicalRisk, ratingCriteria: e.target.value})}>
                    Critères de cotation
                </FieldSelect>
                <FieldSelectGroup
                    className="mb-2"
                    name={"quantityCriteria"}
                    options={quantitiesCriteria}
                    groups={quantitiesCriteriaGroups}
                    value={chemicalRisk.quantityCriteria ?? ''}
                    onChange={(e) => setChemicalRisk({...chemicalRisk, quantityCriteria: e.target.value})}>
                    Critères de quantité
                </FieldSelectGroup>
                <FieldSelect
                    className="mb-2"
                    name={"frequencyCriteria"}
                    options={frequenciesCriteria}
                    value={chemicalRisk.frequencyCriteria ?? ''}
                    onChange={(e) => setChemicalRisk({...chemicalRisk, frequencyCriteria: e.target.value})}>
                    Critères de fréquence
                </FieldSelect>
                <FieldSelectGroup
                    className="mb-2"
                    name={"emissionClass"}
                    options={emissionClasses}
                    groups={emissionClassesGroups}
                    value={chemicalRisk.emissionClass ?? ''}
                    onChange={(e) => setChemicalRisk({...chemicalRisk, emissionClass: e.target.value})}>
                    Classe d'émission
                    <span
                        onMouseOver={() => setHover(true)}
                        onMouseOut={() => setHover(false)}
                        className="text-blue-light cursor-pointer ml-5 absolute top-0 left-30"
                        title="En savoir plus">
                     <QuestionIcon/>
                        <img className="mw-[400px] relative left-1" hidden={!hover} src={chemical_method}
                             alt="Méthodes"/>
                </span>
                </FieldSelectGroup>


                <FieldSelect
                    className="mb-2"
                    name={"methodOfUse"}
                    options={methodsOfUse}
                    value={chemicalRisk.methodOfUse ?? ''}
                    onChange={(e) => setChemicalRisk({...chemicalRisk, methodOfUse: e.target.value})}>
                    Procédé d'utilisation
                </FieldSelect>
                <FieldSelect
                    className="mb-2"
                    name={"expositionType"}
                    options={expositionSituations}
                    value={chemicalRisk.expositionSituation ?? ''}
                    onChange={(e) => setChemicalRisk({...chemicalRisk, expositionSituation: e.target.value})}>
                    Situation d'exposition
                </FieldSelect>
            </>}

            {chemicalRisk.expositionType === 'cutanee' &&
            <>
                <FieldSelect
                    className="mb-2"
                    name={"contactClass"}
                    options={contactClasses}
                    value={chemicalRisk.contactClass ?? ''}
                    onChange={(e) => setChemicalRisk({...chemicalRisk, contactClass: e.target.value})}>
                    Classe de contact

                </FieldSelect>
                <FieldSelect
                    className="mb-2"
                    name={"frequencyCriteria"}
                    options={frequenciesCriteria}
                    value={chemicalRisk.frequencyCriteria ?? ''}
                    onChange={(e) => setChemicalRisk({...chemicalRisk, frequencyCriteria: e.target.value})}>
                    Critères de fréquence
                </FieldSelect>
                <FieldSelect
                    className="mb-2"
                    name={"ratingCriteria"}
                    options={ratingsCriteria}
                    value={chemicalRisk.ratingCriteria ?? ''}
                    onChange={(e) => setChemicalRisk({...chemicalRisk, ratingCriteria: e.target.value})}>
                    Critères de cotation
                </FieldSelect></>
            }
        </>


    )
        ;
}
