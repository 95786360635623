import { DataColumn } from "../../models/DataColumn";
import { HazardStatement } from "../Constants";
import {align, minWidth, required, type, hideTh, hideModal, hide} from "../DataColumns/ColumnsConstants";
import { groupBy } from "../Helpers";

const pictogram = [
    { id: "SGH01", label: "SGH01" },
    { id: "SGH02", label: "SGH02" },
    { id: "SGH03", label: "SGH03" },
    { id: "SGH05", label: "SGH05" },
    { id: "SGH06", label: "SGH06" },
    { id: "SGH07", label: "SGH07" },
    { id: "SGH08", label: "SGH08" },
    { id: "SGH09", label: "SGH09" },
];

export const chemicalColumnsSpan: DataColumn[] = [
    { id: "indexNb", label: "Numéro Index", minWidth, align, tab: 1, modalTab: 1, rowSpan: 2 },
    { id: "chemicalId", label: "Identifiant Chimique international", minWidth, align, tab: 1, modalTab: 1, rowSpan: 2 },
    { id: "casNb", label: "Numéro CAS", minWidth, align, tab: 1, modalTab: 1, rowSpan: 2 },
    { id: "ceNb", label: "Numéro CE", minWidth, align, tab: 1, modalTab: 1, rowSpan: 2 },
    { id: "classification", label: "Classification", minWidth, align, tab: 1, modalTab: 1, colSpan: 2 },
    { id: "labeling", label: "Étiquetage", minWidth, align, tab: 1, modalTab: 1, colSpan: 3 },
    { id: "concentrationLimits", label: "Limite de concentration", minWidth, align, type: "number", tab: 1, modalTab: 1, rowSpan: 2 },
    { id: "rating", label: "Notes", minWidth, align, tab: 1, modalTab: 1, rowSpan: 2 },
    { id: "action", label: "Action", minWidth, align, type: "action", tab: 1, hideModal, rowSpan: 2 },
];

export const chemicalColumns: DataColumn[] = [
    {
        id: "indexNb",
        label: "Numéro Index",
        modalLabel: "Numéro index international",
        minWidth,
        align,
        type,
        tab: 1,
        modalTab: 1,
        hideTh,
    },
    {
        id: "chemicalId",
        label: "Identifiant Chimique",
        minWidth,
        align,
        type,
        required,
        tab: 1,
        modalTab: 1,
        hideTh,
    },
    {
        id: "casNb",
        label: "Numéro CAS",
        minWidth,
        align,
        type,
        required,
        tab: 1,
        modalTab: 1,
        hideTh,
    },
    {
        id: "ceNb",
        label: "Numéro CE",
        minWidth,
        align,
        type,
        required,
        tab: 1,
        modalTab: 1,
        hideTh,
    },
    {
        id: "hazardStatement",
        label: "Code(s) des classes et des catégories de dangers",
        minWidth,
        align,
        type: "multi-select",
        tab: 1,
        modalTab: 2,
        hideModal,
    },
    {
        id: "hazardStatement",
        label: "Code(s) des mentions de dangers",
        minWidth,
        align,
        type: "multi-select",
        tab: 1,
        modalTab: 2,
        hideModal,
    },
    {
        id: "pictogram",
        label: "Code(s) des pictogrammes, mentions d'avertissement",
        minWidth,
        align,
        type: "multi-select",
        tab: 1,
        modalTab: 2,
        data: pictogram,
    },
    {
        id: "hazardStatement",
        label: "Code(s) des mentions de dangers",
        minWidth,
        align,
        type: "multi-select",
        tab: 1,
        modalTab: 2,
        data: HazardStatement.SGH_CODES,
    },
    {
        id: "hazardStatement",
        label: "Code(s) des mentions additionnels de dangers",
        minWidth,
        align,
        type: "multi-select",
        tab: 1,
        modalTab: 1,
        hideModal,
    },
    {
        id: "concentrationLimits",
        label: "Limite de concentration",
        minWidth,
        align,
        type: "number",
        tab: 1,
        modalTab: 1,
        hideTh,
    },
    {
        id: "rating",
        label: "Notes",
        minWidth,
        align,
        type,
        tab: 1,
        modalTab: 1,
        hideTh,
    },
    { id: "action", label: "Action", minWidth, align, type: "action", tab: 1, hideTh },
    {
        id: "chemicalId",
        label: "Identifiant Chimique international",
        minWidth,
        align,
        type,
        tab: 2,
        hideModal,
    },
    {
        id: "density",
        label: "Masse volumique (kg/m3) à 20°C",
        minWidth,
        align,
        type: "number",
        tab: 2,
        modalTab: 3,
    },
    {
        id: "mass",
        label: "Masse ou poids moléculaire",
        minWidth,
        align,
        type: "number",
        tab: 2,
        modalTab: 3,
    },
    {
        id: "flashPoint",
        label: "Point d'éclair",
        minWidth,
        align,
        type: "number",
        tab: 2,
        modalTab: 3,
    },
    {
        id: "meltingPoint",
        label: "Point de fusion (°C)",
        minWidth,
        align,
        type: "number",
        tab: 2,
        modalTab: 3,
    },
    {
        id: "boilingPoint",
        label: "Point d'ébullition (°C)",
        minWidth,
        align,
        type: "number",
        tab: 2,
        modalTab: 3,
    },
    {
        id: "autoIgnitionTemp",
        label: "Température d'auto-inflammation (°C)",
        minWidth,
        align,
        type: "number",
        tab: 2,
        modalTab: 3,
    },
    {
        id: "criticalTemp",
        label: "Température critique (°C)",
        minWidth,
        align,
        type: "number",
        tab: 2,
        modalTab: 3,
    },
    {
        id: "flammableLimitLower",
        label: "Limite d'inflammabilité - inférieur (%)",
        minWidth,
        align,
        type: "number",
        tab: 2,
        modalTab: 3,
    },
    {
        id: "flammableLimitUpper",
        label: "Limite d'inflammabilité - supérieur (%)",
        minWidth,
        align,
        type: "number",
        tab: 2,
        modalTab: 3,
    },
    { id: "action", label: "Action", minWidth, align, type: "action", tab: 2, hideModal },
    {
        id: "chemicalId",
        label: "Identifiant Chimique international",
        minWidth,
        align,
        type,
        tab: 3,
        hideModal,
    },
    {
        id: "manufacturerName",
        label: "Nom du fabriquant",
        hide,
        hideTh,
        minWidth,
        align,
        type,
        modalTab: 4,
    },
    {
        id: "chemicalName",
        label: "Nom chimique",
        minWidth,
        align,
        type,
        tab: 3,
        modalTab: 4,
    },
    {
        id: "chemicalConcentration",
        label: "Concentration chimique",
        minWidth,
        align,
        type: "number",
        tab: 3,
        modalTab: 4,
    },
    {
        id: "safetyDataSheet",
        label: "Fiche données sécurité",
        minWidth,
        align,
        type,
        tab: 3,
        modalTab: 4,
    },
    { id: "action", label: "Action", minWidth, align, type: "action", tab: 3, hideModal },
];

export const chemicalColumnsTab = groupBy(chemicalColumns, "tab");
export const chemicalColumnsSpanTab = groupBy(chemicalColumnsSpan, "tab");
