/* DEFAULT COLUMNS VALUES */
//Default values
export const minWidth: number = 50;
export const rowSpan = 1;
export const colSpan = 1;
export const tab = 1;
export const modalTab = 1;
export const type = "string";
export const align = "center";
export const hide = true;
export const hideTh = true;
export const hideModal = true;
export const required = true;
export const disabled = true;
