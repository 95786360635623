import {DataColumn} from "../../models/DataColumn";
import {align, hideTh, minWidth, tab, type} from "./ColumnsConstants";
import {groupBy} from "../Helpers";
import {RiskExposition} from "../../models/RiskExposition";

/****************************
 **********  RISQUES ********
 ***************************/
export const expositions = [
    {id: RiskExposition.CHRONIC, name: "Chronique"},
    {id: RiskExposition.ACCIDENTAL, name: "Accidentelle"},
    {id: RiskExposition.ACUTE, name: "Aiguë"},
];
export const workingConditions = [
    {id: "normal", name: "Normal"},
    {id: "degraded", name: "Dégradé"},
    {id: "accidental", name: "Accidentel"},
    {id: "transient", name: "Transitoire"},
];

/****************************
 *******RISQUES CHIMIQUES****
 ***************************/
export const expositionsType = [
    {id: "inhalation", name: "Exposition par inhalation"},
    {id: "cutanee", name: "Exposition par voie cutanée"},
];
export const ratingsCriteria = [
    {id: "1", name: "Aucune"},
    {id: "2", name: "H315, H317, H319, H335, EUH202, EUH066, H281", title: "> 10 et <= 100 mg/m3", quoting: 1},
    {
        id: "3",
        name: "H302, H312, H314, H332, H334, H336, H341, H351, H361, H362, EUH 029, EUH 031, EUH032, H371, H373, EUH071",
        title: "> 1 et <= 10 mg/m3",
        quoting: 2
    },
    {
        id: "4",
        name: "H301, H311, H318, H331, H340, H350,  H360, EUH070, H370, H372",
        title: "> 0.1 et <= 1 mg/m3",
        quoting: 3
    },
    {
        id: "5",
        name: "H300, H304, H310, H330, EUH032",
        title: "<= 0.1 et mg/m3",
        quoting: 4
    },
];
export const quantitiesCriteria = [
    {id: "1", name: "< 1g", group: "solid", quoting: 1},
    {id: "2", name: "> 1g et < 50g", group: "solid", quoting: 2},
    {id: "3", name: ">= 50g et < 500g", group: "solid", quoting: 3},
    {id: "4", name: ">= 500g et < 5kg", group: "solid", quoting: 4},
    {id: "5", name: ">= 5kg", group: "solid", quoting: 5},
    {id: "6", name: "< 10mL", group: "fluid", quoting: 1},
    {id: "7", name: "> 10mL et < 500mL", group: "fluid", quoting: 2},
    {id: "8", name: ">= 500ml et < 2L", group: "fluid", quoting: 3},
    {id: "9", name: ">= 2L et < 10L", group: "fluid", quoting: 4},
    {id: "10", name: ">= 10L", group: "fluid", quoting: 5},
];
export const quantitiesCriteriaGroups = [
    {id: "solid", name: "Solide"},
    {id: "fluid", name: "Fluide"},
];
const title1 = "Fréquence journalière : < 30 min\n Fréquence hebdomadaire : < 2h\n Fréquence mensuelle : < 1 jour";
const title2 = "Fréquence journalière : >= 30 min et < 2h\n Fréquence hebdomadaire : > 2h et < 8h\n Fréquence mensuelle : >=1 jour et < 6 jours";
const title3 = "Fréquence journalière : >= 2h et < 6h\n Fréquence hebdomadaire : > 8h et < 3 jours\n Fréquence mensuelle : >=6 jour et < 15 jours";
const title4 = "Fréquence journalière : >= 6h\n Fréquence hebdomadaire : >= 3 jours\n Fréquence mensuelle : >= 15 jours";

export const frequenciesCriteria = [
    {id: "1", name: "< 150h", title: title1, quoting: 1},
    {id: "2", name: ">= 150h et < 300h", title: title2, quoting: 2},
    {id: "3", name: ">= 300h et < 450h", title: title3, quoting: 3},
    {id: "4", name: ">= 450h", title: title4, quoting: 4},
];

// VOLATILITY
export const emissionClasses = [
    {id: "1", name: "Classe 1", group: "solid", quoting: 1},
    {id: "2", name: "Classe 2", group: "solid", quoting: 2},
    {id: "3", name: "Classe 3", group: "solid", quoting: 3},
    {id: "4", name: "Poudre fine ...", group: "fluid", quoting: 1},
    {id: "5", name: "Poudre constitué de grain ...", group: "fluid", quoting: 2},
    {id: "6", name: "Pastilles ...", group: "fluid", quoting: 3},
];
// PROCEDE
export const methodsOfUse = [
    {id: "open", name: "Ouvert", quoting: 0.5},
    {id: "dispersif", name: "Dispersif", quoting: 1},
    {id: "open_closed", name: "Clos mais régulièrement ouvert", quoting: 0.05},
    {id: "closed", name: "Clos", quoting: 0.001},
]

// PROTECTION COLLECTIVE
export const expositionSituations = [
    {id: "1", name: "Aucune protection collective", quoting: 1},
    {id: "2", name: "Ventilation générale mécanique", quoting: 0.7},
    {id: "3", name: "Captage à la source", quoting: 0.1},
    {id: "4", name: "Captage enveloppant", quoting: 0.001},
    {id: "5", name: "Isolateur ", quoting: 0.0001},
]
export const contactClasses = [
    {id: "1", name: "1 main", quoting: 1},
    {id: "2", name: "2 mains", quoting: 2},
    {id: "3", name: "Membre supérieurs", quoting: 3},
    {id: "4", name: "Corp Entier", quoting: 4},
]
export const emissionClassesGroups = [
    {id: "solid", name: "Solide"},
    {id: "fluid", name: "Fluide"},
];


/****************************
 *******RISQUES ATEX*********
 ***************************/
interface RiskInfosInterface {
    [key: string]: any;
}

export const sourceProbalities = [
    {id: "p1", name: "P1 : Aucune source d'ignition identifiée ou matériel ATEX conforme"},
    {id: "p2", name: "P2 : Source d'ignition susceptible de se présenter dans des circonstances accidentelles"},
    {id: "p3", name: "P3 : Source d'ignition présente ponctuellement en fonctionnement normal"},
    {id: "p4", name: "P4 : Source d'ignition présente constamment ou fréquemment en fonctionnement normal"},
];
export const atexZones = [
    {id: "z0", name: "Zone 0"},
    {id: "z1", name: "Zone 1"},
    {id: "z2", name: "Zone 2"},
    {id: "hz", name: "Hors Zone"},
];
export const riTypes = [
    {id: "prev", name: "Prev"},
    {id: "prot", name: "Prot"},
];
export const riskInfosLabels: RiskInfosInterface = {
    "contactClass": contactClasses,
    "emissionClass": emissionClasses,
    "expositionSituation": expositionSituations,
    "expositionType": expositionsType,
    "frequencyCriteria": frequenciesCriteria,
    "methodOfUse": methodsOfUse,
    "quantityCriteria": quantitiesCriteria,
    "ratingsCriteria": ratingsCriteria,
};
export const atexInfosLabels: RiskInfosInterface = {
    "atexZone": atexZones,
    "probability": sourceProbalities
};

/**
 * nameColumnsSpan is for the first row of the header (optional if only one row in header)
 * nameColumns is for the second (or only) row in the header
 * No logic of generic modal here
 */
export const riskColumnsSpan: DataColumn[] = [
    /****************************
     *IDENTIFICATION DES DANGERS*
     ***************************/
    {id: "ut", label: "Unité de travail", minWidth, align, tab, colSpan: 2},
    {id: "operation", label: "Opération", minWidth, align, tab, colSpan: 2},
    {id: "globalNumber", label: "N°", minWidth: 130, align, tab, rowSpan: 2},
    {id: "subFamily", label: "Famille ou sous-famille de dangers", minWidth: 190, align, tab, rowSpan: 2},
    {id: "period", label: "Période d'exposition", minWidth, align, tab, rowSpan: 2},
    {id: "description", label: "Description de la situation dangereuse", minWidth, align, tab, rowSpan: 2},
    {id: "workingCondition", label: "Condition de fonctionnement", minWidth, align, tab, rowSpan: 2, type: 'select'},
    {id: "exposition", label: "Exposition", minWidth, align, tab, rowSpan: 2, type: 'select'},
    {id: "risk", label: "Risque", minWidth, align, tab, colSpan: 3},
    {id: "action", label: "Action", minWidth, align, type: "action", tab: 1, rowSpan: 2},

    /***************************
     ****** RISQUE BRUT ********
     **************************/
    {id: "number", label: "N°", minWidth, align, tab: 2, rowSpan: 2},
    {id: "dangerFamily.name", label: "Famille ou sous-famille de dangers", minWidth: 100, align, tab: 2, rowSpan: 2},
    {id: "description", label: "Description de la situation dangereuse", minWidth, align, tab: 2, rowSpan: 2},
    {id: "workingCondition", label: "Condition de fonctionnement", minWidth, align, tab: 2, rowSpan: 2, type: 'select'},
    {id: "exposition", label: "Exposition", minWidth, align, tab: 2, rowSpan: 2, type: 'select'},
    {id: "rbInfos", label: "Informations complémentaires", minWidth, align, tab: 2, rowSpan: 2, type: "riskInfos"},
    {id: "rbDifficulty", label: "Pénibilité", minWidth, align, tab: 2, colSpan: 2, type},
    {id: "rbProbability", type: "number", label: "P/F", minWidth, align, tab: 2, rowSpan: 2},
    {id: "rbGravity", type: "number", label: "G/I", minWidth, align, tab: 2, rowSpan: 2},
    {id: "rb", type: "string", label: "RB", minWidth, align, tab: 2, rowSpan: 2},
    {id: "action", label: "Action", minWidth, align, type: "action", tab: 2, rowSpan: 2},

    /***************************
     ***** RISQUE INITIAL ******
     **************************/
    {id: "globalNumber", label: "N°", minWidth, align, tab: 3, rowSpan: 2},
    {id: "description", label: "Description de la situation dangereuse", minWidth, align, tab: 3, rowSpan: 2},
    {id: "workingCondition", label: "Condition de fonctionnement", minWidth, align, tab: 3, rowSpan: 2, type: 'select'},
    {id: "exposition", label: "Exposition", minWidth, align, tab: 3, rowSpan: 2, type: 'select'},
    {
        id: "measureRi", label: "Mesures de maîtrise des risques existants", minWidth, align,
        tab: 3, colSpan: 4,
    },
    {id: "riInfos", label: "Informations complémentaires", minWidth, align, tab: 3, rowSpan: 2, type},
    {id: "riDifficulty", label: "Pénibilité", minWidth, align, tab: 3, colSpan: 2, type},
    {id: "riCotation", label: "Cotation", minWidth, align, tab: 3, colSpan: 4, type},
    {id: "action", label: "Action", minWidth, align, type: "action", tab: 3, rowSpan: 2},

    /***************************
     ***** RISQUE RESIDUEL *****
     **************************/
    {id: "globalNumber", label: "N°", minWidth, align, tab: 4, rowSpan: 2},
    {id: "description", label: "Description de la situation dangereuse", minWidth, align, tab: 4, rowSpan: 2},
    {id: "workingCondition", label: "Condition de fonctionnement", minWidth, align, tab: 4, rowSpan: 2, type: 'select'},
    {id: "exposition", label: "Exposition", minWidth, align, tab: 4, rowSpan: 2, type: 'select'},
    {
        id: "measureRr", label: "Mesures de maîtrise des risques résiduels", minWidth, align,
        tab: 4, colSpan: 4,
    },
    {id: "rrInfos", label: "Informations complémentaires", minWidth, align, tab: 4, rowSpan: 2, type},
    {id: "rrDifficulty", label: "Pénibilité", minWidth, align, tab: 4, colSpan: 2, type},
    {id: "rrCotation", label: "Cotation", minWidth, align, tab: 4, colSpan: 4, type},
    {id: "action", label: "Action", minWidth, align, type: "action", tab: 4, rowSpan: 2},
]
export const riskColumn: DataColumn[] = [
    /****************************
     *IDENTIFICATION DES DANGERS*
     ***************************/
    {id: "operation.workUnit.number", type: 'wuNumber', label: "N°", minWidth, align, tab},
    {id: "operation.workUnit.name", type, label: "Désignation", minWidth, align, tab},
    {id: "operation.number", type: 'opNumber', label: "N°", minWidth, align, tab},
    {id: "operation.name", type, label: "Désignation", minWidth, align, tab},
    {id: "number", type: 'riskNumber', label: "N°", minWidth: 130, align, tab, hideTh},
    {
        id: "dangerFamily.name", type: 'danger', label: "Famille ou sous-famille de dangers",
        minWidth: 190, align, tab, hideTh
    },
    {id: "period", type: 'period', label: "Période d'exposition", minWidth, align, tab, hideTh},
    {id: "description", type, label: "Description de la situation dangereuse", minWidth, align, tab, hideTh},
    {
        id: "workingCondition", type: 'select', data: workingConditions, label: "Condition de fonctionnement",
        minWidth, align, tab, hideTh
    },
    {
        id: "exposition", type: 'select',
        data: expositions, label: "Exposition", minWidth, align, tab, hideTh
    },
    {id: "rb", label: "Risque Brut", minWidth, align, tab, type: "matrix"},
    {id: "ri", label: "Risque initial", minWidth, align, tab, type: "matrix"},
    {id: "rr", label: "Risque résiduel", minWidth, align, tab, type: "matrix"},
    {id: "action", label: "Action", minWidth, align, type: "action", tab: 1, hideTh},

    /***************************
     ****** RISQUE BRUT ********
     **************************/
    {id: "number", type: 'riskNumber', label: "N°", minWidth, align, tab: 2, hideTh},
    {
        id: "dangerFamily.name", type: 'danger', label: "Famille ou sous-famille de dangers",
        minWidth: 100, align, tab: 2, hideTh
    },
    {id: "description", type, label: "Description de la situation dangereuse", minWidth, align, tab: 2, hideTh},
    {
        id: "workingCondition", type: 'select', data: workingConditions, label: "Condition de fonctionnement",
        minWidth, align, tab: 2, hideTh
    },
    {
        id: "exposition", type: 'select',
        data: expositions, label: "Exposition", minWidth, align, tab: 2, hideTh
    },
    {id: "rbInfos", type: "riskInfos", label: "Informations complémentaires", minWidth, align, tab: 2, hideTh},
    {id: "rbFrequency", type: "difficulty", label: "Fréquence", minWidth, align, tab: 2},
    {id: "rbIntensity", type: "difficulty", label: "Intensité", minWidth, align, tab: 2},
    {id: "rbProbability", type: "risk", label: "P/F", minWidth, align, tab: 2, hideTh},
    {id: "rbGravity", type: "risk", label: "G/I", minWidth, align, tab: 2, hideTh},
    {id: "rb", type: "matrix", label: "RB", minWidth, align, tab: 2, hideTh},
    {id: "action", label: "Action", minWidth, align, type: "action", tab: 2, hideTh},

    /***************************
     ***** RISQUE INITIAL ******
     **************************/
    {id: "number", type: 'riskNumber', label: "N°", minWidth, align, tab: 3, hideTh},
    {id: "description", type, label: "Description de la situation dangereuse", minWidth, align, tab: 3, hideTh},
    {
        id: "workingCondition", type: 'select', data: workingConditions, label: "Condition de fonctionnement",
        minWidth, align, tab: 3, hideTh
    },
    {
        id: "exposition", type: 'select',
        data: expositions, label: "Exposition", minWidth, align, tab: 3, hideTh
    },
    {id: "riType", type: 'riType', label: "Types", minWidth, align, tab: 3, data: riTypes},
    {id: "riOrga", type: 'prevProt', label: "Organisationnelles", minWidth, align, tab: 3},
    {id: "riColl", type: 'prevProt', label: "Collectives", minWidth, align, tab: 3},
    {id: "riIndiv", type: 'prevProt', label: "Individuelles", minWidth, align, tab: 3},
    {id: "riInfos", type: "riskInfos", label: "Informations complémentaires", minWidth, align, tab: 3, hideTh},
    {id: "riFrequency", type: "difficulty", label: "Fréquence", minWidth, align, tab: 3},
    {id: "riIntensity", type: "difficulty", label: "Intensité", minWidth, align, tab: 3},
    {id: "riMaster", label: "M", minWidth, align, tab: 3, type: 'prevProt'},
    {id: "riProbability", type: "risk", label: "P/F", minWidth, align, tab: 3},
    {id: "riGravity", type: "risk", label: "G/I", minWidth, align, tab: 3},
    {id: "ri", type: "matrix", label: "RI", minWidth, align, tab: 3},
    {id: "action", label: "Action", minWidth, align, type: "action", tab: 3, hideTh},


    /***************************
     ***** RISQUE RESIDUEL *****
     **************************/
    {id: "number", type: 'riskNumber', label: "N°", minWidth, align, tab: 4, hideTh},
    {id: "description", type, label: "Description de la situation dangereuse", minWidth, align, tab: 4, hideTh},
    {
        id: "workingCondition", type: 'select', data: workingConditions, label: "Condition de fonctionnement",
        minWidth, align, tab: 4, hideTh
    },
    {
        id: "exposition", type: 'select',
        data: expositions, label: "Exposition", minWidth, align, tab: 4, hideTh
    },
    {id: "rrType", type: 'riType', label: "Types", minWidth, align, tab: 4, data: riTypes},
    {id: "rrOrga", type: 'prevProt', label: "Organisationnelles", minWidth, align, tab: 4},
    {id: "rrColl", type: 'prevProt', label: "Collectives", minWidth, align, tab: 4},
    {id: "rrIndiv", type: 'prevProt', label: "Individuelles", minWidth, align, tab: 4},
    {id: "rrInfos", type: "riskInfos", label: "Informations complémentaires", minWidth, align, tab: 4, hideTh},
    {id: "rrFrequency", type: "difficulty", label: "Fréquence", minWidth, align, tab: 4},
    {id: "rrIntensity", type: "difficulty", label: "Intensité", minWidth, align, tab: 4},
    {id: "rrMaster", label: "M", minWidth, align, tab: 4, type: 'prevProt'},
    {id: "rrProbability", type: "risk", label: "P/F", minWidth, align, tab: 4},
    {id: "rrGravity", type: "risk", label: "G/I", minWidth, align, tab: 4},
    {id: "rr", type: "matrix", label: "RR", minWidth, align, tab: 4},
    {id: "action", label: "Action", minWidth, align, type: "action", tab: 4, hideTh},
]

export const riskColumnsTab = groupBy(riskColumn, "tab");
export const riskColumnsSpanTab = groupBy(riskColumnsSpan, "tab");
