export class Cotation {
    public static LOW = "maitrisé";
    public static MEDIUM = "modéré";
    public static HIGH = "critique";
    public static GRAVITY = "Gravité";
    public static CONTROL = "Maîtrise";
    public static PROBA_FREQ = "Probalilité / Fréquence";
    public static COTATION_DEFAULT = {
        matrixSize: 4,
        matrixCotationRisk: {
            value: [
                [2, 3, 3, 3],
                [1, 2, 3, 3],
                [1, 1, 2, 3],
                [1, 1, 1, 2],
            ],
        },
        matrixCotationRiskControled: {
            value: [
                [4, 3, 2, 1],
                [3, 2, 1, 1],
                [2, 1, 1, 1],
                [1, 1, 1, 1],
            ],
        },
    };
}

export class Configuration {
    public static BUBBLES_INFOS = [
        {
            id: "probabilityCriteria",
            name: "Critère de cotation de la probabilité",
            data: [
                "Exposition exceptionnelle (maximum une fois par an)",
                "Exposition occasionnelle (plus d'une fois par an)",
                "Exposition ponctuelle (plus d'une fois par mois)",
                "Exposition récurrente (plus d'une fois par semaine)",
            ],
        },
        {
            id: "severityCriteria",
            name: "Critère de cotation de la gravité",
            data: [
                "Accident bénin ne nécessitant pas d'arrêt de travail / Situation occasionnant une gène ponctuelle",
                "Accident avec arrêt de travail inférieur à 21 jours / Situation occasionnant une gène récurrente",
                "Accident pouvant porter une atteinte réversible avec arrêt de travail supérieur à 21 jours / Maladie professionnelle sans incapacité permanente après consolidation",
                "Accident ou maladie professionnelle pouvant entraîner une atteinte irréversible",
            ],
        },
        {
            id: "preventionCriteria",
            name: "Critère de cotation de la prévention",
            data: [
                "Niveau de maîtrise du risque insuffisant",
                "Niveau de maîtrise du risque moyen",
                "Niveau de maîtrise du risque bon",
                "Niveau de maîtrise du risque très bon",
            ],
        },
        {
            id: "protectionCriteria",
            name: "Critère de cotation de la protection",
            data: [
                "Niveau de maîtrise du risque insuffisant",
                "Niveau de maîtrise du risque moyen",
                "Niveau de maîtrise du risque bon",
                "Niveau de maîtrise du risque très bon",
            ],
        },
    ];
    public static COUPLE_PG_DEFAULT = {
        PF: [1, 1, 1, 1],
        G: [1, 1, 1, 1],
    };
    public static COUPLE_PG_DEFAULT_NULL = {
        PF: [0, 0, 0, 0],
        G: [0, 0, 0, 0],
    };
    public static ACTIVE = true;
}

export class HazardStatement {
    public static SGH_CODES = [
        {
            id: "SGH01",
            options: [
                "200 - Explosibles, explosives instables",
                "201 - Explosif: danger d'explosion en masse",
                "202 - Explosif: danger sérieux de projection",
                "203 - Explosif; danger d'incendie, d'effet de souffle ou de projection",
                "204 - Danger d'incendie ou de projection",
                "240 - Peut exploser sous l'effet de la chaleur",
                "241 - Peut s'enflammer ou exploser sous l'effet de la chaleur",
            ],
        },
        {
            id: "SGH02",
            options: [
                "220 - Gaz extrêmement inflammable",
                "222 - Aérosols extrêmement inflammables",
                "223 - Aérosols inflammables",
                "224 - Liquide et vapeurs extrêmement inflammables",
                "225 - Liquide et vapeurs très inflammables",
                "226 - Liquide et vapeurs inflammables",
                "228 - Matières solides inflammables",
                "242 - Peut s'enflammer sous l'effet de la chaleur",
                "250 - S'enflamme spontanément au contact de l'air",
                "251 - Matière auto-échauffante; peut s'enflammer",
                "252 - Matière auto-échauffante en grandes quantités; peut s'enflammer",
                "260 - Dégage au contact de l'eau des gaz inflammables qui peuvent s'enflammer spontanément",
                "261 - Dégage au contact de l'eau des gaz inflammables",
                "241 - Peut s'enflammer ou exploser sous l'effet de la chaleur",
            ],
        },
        {
            id: "SGH03",
            options: [
                "H270 - Peut provoquer ou aggraver un incendie; comburant",
                "H271 - Peut provoquer un incendie ou une explosion; comburant puissant",
                "H272 Peut aggraver un incendie; comburant",
            ],
        },
        {
            id: "SGH04",
            options: [
                "H280 - Contient un gaz sous pression; peut exploser sous l'effet de la chaleur",
                "H281 - Contient un gaz réfrigéré; peut causer des brûlures ou blessures cryogéniques",
                "H280 - Contient un gaz sous pression; peut exploser sous l'effet de la chaleur",
            ],
        },
        {
            id: "SGH05",
            options: [
                "H290 - Peut être corrosif pour les métaux",
                "H314 - Provoque des brûlures de la peau et des lésions oculaires graves",
                "H318 - Provoque des lésions oculaires graves",
            ],
        },
        {
            id: "SGH06",
            options: [
                "H300 - Mortel en cas d'ingestion",
                "H310 - Mortel par contact cutané",
                "H330 - Mortel par inhalation",
                "H301 - Toxique en cas d'ingestion",
                "H311 - Toxique par contact cutané",
                "H331 - Toxique par inhalation",
            ],
        },
        {
            id: "SGH07",
            options: [
                "H302 - Nocif en cas d'ingestion",
                "H312 - Nocif par contact cutané",
                "H332 - Nocif par inhalation",
                "H315 - Provoque une irritation cutanée",
                "H319 - Provoque une sévère irritation des yeux",
                "H317 - Peut provoquer une allergie cutanée",
                "H335 -  Peut irriter les voies respiratoires",
                "H336 -  Peut provoquer somnolence ou vertiges",
                "H420 -  Nuit à la santé publique et à l’environnement en détruisant l’ozone dans la haute atmosphère",
            ],
        },
        {
            id: "SGH08",
            options: [
                "H334 - Peut provoquer des symptômes allergiques ou d'asthme ou des difficultés respiratoires par inhalation",
                "H340 - Peut induire des anomalies génétiques",
                "H341 - Susceptible d'induire des anomalies génétiques",
                "H350 - Peut provoquer le cancer",
                "H350i - Peut provoquer le cancer en cas d’inhalation",
                "H351 - Susceptible de provoquer le cancer",
                "H360 - Peut nuire à la fertilité ou au fœtus",
                "H360F - Peut nuire à la fertilité",
                "H360D - Peut nuire au fœtus",
                "H360FD - Peut nuire à la fertilité; peut nuire au fœtus",
                "H360Fd - Peut nuire à la fertilité; susceptible de nuire au fœtus",
                "H360Df - Peut nuire à la fœtus; susceptible de nuire à la fertilité",
                "H361 - Susceptible de nuire à la fertilité ou au fœtus",
                "H361f - Susceptible de nuire à la fertilité",
                "H361d - Susceptible de nuire au fœtus",
                "H361fd - Susceptible de nuire à la fertilité. Susceptible de nuire au fœtus",
                "H370 - Risque avéré d'effets graves pour les organes",
                "H371 - Risque présumé d'effets graves pour les organes",
                "H372 - Risque avéré d'effets graves pour les organes à la suite d'expositions répétées ou d'une exposition prolongée",
                "H373 - Risque présumé d'effets graves pour les organes à la suite d'expositions répétées ou d'une exposition prolongée",
                "H304 - Peut être mortel en cas d'ingestion et de pénétration dans les voies respiratoires"
            ],
        },
        {
            id: "SGH09",
            options: [
                "H400 - Très toxique pour les organismes aquatiques",
                "H410 - Très toxique pour les organismes aquatiques, entraîne des effets néfastes à long terme",
                "H411 - Toxique pour les organismes aquatiques, entraîne des effets néfastes à long terme",
            ],
        },
    ];
}

export const PICTOGRAMS = [
    "amiante.png",
    "anoxie.png",
    "biologique.png",
    "chutehauteur.png",
    "chuteplainpied.png",
    "deplacementengins.png",
    "deplacementroutier.png",
    "coactivite.png",
    "posture.png",
    "vibration.png",
    "animaux.png",
    "chiens.png",
    "divers.png",
    "intrusion.png",
    "nanomateriaux.png",
    "noyade.png",
    "phenomenenaturel.png",
    "proximiterisque.png",
    "psychosociaux.png",
    "eclairage.png",
    "electrique.png",
    "agentchim.png",
    "bruit.png",
    "hyperbar.png",
    "temperaturechaud.png",
    "temperaturefroid.png",
    "chocpression.png",
    "chutecharge.png",
    "chuteobjet.png",
    "cognement.png",
    "coupure.png",
    "ecrasement.png",
    "happement.png",
    "pression.png",
    "rayonnementionisant.png",
    "rayonnementnonionisant.png",
    "rayonnementoptique.png",
    "travaildenuit.png",
    "travailequipe.png",
    "travailrepetitif.png",
    "ambiance_thermique.png",
    "atex.png",
    "brulure.png",
    "explosion.png",
    "incendie.png",
    "plandetravail.png",
    "travaileurisole.png",
    "travailsurecran.png",
];
