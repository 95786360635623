import React, {useEffect, useState} from "react";
import {ModalEditProps, post} from "./Modal";
import {ActionsModal, HeaderModal} from "../utils/ActionsModal";
import {DataColumn} from "../../models/DataColumn";
import {cleanColumns, formRequirementValidation} from "../../helpers/Helpers";
import {serviceColumns} from "../pages/ServicesPage";
import {RenderInputs} from "./ModalTabs";
import RemoveModal from "./RemoveModal";


export default function ServicesModal({item, method, path, toggleModal}: ModalEditProps) {
    switch (method) {
        case "edit":
        case "add":
            return <EditModal item={item} path={path} toggleModal={toggleModal} method={method}/>;
        case "delete":
            return <RemoveModal label={item?.name} item={item} path={path} toggleModal={toggleModal}>
                Êtes-vous sur de vouloir supprimer ce service ?
            </RemoveModal>;
        default:
            return null;
    }
}

function EditModal({item, path, toggleModal, method}: ModalEditProps) {
    const columns: DataColumn[] = cleanColumns([...serviceColumns]);
    const [formData, setFormData] = useState<any>(method === "edit" ? item : {});
    const [isValidForm, setIsValidForm] = useState<boolean>(false);
    //PERFORM MUTATION
    const {isLoading, mutate} = post(path, formData, item?.id, toggleModal, method);
    useEffect(() => {
        setIsValidForm(formRequirementValidation(columns, formData))
    }, [columns, formData]);
    return (
        <div>

            <HeaderModal label={item?.name} method={method}>Ajout d'un Service/departement</HeaderModal>
            <RenderInputs elements={columns} formData={formData} setFormData={setFormData}/>
            <ActionsModal disabled={!isValidForm} toggleModal={toggleModal} isLoading={isLoading} method={method}
                          mutate={mutate}/>
        </div>
    );
}
