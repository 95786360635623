import Api from "../../../services/api.service";
import {useQuery} from "react-query";
import {DangerFamily} from "../../../models/DangerFamily";
import {Endpoints} from "../../../services/endpoints";
import {useNonInitialEffect} from "../../hooks/useNonInitialEffect";
import React from "react";
import MultiSelect from "../../utils/MultiSelect";
import {DangerSubFamily} from "../../../models/DangerSubFamily";

export interface RiskStepTwoProps {
    family: number,
    setFamily: (value: number) => void,
    subFamily: DangerSubFamily,
    setSubFamily: (value: DangerSubFamily | {}) => void,
}

export default function RiskStepTwo({family, setFamily, subFamily, setSubFamily}: RiskStepTwoProps) {
    const api = new Api();
    const families = useQuery<DangerFamily[]>(Endpoints.DANGER_FAMILIES + "_modal", () => api.getAll(Endpoints.DANGER_FAMILIES));

    const subFamilies = useQuery<DangerSubFamily[]>([Endpoints.DANGER_SUB_FAMILIES + "_modal", family], () => api.getAll(Endpoints.DANGER_SUB_FAMILIES, [["family.id", family + ""], ["dangerConfigurations.active", "true"]]), {
        enabled: family !== 0,
        onSuccess: (result: DangerSubFamily[]) => subFamily && result && result[0] && setSubFamily(result[0])
    })

    useNonInitialEffect(() => {
        setSubFamily({});
    }, [family])

    const setterSubFamily = (value: number): void =>
        setSubFamily(subFamilies.data?.find((s: DangerSubFamily) => Number(s.id) === Number(value)) ?? {})

    const selectsProps = [
        {
            name: "family",
            collection: families,
            item: family,
            setter: setFamily,
            label: "Choisissez une famille de danger"
        },
        {
            name: "subFamily",
            collection: subFamilies,
            item: subFamily.id,
            setter: setterSubFamily,
            enabler: family === 0,
            placeHolder: false,
            label: "Choisissez une sous-famille de danger"
        }
    ]
    // TODO: block validation while subfamily is not selected
    return (
        <>
            <MultiSelect selects={selectsProps} cols={"grid-cols-2"}/>
        </>

    )
}
