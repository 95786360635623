import React from 'react'
import { columnDULabels } from '../../helpers/DataColumns/ColumnDU'
import CheckBox from './Inputs/CheckBox'

interface CheckBoxColumnInterface {
    tab: { id: string, value: boolean }[];
    formData: any;
    currentTab: number;
    callBack: (x: any) => void
}

export default function CheckBoxColumn({ tab, formData, currentTab, callBack }: CheckBoxColumnInterface) {
    return (<>
        {tab.map((item: { id: string, value: boolean }, itemIndex: number) => (
            <li className="h-8 flex justify-between items-center hover:bg-blue-light-25 hover:text-blue-text" key={item.id}>
                <span className="w-4/5 px-5">{columnDULabels[item.id]}</span>
                <span className="w-1/5 flex justify-center">
                    <CheckBox value={formData[currentTab] ? formData[currentTab][itemIndex].value : true} callBack={() => callBack(item.id)} />
                </span>
            </li>)
        )}
    </>)
}
