import React, {useState} from "react";
import {Link} from "react-router-dom";
import {
    ArrowDownIcon,
    ArrowUpIcon,
    ChemicalIcon,
    ColumnsIcon,
    DescriptionIcon,
    FamilyIcon,
    FolderIcon,
    GearIcon,
    InstallationIcon,
    OperationIcon,
    SiteIcon,
    SubFamilyIcon,
    WorkIcon
} from "../../Icons";
import {Collapse, List, ListItem, ListItemText} from "@material-ui/core";
import "../../index.css";
import UserCard from "../utils/UserCard";
import {authService} from "../../services/auth.service";
import {Routes} from "../routing/Routes";
import logo from "../../assets/logo-capse.png";

const facilities = [
    {path: Routes.COMPANY_DESCRIPTION, title: "Description Entreprise", icon: <DescriptionIcon/>},
    {path: Routes.SITES, title: "Sites", icon: <SiteIcon/>},
    {path: Routes.INSTALLATIONS, title: "Installations", icon: <InstallationIcon/>},
    {path: Routes.WORK_UNIT, title: "Unité de travail", icon: <WorkIcon/>},
    {path: Routes.OPERATIONS, title: "Opérations", icon: <OperationIcon/>},
];

const evrp = [
    {path: Routes.CHEMICAL_PRODUCTS, title: "Produits Chimiques", icon: <ChemicalIcon/>},
    {path: Routes.COLUMNS_DU, title: "Colonnes DU", icon: <ColumnsIcon/>},
    {path: Routes.IMPORT_RISK, title: "Import risques"},
];

const dangers = [
    {path: Routes.FAMILY_DANGER, title: "Famille de dangers", icon: <FamilyIcon/>},
    {path: Routes.FAMILY_SUB_DANGER, title: "Sous-famille de dangers", icon: <SubFamilyIcon/>},
];

const cotation = [
    {path: Routes.COTATION, title: "Cotation des risques"},
    {path: Routes.COTATION_CONTROLED, title: "Cotation des risques après maîtrise"},
    {path: Routes.COUPLE_BUBBLES_INFOS, title: "Infos Bulles"},
    {path: Routes.COUPLE_PROBABILITY_GRAVITY, title: "Couple probabilité/gravité"}
];

export default function Sidebar({
                                    isLoading,
                                    isError,
                                    setLocation
                                }: { isLoading: boolean; isError: boolean; setLocation: (x: any) => void }) {
    const user = authService.getCurrentUser();
    const [isOpenGlobal, setIsOpenGlobal] = useState(false);
    const [isOpenEVRP, setIsOpenEVRP] = useState(false);
    const [isOpenCotation, setIsOpenCotation] = useState(false);
    const [isOpenDanger, setIsOpenDanger] = useState(false);

    function handleGlobalMenu() {
        setIsOpenGlobal(!isOpenGlobal)
        if (!isOpenGlobal) setIsOpenEVRP(false)
    }

    function handleEVRPMenu() {
        setIsOpenEVRP(!isOpenEVRP)
        if (!isOpenEVRP) setIsOpenGlobal(false)

    }

    function handleCotationMenu() {
        setIsOpenCotation(!isOpenCotation);
        setIsOpenDanger(false);
    }

    function handleDangerMenu() {
        setIsOpenCotation(false);
        setIsOpenDanger(!isOpenDanger);
    }

    return (
        <div className="w-1/6 border-r border-gray-light text-xl overflow-scroll 2xl:overflow-auto">
            <div className="h-1/5 flex items-center justify-center">
                <img className="h-auto w-3/5" src={logo} alt="logo"/>
            </div>
            <UserCard user={user} isLoading={isLoading} isError={isError}/>
            <div className="px-2 xl:px-5 mt-5">
                <ListComponent items={facilities} title={"Paramétrage global"} isOpen={isOpenGlobal}
                               handleMenu={handleGlobalMenu} setLocation={setLocation} icon={<GearIcon/>}/>
                <ListComponent items={evrp} title={"Paramétrage EVRP"} isOpen={isOpenEVRP} handleMenu={handleEVRPMenu}
                               setLocation={setLocation} icon={<GearIcon/>} childComponents={[
                    <ListComponent items={dangers} title={"Dangers"} isOpen={isOpenDanger} handleMenu={handleDangerMenu}
                                   setLocation={setLocation} icon={<FolderIcon/>}/>,
                    <ListComponent items={cotation} title={"Cotation"} isOpen={isOpenCotation}
                                   handleMenu={handleCotationMenu} setLocation={setLocation} icon={<FolderIcon/>}/>
                ]}/>
            </div>
        </div>
    );
}

interface ListProps {
    items: any[];
    title: string;
    isOpen: boolean;
    childComponents?: JSX.Element[];
    handleMenu: () => void;
    setLocation: (x: any) => void;
    icon: JSX.Element;
}

const ListComponent = React.memo(function ListComponent({
                                                            items,
                                                            title,
                                                            isOpen,
                                                            childComponents,
                                                            handleMenu,
                                                            setLocation,
                                                            icon
                                                        }: ListProps) {
    return (
        <List className="w-9/10 text-black">
            <ListItem button className="hover:text-white hover:bg-blue-light rounded w-9/10 p-2 xl:p-2"
                      onClick={handleMenu}>
                <div className="hidden xl:flex inline-flex flex-shrink-0 min-w-9 2xl:min-w-14">
                    {icon}
                </div>
                <ListItemText style={{fontWeight: 600}} primary={title}/>
                <span className="pl-5 hidden md:flex">{isOpen ? <ArrowUpIcon/> : <ArrowDownIcon/>}</span>
            </ListItem>
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {items.map((item: any) => (
                        <Link key={item.title} to={item.path} onClick={setLocation}>
                            <ListItem
                                className="w-9/10 text-gray-dark hover:bg-blue-light-75 hover:text-white rounded max-w-full font-semibold p-0 xl:p-2">
                                <div className="inline-flex flex-shrink-0 min-w-14">
                                    {item.icon ?? <FolderIcon/>}
                                </div>
                                <ListItemText primary={item.title}/>
                            </ListItem>
                        </Link>
                    ))}
                    <div className="2xl:pl-2">
                        {childComponents?.map((component: JSX.Element, index: number) => <div
                            key={index}>{component}</div>)}
                    </div>
                </List>
            </Collapse>
        </List>
    )
})
