import React, { useEffect, useState } from "react";
import { filterData } from "../../../helpers/Helpers";
import { SearchIcon, XIcon } from "../../../Icons";
import { DataColumn } from "../../../models/DataColumn";

interface TextSearchToolbarProps {
    initialRows: any;
    setRows: any;
    columns: DataColumn[];
}

export function TextSearchToolbar({ initialRows, setRows, columns }: TextSearchToolbarProps) {
    const [filterText, setFilterText] = useState<string>("")

    useEffect(() => {
        setRows(filterData(initialRows, filterText, columns));
    }, [initialRows, columns, filterText, setRows])

    return (
        <div className="flex items-center justify-end bg-white font-semibold">
            <label htmlFor="search" className="cursor-pointer mr-2 hover:text-blue-dark"><SearchIcon /></label>
            <input
                id="search"
                autoComplete="off"
                type="text"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
                className="border-b border-gray-light focus:outline-none focus:border-blue-light"
                placeholder="Rechercher…"
            />
            {filterText.length > 0 && <div className="cursor-pointer rounded-full p-1 hover:bg-blue-light-25" onClick={() => setFilterText("")}><XIcon /></div>}
            {filterText.length === 0 && <div className="opacity-0 p-1"><XIcon /></div>}
        </div>
    );
}