import { useQuery } from "react-query";
import { DangerFamily } from "../../models/DangerFamily";
import { DangerSubFamily } from "../../models/DangerSubFamily";
import Api from "../../services/api.service";
import { Endpoints } from "../../services/endpoints";

export const useDangers = () => {
    const api = new Api();
    const { data: families, isLoading: isLoadingFamilies } = useQuery<DangerFamily[]>(Endpoints.DANGER_FAMILIES, () => api.getAll(Endpoints.DANGER_FAMILIES));
    const { data: dataSubFamilies, isLoading: isLoadingSubFamilies } = useQuery<DangerSubFamily[]>(Endpoints.DANGER_SUB_FAMILIES, () => api.getAll(Endpoints.DANGER_SUB_FAMILIES, [["dangerConfigurations.active", "true"]]));
    const subFamilies = dataSubFamilies?.map((family: DangerSubFamily) => { return {...family, group: family.family.id}})
    return {families, subFamilies, isLoadingFamilies, isLoadingSubFamilies};
};
