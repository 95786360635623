import React, {useEffect, useState} from "react";
import {useMutation} from "react-query";
import {Cotation} from "../../helpers/Constants";
import {NameType} from "../../helpers/PathType";
import {User} from "../../models/User";
import Api from "../../services/api.service";
import {authService} from "../../services/auth.service";
import {Endpoints} from "../../services/endpoints";
import {ErrorQuery} from "./ErrorQuery";

interface MatrixProps {
    data: any;
    isControlled: boolean;
    setUser: (x: User) => void
}

export default function Matrix({data, isControlled, setUser}: MatrixProps) {
    const api = new Api();
    const user = authService.getCurrentUser();
    const [formData, setFormData] = useState(data);
    const {
        isLoading,
        mutate
    } = useMutation((postData) => api.patch(Endpoints.USERS, {[isControlled ? NameType.COTATION_CONTROLED : NameType.COTATION]: postData}, user.id), {
        onSuccess: (result: any) => {
            setFormData(result[isControlled ? NameType.COTATION_CONTROLED : NameType.COTATION])
            setUser(result)
        }
    });

    useEffect(() => {
        setFormData(data)
    }, [data])

    function changeCellValue(e: any, row: number, col: number) {
        let temp = {...formData};
        temp.value[row][col] = isControlled ? parseInt(e.target.value) : 1 + temp.value[row][col] % 3;
        mutate({...temp});

    }

    if (!data.value || !data) {
        return (
            <div className="w-full h-full flex items center justify-center">
                <ErrorQuery>Erreur de chargement...</ErrorQuery>
            </div>
        )
    }
    if (!isControlled) {
        return <Table size={user.matrixSize} data={formData} isControlled={isControlled}
                      changeCellValue={changeCellValue} isLoading={isLoading}/>
    }
    return <Table size={user.matrixSize} data={formData} isControlled={isControlled} changeCellValue={changeCellValue}
                  isLoading={isLoading}/>
}

function Table({
                   size,
                   data,
                   changeCellValue,
                   isLoading,
                   isControlled
               }: { size: number; data: any; changeCellValue: (e: any, row: number, col: number) => void; isLoading: boolean; isControlled: boolean }) {
    return (
        <table className="border-collapse border-2 border-gray-light m-2">
            <thead>
            <tr>
                <th colSpan={2} rowSpan={2} className="border-r"/>
                <th colSpan={size}
                    className="text-center uppercase p-2">{isControlled ? Cotation.CONTROL : Cotation.GRAVITY}</th>
            </tr>
            <tr>
                {data.value[0].map((value: any, index: number) => {
                    if (index <= (size - 1)) {
                        return <th key={index} className="border">{index + 1}</th>
                    } else {
                        return null
                    }
                })}
            </tr>
            </thead>
            <tbody>
            <th rowSpan={size + 1} className="w-10 border-t text-center uppercase p-2">{Cotation.PROBA_FREQ}</th>
            {data.value.map((row: any, indexRow: number) => (
                <tr key={indexRow}>
                    <th className="border w-10">{size - indexRow}</th>
                    {row.map((column: any, indexCol: number) => (
                        <Cell row={indexRow} col={indexCol} size={size} changeCellValue={changeCellValue}
                              isControlled={isControlled} key={indexCol + '' + indexRow} value={column}
                              isLoading={isLoading}/>
                    ))}
                </tr>
            ))}
            </tbody>
        </table>
    )
}


function Cell({
                  value,
                  changeCellValue,
                  row,
                  col,
                  isLoading,
                  isControlled,
                  size
              }: { value: string | number; changeCellValue: (e: any, row: number, col: number) => void; row: number; col: number; isLoading: boolean; isControlled: boolean, size: number }) {
    if (!isControlled) {
        let classDisabled = "chip cursor-default opacity-50 "
        switch (value) {
            case 1:
                return <td className="text-center h-16 w-40 bg-green-50">
                    {!isLoading ? <span onClick={(e) => changeCellValue(e, row, col)}
                                        className="chip-green">{Cotation.LOW}</span> :
                        <span className={classDisabled + "chip-green"}>{Cotation.LOW}</span>}
                </td>
            case 2:
                return <td className="text-center h-16 w-40 bg-yellow-50">
                    {!isLoading ? <span onClick={(e) => changeCellValue(e, row, col)}
                                        className="chip-yellow">{Cotation.MEDIUM}</span> :
                        <span className={classDisabled + "chip-yellow"}>{Cotation.MEDIUM}</span>}
                </td>
            case 3:
                return <td className="text-center h-16 w-40 bg-red-50">
                    {!isLoading ? <span onClick={(e) => changeCellValue(e, row, col)}
                                        className="chip-red">{Cotation.HIGH}</span> :
                        <span className={classDisabled + "chip-red"}>{Cotation.HIGH}</span>}
                </td>
            default:
                return <td className="text-center h-16 w-32">N/A</td>
        }
    }

    function generateCells() {
        let result = [];
        for (let i = 0; i < size; i++) {
            result.push(<option value={i + 1}>{i + 1}</option>);
        }
        return result;
    }

    if (typeof value === 'number') {
        let classSelect = "input text-black w-9 p-0 "
        return (
            <td className="text-center h-16 w-40">
                {!isLoading ? (
                        <select className={classSelect + "cursor-pointer"} onChange={(e) => changeCellValue(e, row, col)}
                                value={value}>
                            {generateCells()}
                        </select>)
                    :
                    <select className={classSelect + "cursor-default opacity-50"} value={value}>
                        {generateCells()}
                    </select>
                }
            </td>
        )
    }

    return <td className="text-center h-16 w-32">N/A</td>
}


