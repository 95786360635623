import React, {useEffect, useState} from "react";
import "../../index.css";
import {CompanyEntity} from "../../models/User";
import {ClickAwayListener, Table, TableBody, TableContainer, TableHead} from "@material-ui/core";
import {getValueByReduce, orderPath, resetFavId} from "../../helpers/Helpers";
import ModalComponent, {methodType} from "../modals/Modal";
import Popup from "../utils/Popup";
import {PathType} from "../../helpers/PathType";
import {authService} from "../../services/auth.service";
import {DataColumn} from "../../models/DataColumn";
import {Service} from "../../models/Service";
import {ChemicalProduct} from "../../models/ChemicalProduct";
import Footer from "../utils/Footer";
import TableTh from "../utils/TableTh";
import TableTd from "../utils/TableTd";
import {usePagination} from "../hooks/usePagination";
import {TextSearchToolbar} from "../utils/Inputs/TextSearchToolbar";
import {Color} from "@material-ui/lab/Alert";
import {withSnackbar} from "../utils/blocks/SnackBar";

interface DataTableProps {
    data: CompanyEntity[];
    path: PathType;
    columns: DataColumn[];
    columnsSpan?: DataColumn[];
    snackbar?: any;
    setDisplay?: (e: boolean) => void;
    display?: boolean;
    selectedRow?: number;
    changeColor?: (index: number) => void;
}

function DataTableWrapped({
                              data,
                              path,
                              columns,
                              columnsSpan,
                              snackbar,
                              setDisplay,
                              display,
                              selectedRow,
                              changeColor
                          }: DataTableProps) {
    const user = authService.getCurrentUser();
    const [selectedItem, setSelectedItem] = useState<number>(resetFavId(path, user));
    const [currentItem, setCurrentItem] = useState<CompanyEntity | Service | ChemicalProduct>(data ? data[0] : {});

    const {page, setPage, handleChangePage, rowsPerPage, handleChangeRowsPerPage} = usePagination();
    const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = React.useState<string>(orderPath(path) ? columns[0].id : 'name');

    const [method, setMethod] = useState<methodType>("edit");
    const [popperAnchor, setPopperAnchor] = useState<HTMLDivElement | null>(null);
    const [popperVisibility, setPopperVisibility] = useState<boolean>(false);
    const [modalVisibility, setModalVisibility] = useState<boolean>(false);

    const [rows, setRows] = useState<CompanyEntity[] | any>(data)

    const handleAction = (event: React.MouseEvent<HTMLDivElement>, item: CompanyEntity) => {
        if (event.currentTarget !== popperAnchor) {
            setPopperAnchor(event.currentTarget);
            setPopperVisibility(true);
        } else {
            setPopperVisibility(!popperVisibility);
        }
        setCurrentItem(item);
    };

    function toggleModal(event?: object, reason?: string, message?: string, severity: Color = "success") {
        setModalVisibility(!modalVisibility);
        setPopperVisibility(false);
        if (message) {
            snackbar(message, severity);
        }
    }

    function changeMethod(methodType: methodType) {
        setMethod(methodType);
    }

    function setPopup(bool: boolean) {
        setPopperVisibility(bool);
    }

    function onAdd() {
        setMethod("add");
        setModalVisibility(true);
    }

    function handleClickAway() {
        if (popperVisibility) {
            setPopup(false);
        }
    }

    const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    useEffect(() => {
        setPage(0);
        setSelectedItem(resetFavId(path, user));
        setPopperVisibility(false);
        setPopperAnchor(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [path]);

    useEffect(() => {
        setRows(data)
    }, [data])

    function sortRows(rows: any) {
        const c = columns.find(c => c.id === orderBy)!;
        return (rows.sort((a: object, b: object) => {
            const isAsc = order === 'asc';

            const aValue = getValueByReduce(c, a);
            const bValue = getValueByReduce(c, b);
            if (aValue > bValue) {
                return isAsc ? 1 : -1;
            }
            if (aValue < bValue) {
                return isAsc ? -1 : 1;
            }
            return 0;
        }));
    }

    return (
        <div className="w-full h-full">
            <TableContainer className="h-9/10">
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableTh columns={columns} columnsSpan={columnsSpan} order={order} orderBy={orderBy}
                                 createSortHandler={createSortHandler}/>
                    </TableHead>
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <TableBody>
                            <ModalComponent
                                modalVisibility={modalVisibility}
                                path={path}
                                item={currentItem}
                                method={method}
                                toggleModal={toggleModal}
                                number={data.length}
                            />
                            <Popup
                                popperVisibility={popperVisibility}
                                popperAnchor={popperAnchor}
                                toggleModal={toggleModal}
                                changeMethod={changeMethod}
                                path={path}
                                item={currentItem}
                            />
                            <TableTd
                                rows={orderPath(path) ? sortRows(rows) : rows}
                                columns={columns}
                                page={page}
                                path={path}
                                rowsPerPage={rowsPerPage}
                                selectedItem={selectedItem}
                                handleAction={handleAction}
                                setSelectedItem={setSelectedItem}
                                order={order}
                                orderBy={orderBy}
                                selectedRow={selectedRow}
                                changeColor={changeColor}
                            />
                        </TableBody>
                    </ClickAwayListener>
                </Table>
            </TableContainer>
            <div className="h-1/10">
                <Footer
                    onAdd={onAdd}
                    data={rows}
                    path={path}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    setDisplay={setDisplay}
                    display={display}
                >
                    <TextSearchToolbar initialRows={data} setRows={setRows} columns={columns}/>
                </Footer>
            </div>
        </div>
    );
}

export const DataTable = withSnackbar(DataTableWrapped);

