import React, {useState} from 'react'
import {StyledTab, StyledTabs, TabPanel} from "../utils/TabsComponents";
import {columnDU} from "../../helpers/DataColumns/ColumnDU";
import Button from '../utils/Inputs/Button';
import CheckBoxColumn from '../utils/CheckBoxColumn';
import {useMutation} from 'react-query';
import Api from '../../services/api.service';
import {Endpoints} from '../../services/endpoints';
import {authService} from '../../services/auth.service';
import {User} from '../../models/User';
import {withSnackbar} from "../utils/blocks/SnackBar";

const tabs = [
    {name: "Identification des dangers", index: 1},
    {name: "Risque brut", index: 2},
    {name: "Risque initial", index: 3},
    {name: "Risque résiduel", index: 4},
];

function ColumnDUPageWrapped({snackbar}: { snackbar: any }) {
    const api = new Api();
    const user = authService.getCurrentUser();
    const [currentTab, setCurrentTab] = React.useState<number>(0);
    const [formData, setformData] = useState<User["columnsConfiguration"]>(user.columnsConfiguration)

    const handleChange = (event: React.ChangeEvent<{}>, tab: number) => {
        setCurrentTab(tab);
    }

    function handleCheck(id: string) {
        let temp = formData.length ? [...formData] : [...columnDU];
        temp[currentTab] = temp[currentTab].map(item => item.id === id ? {
            ...item,
            value: !item?.value
        } : item)
        setformData(temp)
    }

    function handleCheckAll() {
        let temp = formData.length ? [...formData] : [...columnDU];
        temp[currentTab] = temp[currentTab].map(item => {
            return {
                ...item,
                value: !item?.value
            }
        });
        setformData(temp)
    }

    const {
        isLoading,
        mutate
    } = useMutation<User>(() => api.patch(Endpoints.USERS, {columnsConfiguration: formData}, user.id), {
        onSuccess: (result: User) => {
            localStorage.setItem('user', JSON.stringify({...result, token: user.token}));
            snackbar('Changements enregistrés')
        }
    })

    return (
        <div className="h-4/5">
            <div className="absolute transform -translate-y-12">
                <StyledTabs value={currentTab} onChange={handleChange} aria-label="styled tabs">
                    {tabs.map((tab: any, index: number) => (
                        <StyledTab key={index} label={tab.name}/>
                    ))}
                </StyledTabs>
            </div>
            <div className="overflow-auto">
                <div className="flex items-center justify-end my-3 mx-5 2xl:m-5">
                    <div className="w-1/5 flex justify-center">
                        <Button trigger={handleCheckAll} label={["Tout cocher / décocher"]}/>
                    </div>
                </div>
                <div className="border-2 border-gray-light rounded-lg m-5">
                    {columnDU.map((tab: any, index: number) => (
                        <TabPanel index={index} value={currentTab} key={index}>
                            <div>
                                <li className="flex justify-between border-gray-light border-b-2">
                                    <h4 className="font-semibold w-4/5 py-2 pl-5 2xl:p-5">Libellé de la colonne</h4>
                                    <h4 className="font-semibold w-1/5 p-2 2xl:p-5 text-center border-gray-light border-l-2">Colonne
                                        sélectionnée</h4>
                                </li>
                                <div className="orverflow-auto">
                                    <CheckBoxColumn tab={tab} callBack={handleCheck} currentTab={currentTab}
                                                    formData={formData}/>
                                </div>
                            </div>
                        </TabPanel>)
                    )}
                </div>
                <div className="flex items-center justify-end my-3 mx-5 2xl:m-5">
                    <div className="w-1/5 flex justify-center">
                        <Button isLoading={isLoading} trigger={mutate} label={["Valider"]}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ColumnDUPage = withSnackbar(ColumnDUPageWrapped);
