import { useState } from "react";
import { useQuery } from "react-query";
import { Installation } from "../../models/Installation";
import { Operation } from "../../models/Operation";
import { Site } from "../../models/Site";
import { WorkUnit } from "../../models/Workunit";
import Api from "../../services/api.service";
import { authService } from "../../services/auth.service";
import { Endpoints } from "../../services/endpoints";

export const useMultiSelectEntities = () => {
    const api = new Api();
    const user = authService.getCurrentUser();
    const [site, setSite] = useState<number>(0);
    const [installation, setInstallation] = useState<number>(0);
    const [workUnit, setWorkUnit] = useState<number>(0);
    const [operation, setOperation] = useState<number>(0);
    const sites = useQuery<Site[]>(Endpoints.SITES, () => api.getAll(Endpoints.SITES), {
        onSuccess: (result) => setSite(user.favoriteSite ? user.favoriteSite.id : result[0]?.id),
    });
    const installations = useQuery<Installation[]>(
        [Endpoints.INSTALLATIONS, site],
        () => api.getAll(Endpoints.INSTALLATIONS, [["site.id", site + ""]]),
        {
            enabled: site !== 0,
            onSuccess: (result: Installation[]) => {
                installation === 0 && result[0] && setInstallation(result[0]?.id ?? 0);
            },
        }
    );
    const workUnits = useQuery<WorkUnit[]>(
        [Endpoints.WORK_UNIT, installation],
        () => api.getAll(Endpoints.WORK_UNIT, [["installation.id", installation + ""]]),
        {
            enabled: installation !== 0,
            onSuccess: (result: WorkUnit[]) => {
                workUnit === 0 && result[0] && setWorkUnit(result[0]?.id ?? 0);
            },
        }
    );
    const operations = useQuery<Operation[]>(
        [Endpoints.OPERATIONS, workUnit],
        () => api.getAll(Endpoints.OPERATIONS, [["workUnit.id", workUnit + ""]]),
        {
            enabled: workUnit !== 0,
            onSuccess: (result: Operation[]) => {
                operation === 0 && result[0] && setOperation(result[0]?.id ?? 0);
            },
        }
    );
    return {
        site,
        sites,
        setSite,
        installation,
        installations,
        setInstallation,
        workUnit,
        workUnits,
        setWorkUnit,
        operation,
        operations,
        setOperation,
    };
};
