import {CSVLink} from "react-csv"
import {PathType} from "../../helpers/PathType";
import {DownloadIcon, QuestionIcon, UploadIcon} from "../../Icons";
import CSVReader from 'react-csv-reader'
import {useMutation} from "react-query";
import Api from "../../services/api.service";
import {cleanObj} from "../../helpers/Helpers";
import {Link} from "react-router-dom";
import {Routes} from "../routing/Routes";

export default function ExportToCsv({data, path}: { data: any, path: PathType }) {
    const api = new Api()
    const {mutate} = useMutation((formData: any) => api.post(path, formData))

    function handleUpLoad(result: any[]) {
        result.forEach(item => mutate(cleanObj(item)))
    }

    //Clone data ES
    const dataClone = [...data];
    let result: any[] = [];
    dataClone.forEach((item: any) => {
        const cloneItem = {...item};
        cloneItem.user && delete cloneItem.user;
        result.push(flattenObject(cleanObj(cloneItem)))
    })

    return (<div className="flex items-center">
            <CSVLink data={result}
                     filename={path + ".csv"}
                     className="btn btn-outline flex items-center"
                     title="Exporter en csv"
                     target="_blank"> <span className="2xl:mr-1"><DownloadIcon/></span> <span
                className="hidden 2xl:flex">Exporter en csv</span></CSVLink>
            <CSVReader
                label={<div className="flex items-center" title="Importer en CSV">
                <span className="flex items-center 2xl:mr-1">
                    <UploadIcon/>
                </span>
                    <span className="hidden 2xl:flex">Importer en CSV</span>
                </div>}
                cssLabelClass="cursor-pointer"
                cssClass="btn btn-outline flex items-center ml-3 2xl:ml-5 cursor-pointer"
                cssInputClass="p-0 m-0 hidden"
                parserOptions={{
                    header: true,
                    dynamicTyping: true,
                    delimitersToGuess: [','],
                    skipEmptyLines: true
                }}
                onFileLoaded={(result) => handleUpLoad(result)}/>
            <Link to={Routes.HELP_CSV} className="text-blue-light hover:text-blue-light-75 mx-3 2xl:mx-5"
                  title="Comment utiliser l'import CSV ?">
                <QuestionIcon/>
            </Link>
        </div>
    )
}


/**
 * Flatten an object
 * Exemple: {id: 1, name: "Frodon", friend: {id: 5, name: "Sam"}} => {id: 1, name: "Frodon", friend.id: 5, friend.name: "Sam"}
 * From https://stackoverflow.com/questions/44134212/best-way-to-flatten-js-object-keys-and-values-to-a-single-depth-array/59787588
 * @param object
 * @returns object
 */
function flattenObject(object: any) {
    let toReturn: any = {};

    for (let item in object) {
        if (!object.hasOwnProperty(item)) continue;

        if ((typeof object[item]) === 'object' && object[item] !== null) {
            let flatObject = flattenObject(object[item]);
            for (let x in flatObject) {
                if (!flatObject.hasOwnProperty(x)) continue;

                toReturn[item + '.' + x] = flatObject[x];
            }
        } else {
            toReturn[item] = object[item];
        }
    }
    return toReturn;
}

/**
 * Unflatten an object
 * Exemple: {id: 1, name: "Frodon", friend.id: 5, friend.name: "Sam"} => {id: 1, name: "Frodon", friend: {id: 5, name: "Sam"}}
 * From https://stackoverflow.com/questions/44134212/best-way-to-flatten-js-object-keys-and-values-to-a-single-depth-array/59787588
 * @param object
 * @returns object
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function unflattenObject(object: any) {
    const result = {};
    const regex = /^\.+[^.]*|[^.]*\.+$|(?:\.{2,}|[^.])+(?:\.+$)?/g; // Just a complicated regex to only match a single dot in the middle of the string
    for (const item in object) {
        if (Object.prototype.hasOwnProperty.call(object, item)) {
            const keys: any = item.match(regex);
            keys.reduce((r: any, e: any, j: any) => {
                return r[e] || (r[e] = isNaN(Number(keys[j + 1])) ? (keys.length - 1 === j ? object[item] : {}) : []);
            }, result);
        }
    }
    return result;
}
