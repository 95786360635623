import React from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import Api from "../../services/api.service";
import "../../index.css";
import {Backdrop, Fade, Modal} from "@material-ui/core";
import {PathType} from "../../helpers/PathType";
import FacilitiesModal from "./FacilitiesModal";
import {SitesModal} from "./SitesModal";
import {cleanObj} from "../../helpers/Helpers";
import {DangerFamilyModal} from "./DangerFamilyModal";
import {DangerSubFamilyModal} from "./DangerSubFamilyModal";
import EmployeesModal from "./EmployeesModal";
import ChemicalProductsModal from "./ChemicalProductsModal";
import ServicesModal from "./ServicesModal";
import RiskModal from "./Risk/RiskModal";
import {Tab} from "../../models/Tab";
import {DataColumn} from "../../models/DataColumn";
import {Color} from "@material-ui/lab/Alert";
import {Operation} from "../../models/Operation";
import RiskDuplicateModal from "./Risk/RiskDuplicateModal";
import DownloadDU from "./DownloadDU";

export interface ModalProps {
    modalVisibility: boolean;
    path: PathType;
    item?: any;
    method: methodType;
    toggleModal: toggleModalType;
    number?: number;
    data?: any;
}

export interface ModalEditProps {
    path: PathType;
    item: any;
    method: methodType;
    toggleModal: toggleModalType;
    tabs?: Tab[];
    columns?: DataColumn[];
    number?: number;
}

export interface ModalRemoveProps {
    path: PathType;
    item: any;
    toggleModal: toggleModalType;
}

export type toggleModalType = (event?: object, reason?: string, message?: string, severity?: Color) => void;
export type methodType = "edit" | "add" | "delete" | "evolve" | "archive" | "duplicate" | "downloadFile";

export default function ModalComponent({modalVisibility, path, item, method, toggleModal, number, data}: ModalProps) {
    function ModalContent(): JSX.Element | null {
        switch (path) {
            case PathType.OPERATIONS:
            case PathType.WORK_UNITS:
            case PathType.INSTALLATIONS:
                return <FacilitiesModal path={path} item={item} toggleModal={toggleModal} method={method}/>;
            case PathType.CHEMICAL_PRODUCTS:
                return <ChemicalProductsModal path={path} item={item} toggleModal={toggleModal} method={method}/>;
            case PathType.EMPLOYEES:
                return <EmployeesModal path={path} item={item} toggleModal={toggleModal} method={method}/>;
            case PathType.SITES:
                return <SitesModal path={path} item={item} toggleModal={toggleModal} method={method}/>;
            case PathType.SERVICES:
                return <ServicesModal path={path} item={item} toggleModal={toggleModal} method={method}/>;
            case PathType.DANGER_FAMILIES:
                return <DangerFamilyModal path={path} item={item} toggleModal={toggleModal} method={method}/>;
            case PathType.DANGER_SUB_FAMILIES:
                return <DangerSubFamilyModal path={path} item={item} toggleModal={toggleModal} method={method}/>;
            case PathType.RISKS:
                return <RiskModal path={path} item={item} toggleModal={toggleModal} method={method} number={number}/>;
            case PathType.RISKS_DUPLICATION:
                return <RiskDuplicateModal toggleModal={toggleModal} operationId={data}/>;
            case PathType.EXCEL:
                return <DownloadDU toggleModal={toggleModal}/>;
            default:
                return null;
        }
    }

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className="flex items-center justify-center modal"
            open={modalVisibility}
            onClose={toggleModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={modalVisibility}>
                <div className="bg-white rounded-2xl p-5 shadow-xl overflow-auto max-h-p-80 max-w-p-80">
                    <ModalContent/>
                </div>
            </Fade>
        </Modal>
    );
}


function addNumber(path: PathType, postData: any, formData: any, api: Api) {
    if (path === PathType.OPERATIONS || path === PathType.WORK_UNITS) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const {data} = useQuery<any>(path + 'number', () => api.getAll(path));
        let results = data;
        if (data !== undefined) {
            if (path === PathType.OPERATIONS) {
                const id = formData['workUnit.name'];
                results = data.filter((op: Operation) => op.workUnit?.id === id);
            }
            return {...postData, number: results.length}
        }
    }
    return postData
}

export function post(path: PathType, formData: any, id: number, toggleModal: toggleModalType,
                     method: string, number?: number, successCallback?: (result: any) => Promise<any> | any) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const queryClient = useQueryClient();
    const api = new Api();
    let postData = cleanObj({...formData});
    // Add number for operation and workUnit
    postData = addNumber(path, postData, formData, api);
    if (method === "edit") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useMutation(() => api.patch(path, postData, id), {
            onSuccess: () => {
                toggleModal({}, 'add_ressource', 'Ressource modifiée avec succès!');
                return queryClient.invalidateQueries([path, path]);
            },
        });
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMutation(() => api.post(path, postData), {
        onSuccess: (result: any) => {
            toggleModal({}, 'add_ressource', 'Ressource créée avec succès!');
            if (successCallback) {
                successCallback(result).then(() => {
                    queryClient.invalidateQueries([path, path]);
                })
            } else {
                queryClient.setQueryData([path, path], (old: any) => [...old, result]);
            }
        },
    });
}
