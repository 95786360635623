import React, {useState} from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert, {AlertProps, Color} from '@material-ui/lab/Alert';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const withSnackbar = (WrappedComponent: any) => {
    return (props: any) => {
        const [open, setOpen] = useState(false);
        const [message, setMessage] = useState("I'm a custom snackbar");
        const [duration, setDuration] = useState(2000);
        const [severity, setSeverity] = useState<Color>("success");

        const showMessage = (message: React.SetStateAction<string>, severity: Color = "success", duration = 2000) => {
            setMessage(message);
            setSeverity(severity);
            setDuration(duration);
            setOpen(true);
        };

        const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
            if (reason === 'clickaway') {
                return;
            }
            setOpen(false);
        };

        return (
            <>
                <WrappedComponent {...props} snackbar={showMessage}/>
                <Snackbar
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    autoHideDuration={duration}
                    open={open}
                    onClose={handleClose}
                >
                    <Alert onClose={handleClose} severity={severity}>
                        {message}
                    </Alert>
                </Snackbar>
            </>
        );
    };
};
