import React, {useEffect, useState} from "react";
import {PathType} from "../../helpers/PathType";
import {ModalEditProps, post} from "./Modal";
import {ActionsModal, HeaderModal} from "../utils/ActionsModal";
import {RenderInputs} from "./ModalTabs";
import {DataColumn} from "../../models/DataColumn";
import {cleanColumns, formRequirementValidation} from "../../helpers/Helpers";
import {siteColumns} from "../pages/SitesPage";
import RemoveModal from "./RemoveModal";


export function SitesModal({item, method, toggleModal}: ModalEditProps) {
    switch (method) {
        case "edit":
        case "add":
            return <EditModal item={item} path={PathType.SITES} toggleModal={toggleModal} method={method}/>;
        case "delete":
            return <RemoveModal label={item?.name} item={item} path={PathType.SITES} toggleModal={toggleModal}>
                Êtes-vous sûr de vouloir supprimer ce site ? Attention, toutes les installations, unités de travail et
                opérations qui en
                dépendent seront supprimées.
            </RemoveModal>;
        default:
            return null;
    }
}


function EditModal({item, path, toggleModal, method}: ModalEditProps) {
    const columns: DataColumn[] = cleanColumns([...siteColumns]);
    const [formData, setFormData] = useState<any>(method === "edit" ? item : {});
    const [isValidForm, setIsValidForm] = useState<boolean>(false);
    //PERFORM MUTATION
    const {isLoading, mutate} = post(path, formData, item?.id, toggleModal, method);

    useEffect(() => {
        setIsValidForm(formRequirementValidation(columns, formData))
    }, [columns, formData]);

    return (
        <div>
            <HeaderModal label={item?.name} method={method}>Ajout d'un site</HeaderModal>
            <RenderInputs elements={columns} formData={formData} setFormData={setFormData}/>
            <ActionsModal disabled={!isValidForm} toggleModal={toggleModal} isLoading={isLoading} method={method}
                          mutate={mutate}/>
        </div>
    );
}
