import React from "react";

interface FieldTextAreaProps {
    name: string;
    value: any;
    className?: string
    onChange: (e: any) => void;
    rows?: number;
    children?: string;
}

export function FieldTextArea({name, value, className = 'input', onChange, rows, children = ''}: FieldTextAreaProps) {
    return (
        <div>
            <label className="text-xl" htmlFor={name}>{children}</label>
            <textarea rows={rows ?? 3} className={className} name={name} onChange={onChange} value={value}/>
        </div>
    )
}
