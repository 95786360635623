/* eslint-disable react-hooks/exhaustive-deps */
import { useNonInitialEffect } from "./useNonInitialEffect";

export const useEffectResetMultiSelect = (site: number, installation: number, workUnit: number, setters: { (x: number): void }[]) => {
    useNonInitialEffect(() => {
        reset();
    }, [site]);

    useNonInitialEffect(() => {
        reset(0);        
    }, [installation]);

    useNonInitialEffect(() => {
        reset(1);
    }, [workUnit]);

    const reset = (limit: number = -1) => {
        setters.forEach((setter: (x: number) => void, index: number) => {
            if (index > limit) {
                setter(0);
            }
        });
    }
};
