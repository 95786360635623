import React from "react";
import {Link} from "react-router-dom";
import {GearIcon, GraphIcon, HelpIcon, HomeIcon, LogInIcon, LogoutIcon} from "../../Icons";
import "../../index.css";
import logo from "../../assets/logo-osirisse.png";
import logo_capse from "../../assets/logo-capse.png";
import {authService} from "../../services/auth.service";

const Nav = React.memo(function NavComponent({setPath}: { setPath: (x: string) => void }) {

    return (
        <nav className="bg-white h-10 top-0 w-full z-10">
            <div className="absolute top-0 left-0 m-1">
                <img className="pr-2 h-7" src={logo_capse} alt="logo CApse"/>
            </div>
            <div className="absolute top-0 left-1/2 transform -translate-x-2/4 m-1 flex items-center text-xl">
                <img className="pr-2 h-7" src={logo} alt="logo"/>
                OSIRISSE
            </div>
            <div className="absolute top-0 right-0 flex flex-row m-2 items-center text-blue-text">
                <a className="mx-2 hover:text-blue-light-75"
                   target="_blank"
                   href="https://osirisse.capse.fr/bdd/clients/docs/manuel.pdf" rel="noreferrer">
                    <HelpIcon/>
                </a>
                <a className="mx-2 hover:text-blue-light-75"
                   target="_blank"
                   href={process.env.REACT_APP_CAPSE_URL + "bdd/clients/index.php"} rel="noreferrer">
                    <HomeIcon/>
                </a>

                <Link className="mx-2 hover:text-blue-light-75" to="/">
                    <div onClick={() => setPath("")}>
                        <GraphIcon/>
                    </div>
                </Link>
                <Link className="mx-2 hover:text-blue-light-75" to="/admin">
                    <div onClick={() => setPath("")}>
                        <GearIcon/>
                    </div>
                </Link>
                <a className="mx-2 hover:text-blue-light-75"
                   target="_blank"
                   href={process.env.REACT_APP_CAPSE_URL + "bdd/clients/compte.php"} rel="noreferrer">
                    <LogInIcon/>
                </a>
                {/*<Link className="mx-2 hover:text-blue-light-75" to="/login">*/}
                {/*    <LogInIcon/>*/}
                {/*</Link>*/}
                <Link className="mx-2 hover:text-blue-light-75" onClick={() => authService.logout()} to="/">
                    <LogoutIcon/>
                </Link>
            </div>
        </nav>
    );
});
export default Nav;
