import React, {useState} from "react";
import {Field} from "../../utils/Inputs/Field";
import Button from "../../utils/Inputs/Button";
import {useMutation, useQueryClient} from "react-query";
import {Endpoints} from "../../../services/endpoints";
import Api from "../../../services/api.service";
import {PathType} from "../../../helpers/PathType";
import {toggleModalType} from "../Modal";
import {Risk} from "../../../models/Risk";
import {getRiskFromItem} from "../../../helpers/riskHelpers";

export interface ModalArchiveProps {
    path: PathType;
    item: Risk
    toggleModal: toggleModalType;
    number?: number;
    evolve?: boolean;
}

export default function ArchiveModal({path, item, toggleModal, number, evolve = false}: ModalArchiveProps) {
    const api = new Api();
    const queryClient = useQueryClient();
    const patch = useMutation(() => api.patch(Endpoints.RISKS, {endDate}, Number(item.id)), {
        onSuccess: () => {
            toggleModal({}, 'archive_risk', 'Risque archivé');
            if (evolve) {
                item.startDate = endDate;
                const postData = getRiskFromItem(item, number)
                post.mutate(postData);
            }
            return queryClient.invalidateQueries(path);
        }
    });

    const post = useMutation((postData: any) => api.post(Endpoints.RISKS, postData), {
        onSuccess: () => {
            toggleModal({}, 'add_risk', 'Risque ajouté');
            return queryClient.invalidateQueries(path);
        }
    })
    const [endDate, setEndDate] = useState<string>(new Date().toISOString().substr(0, 10));
    return (
        <><Field type='date'
                 name='endDate'
                 onChange={(e) => setEndDate(e.target.value)}
                 value={endDate}>
            Date de fin de période d'exposition
        </Field>
            <div className="flex justify-between">
                <Button trigger={toggleModal} styles="btn" label={["Annuler"]}/>
                <Button trigger={patch.mutate} disabled={!endDate} isLoading={patch.isLoading}
                        styles="btn-outline"
                        label={[evolve ? "Faire évoluer" : "Clôturer"]}/>
            </div>
        </>
    );
}
