import {FieldTextArea} from "../../utils/Inputs/FieldTextArea";
import React from "react";
import {FieldSelect} from "../../utils/Inputs/FieldSelect";
import {expositions, workingConditions} from "../../../helpers/DataColumns/RiskColumns";
import {RiskExposition} from "../../../models/RiskExposition";

export interface RiskStepFourProps {
    description: string,
    setDescription: (value: string) => void
    exposition: RiskExposition,
    setExposition: (value: RiskExposition) => void
    workingCondition: string,
    setWorkingCondition: (value: string) => void
}

export default function RiskStepFour({
                                         description,
                                         setDescription,
                                         exposition,
                                         setExposition,
                                         workingCondition,
                                         setWorkingCondition
                                     }: RiskStepFourProps) {
    return (
        <>
            <FieldTextArea
                name='description'
                onChange={(e) => setDescription(e.target.value)}
                value={description}>
                Description de la situation dangereuse
            </FieldTextArea>
            <FieldSelect
                className="mb-2"
                name={"workingCondition"}
                options={workingConditions}
                value={workingCondition}
                onChange={(e) => setWorkingCondition(e.target.value)}>
                Choisissez une condition de travail
            </FieldSelect>
            <FieldSelect
                className="mb-2"
                name={"exposition"}
                options={expositions}
                value={exposition}
                onChange={(e) => setExposition(e.target.value)}>
                Choisissez une exposition
            </FieldSelect>
        </>
    );
}
