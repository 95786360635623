import React from "react";
import Api from "../../services/api.service";
import {authService} from "../../services/auth.service";
import {useQuery} from "react-query";
import {Endpoints} from "../../services/endpoints";
import {LoaderIcon} from "../../Icons";
import {PathType} from "../../helpers/PathType";
import {DataTableTabs} from "../layout/DataTableTabs";
import {employeeColumnsTab} from "../../helpers/DataColumns/EmployeeColumns";
import { ErrorQuery } from "../utils/ErrorQuery";

const tabs = [
    {name: "Fiche personnel", index: 1},
    {name: "Fiche contrat", index: 2},
    {name: "Fiche employeur", index: 3},
    {name: "Temps de travail", index: 4},
];

export default function EmployeesPage() {
    const api = new Api();
    const user = authService.getCurrentUser();
    const {isLoading, data, isError} = useQuery<any>(
        [Endpoints.EMPLOYEES, Endpoints.EMPLOYEES],
        () => api.getAll(Endpoints.EMPLOYEES, [["service.user.id", user?.id + ""]])
    );

    if (isLoading) {
        return (
            <div className="h-4/5 flex items-center justify-center">
                <LoaderIcon/>
            </div>
        );
    }
    if (isError || data === undefined)
        return (
            <ErrorQuery>Erreur de chargement</ErrorQuery>
        );


    return <div className="h-4/5 overflow-auto">{user &&
    <DataTableTabs path={PathType.EMPLOYEES} tabs={tabs} dataTabs={employeeColumnsTab} data={data}/>}</div>;
}
