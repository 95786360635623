import {Checkbox} from '@material-ui/core'
import React from 'react'

interface ChackBoxInterface {
    value?: boolean;
    disabled?: boolean;
    callBack?: () => void;
}

export default function CheckBox({value = true, disabled, callBack}: ChackBoxInterface) {
    return (
        <Checkbox
            checked={value}
            onChange={callBack}
            disabled={disabled}
            color="primary"
            inputProps={{'aria-label': 'checkbox with default color'}}
        />
    )
}
