import React from "react";


interface FielSelectGroupProps {
    name: string;
    options: { id: number, name: string | number, group: string }[] | any[] | undefined;
    groups: { id: number, name: string | number }[] | any[] | undefined;
    value: any;
    className?: string
    isLoading?: boolean;
    onChange: (e: any) => void;
    disabled?: boolean;
    placeHolder?: boolean;
    children?: any;
}

const renderOptions = (options: any, id: number) => {
    return options.filter((o: any) => o.group === id).map((option: any) => (
            <option key={option.id} value={option.id}>{option.name}</option>
        )
    );
};

export const FieldSelectGroup = React.memo(function Select({
                                                               name,
                                                               options,
                                                               groups,
                                                               value,
                                                               className,
                                                               isLoading = false,
                                                               onChange,
                                                               disabled = false,
                                                               placeHolder = true,
                                                               children
                                                           }: FielSelectGroupProps) {
    const classes = "input " + className
    return (
        <div>
            <label className="text-xl relative" htmlFor={name}>{children}</label>
            {isLoading &&
            <select disabled={true}
                    className="input animate-pulse rounded-lg bg-gray-light text-gray-dark border-transparent">Recherche
                en
                cours...</select>}
            {!isLoading && (
                <select disabled={disabled} id={name}
                        className={disabled ? classes + " cursor-not-allowed" : classes + " cursor-pointer"}
                        value={value} onChange={onChange}>
                    {placeHolder && <option defaultChecked value={""}>Choisissez une option</option>}
                    {
                        groups?.map(group =>
                            (<optgroup key={group.id} label={group.name}>
                                {renderOptions(options, group.id)}
                            </optgroup>)
                        )
                    }
                    {options && options?.length < 1 && (
                        <option value="">Aucun résultats</option>
                    )}
                </select>
            )}
        </div>
    )
})
