import React, {useEffect, useState} from "react";
import Api from "../../services/api.service";
import {useQuery} from "react-query";
import {Endpoints} from "../../services/endpoints";
import {ActionsModal, HeaderModal} from "../utils/ActionsModal";
import {ModalEditProps, post} from "./Modal";
import {RenderInputs} from "./ModalTabs";
import {DataColumn} from "../../models/DataColumn";
import {dangerSubFamilyColumns} from "../../helpers/DataColumns/Columns";
import {addOptionsToSelect, arrayMinusElement, cleanColumns, formRequirementValidation} from "../../helpers/Helpers";
import {LoaderIcon} from "../../Icons";
import {Configuration} from "../../helpers/Constants";
import {DangerSubFamily} from "../../models/DangerSubFamily";
import RemoveModal from "./RemoveModal";
import {DangerConfiguration} from "../../models/DangerConfiguration";

function postToFromDangerSubFamily(item: any, method: string): {} {
    if (method === 'add') {
        return {
            isPainful: false,
            color: "#000000"
        };
    }
    const temp = {...item, 'family.name': item.family.id};
    delete temp.dangerConfigurations;
    delete temp.family;
    return temp;
}

export function DangerSubFamilyModal({item, method, path, toggleModal}: ModalEditProps) {
    switch (method) {
        case "edit":
        case "add":
            return <EditModal item={item} path={path} toggleModal={toggleModal} method={method}/>;
        case "delete":
            return <RemoveModal label={item?.name} item={item} path={path} toggleModal={toggleModal}>
                Êtes-vous sur de vouloir supprimer cette sous-famille de danger ?
            </RemoveModal>;
        default:
            return null;
    }
}

function EditModal({item, path, toggleModal, method}: ModalEditProps) {
    const api = new Api();
    const columns: DataColumn[] = cleanColumns([...dangerSubFamilyColumns]);
    const [isValidForm, setIsValidForm] = useState<boolean>(false);
    const [formData, setFormData] = useState(postToFromDangerSubFamily(item, method));

    const {
        isLoading,
        data
    } = useQuery([Endpoints.DANGER_FAMILIES, Endpoints.DANGER_FAMILIES], () => api.getAll(Endpoints.DANGER_FAMILIES))

    useEffect(() => {
        if (data) {
            let result = [...data]
            addOptionsToSelect(result, columns, 'family.name');
            const value = method === "add" ? result[0].id : item['family'].id;
            setFormData({...formData, 'family.name': value});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoading])

    /***
     * Callback function to init danger configuration after posting new sub-family
     * @param danger
     */
    function addDefaultConfiguration(danger: DangerSubFamily): Promise<DangerConfiguration> {
        const data = {
            dangerSubFamily: 'api/danger_sub_families/' + danger.id,
            coupleProbabilityGravity: {...Configuration.COUPLE_PG_DEFAULT},
            bubblesInfos: Configuration.BUBBLES_INFOS,
            active: true
        };
        return api.post(Endpoints.DANGER_CONFIGURATION, data);
    }

    const {
        isLoading: isPostLoading,
        mutate
    } = post(path, formData, item?.id, toggleModal, method, 0, addDefaultConfiguration);

    useEffect(() => {
        setIsValidForm(formRequirementValidation(columns, formData))
    }, [columns, formData]);

    if (!isLoading) {
        return (
            <div>
                <HeaderModal method={method} label={item?.name}>
                    Ajout d'une sous-famille de danger
                </HeaderModal>
                <div className="grid grid-cols-2 gap-6">
                    <div>
                        <RenderInputs elements={arrayMinusElement(columns, [4, 5, 6])} formData={formData}
                                      setFormData={setFormData}/>
                        <div className="grid grid-cols-2 gap-6">
                            <RenderInputs elements={[columns[4]]} formData={formData} setFormData={setFormData}/>
                            <RenderInputs elements={[columns[5]]} formData={formData} setFormData={setFormData}/>
                        </div>
                    </div>
                    <RenderInputs elements={[columns[6]]} formData={formData} setFormData={setFormData}/>
                </div>
                <ActionsModal disabled={!isValidForm} toggleModal={toggleModal} isLoading={isPostLoading}
                              method={method}
                              mutate={mutate}/>
            </div>
        );
    }

    return (
        <div>
            <LoaderIcon/>
            <p>Chargement...</p>
        </div>
    );


}
