import React, { useEffect, useState } from "react";
import Array from "../utils/Array";
import MartixSizeHandler from "../utils/MartixSizeHandler";
import { authService } from "../../services/auth.service";
import { User } from "../../models/User";
import { NameType } from "../../helpers/PathType";


export default function CotationPage({isControlled} : {isControlled: boolean}) {
    const storageUser = authService.getCurrentUser();
    const [user, setUser] = useState<User>(storageUser)

    useEffect(() => {
        localStorage.setItem('user', JSON.stringify({...user, token: storageUser.token}));
    }, [storageUser.token, user])

    return <div className="h-4/5 relative flex items-center justify-center">
        <MartixSizeHandler isControlled={isControlled} user={user} setUser={setUser} />
        <Array data={user[isControlled ? NameType.COTATION_CONTROLED : NameType.COTATION]} isControlled={isControlled} setUser={setUser} />
    </div>
}
