import React from 'react'
import { EntityReference, References } from '../../helpers/ReferencesCSV'

export default function CSVReferencePage() {

    return (
        <div className="mt-12 px-5">
            <h1 className="text-2xl font-semibold">Références pour l'import CSV:</h1>
            <p className="italic">* : éléments obligatoire à renseigner</p>
            <div className="flex flex-wrap">
                {References.map((ref) => <List key={ref.title} item={ref} />)}
            </div>
        </div>
    )
}

function List({ item }: { item: EntityReference }) {
    const cellStyle = "border-2 border-gray-dark py-1 px-2"
    return (<div className="m-5">
        <h6 className="text-lg font-semibold">{item.title}:</h6>
        <ul>
            <table className="table-auto border-2 border-collapse">
                <thead>
                    <tr className="bg-blue-light text-white">
                        <td className={cellStyle + " font-semibold"}>Entête</td>
                        <td className={cellStyle + " font-semibold"}>Correspondence</td>
                    </tr>
                </thead>
                <tbody>
                    {item.tags.map((value, index) => (
                        <tr key={value} className="hover:bg-blue-light-25">
                            <td className={cellStyle}>"{value}"</td>
                            <td className={cellStyle}>{item.values[index]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </ul>
    </div>)
}