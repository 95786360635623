import React from "react";
import { UseMutateFunction } from "react-query";
import Button from "./Inputs/Button";

interface ActionModalProps {
    toggleModal: () => void;
    isLoading: boolean;
    method?: string;
    mutate: UseMutateFunction
    disabled?: boolean;
}

interface HeaderModalProps {
    method?: string;
    label: string;
    children: any;
}

export function ActionsModal({ toggleModal, isLoading, method, mutate, disabled }: ActionModalProps) {
    return (
        <div className="flex justify-between">
            <Button trigger={toggleModal} styles="btn" label={["Annuler"]}/>
            <Button trigger={mutate} disabled={disabled} isLoading={isLoading} styles="btn-outline" label={[method === "edit" ? "Modifier" : "Ajouter"]}/>
        </div>
    )
}

export function HeaderModal({ method, label, children }: HeaderModalProps) {
    return (
        <h2 className="text-3xl text-center">
            {method === "edit" ? (
                <>Modification de <span className="font-semibold">{label}</span>
                </>
            ) : (
                <>{children}</>
            )}
        </h2>
    )
}


