import React from "react";
import {authService} from "../../services/auth.service";
import {Endpoints} from "../../services/endpoints";
import {LoaderIcon} from "../../Icons";
import {DataTable} from "../layout/DataTable";
import {DataColumn} from "../../models/DataColumn";
import {PathType} from "../../helpers/PathType";
import {useLoadData} from "../hooks/useLoadData";
import {ErrorQuery} from "../utils/ErrorQuery";
import {required, type, align} from "../../helpers/DataColumns/ColumnsConstants";

export const serviceColumns: DataColumn[] = [
    {id: "favorite", label: "Favori", minWidth: 100, align, type: "favorite"},
    {id: "name", label: "Libellé", minWidth: 200, type, required},
    {id: "action", label: "Action", minWidth: 100, align, type: "action"},
];
export default function ServicesPage() {
    const user = authService.getCurrentUser();

    const {isLoading, data, isError} = useLoadData(
        Endpoints.SERVICES,
        Endpoints.SERVICES,
        [["user.id", user?.id + ""]]
    );

    if (isLoading)
        return (
            <div className="h-4/5 flex items-center justify-center">
                <LoaderIcon/>
            </div>
        );

    if (isError || data === undefined) {
        return (
            <ErrorQuery>Erreur de chargement</ErrorQuery>
        );
    }

    return <div className="h-4/5 overflow-auto">{user &&
    <DataTable data={data} path={PathType.SERVICES} columns={serviceColumns}/>}</div>;
}
