import React, {useEffect, useState} from "react";
import {PathType} from "../../helpers/PathType";
import {
    buildUserPath,
    cleanColumns,
    formRequirementValidation,
    getDisplayName,
    getName,
    getParentPath
} from "../../helpers/Helpers";
import {authService} from "../../services/auth.service";
import {ModalEditProps, post} from "./Modal";
import {ActionsModal, HeaderModal} from "../utils/ActionsModal";
import {DataColumn} from "../../models/DataColumn";
import {COLUMNS} from "../../helpers/DataColumns/Columns";
import {RenderInputs} from "./ModalTabs";
import {useLoadRelation} from "../hooks/useLoadRelation";
import {LoaderIcon} from "../../Icons";
import RemoveModal from "./RemoveModal";


function postToFormFacilities(item: any, parent: any, method: string): {} {
    if (method === 'add') {
        return {};
    }
    const temp = {...item, [parent + '.name']: item[parent].id};
    delete temp[parent];
    return temp;
}

export default function FacilitiesModal({item, path, method, toggleModal}: ModalEditProps) {
    switch (method) {
        case "edit":
        case "add":
            return <EditModal item={item} path={path} toggleModal={toggleModal} method={method}/>;
        case "delete":
            return <RemoveModal label={item?.name} item={item} path={path} toggleModal={toggleModal}>
                Êtes-vous sûr de vouloir supprimer cet élement ? Attention, toutes unités de travail ou opérations qui
                en
                dépendent seront supprimées.
            </RemoveModal>;
        default:
            return null;
    }
}

function EditModal({item, path, toggleModal, method}: ModalEditProps) {
    const columns: DataColumn[] = cleanColumns([...COLUMNS[path]]);
    const user = authService.getCurrentUser();
    const [isValidForm, setIsValidForm] = useState<boolean>(false);
    const [parent, setParent] = useState<PathType | null>(getParentPath(path));

    const {
        isLoading,
        formData,
        setFormData
    } = useLoadRelation(path, parent, [[buildUserPath(parent), user?.id + '']], columns, getName(parent) + '.name', method, item, getName(parent), postToFormFacilities(item, getName(parent), method));
    //PERFORM MUTATION
    const {isLoading: isPostLoading, mutate} = post(path, formData, item?.id, toggleModal, method);

    useEffect(() => {
        setIsValidForm(formRequirementValidation(columns, formData))
    }, [formData, columns]);

    //CHANGE TREE IF CHANGE PATH
    useEffect(() => {
        setParent(getParentPath(path));
    }, [path]);

    if (!isLoading) {
        return (
            <div>
                <HeaderModal method={method} label={item?.name}>
                    Ajout d'un/une {getDisplayName(path)}
                </HeaderModal>
                <RenderInputs elements={columns} formData={formData} setFormData={setFormData}/>
                <ActionsModal disabled={!isValidForm} toggleModal={toggleModal} isLoading={isPostLoading}
                              method={method}
                              mutate={mutate}/>
            </div>
        );
    }
    return (
        <div>
            <LoaderIcon/>
            <p>Chargement...</p>
        </div>
    );
}
