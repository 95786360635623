import {methodType, ModalEditProps, toggleModalType} from "../Modal";
import React, {useState} from "react";
import {Step, StepLabel, Stepper} from "@material-ui/core";
import Button from "../../utils/Inputs/Button";
import {useMutation, useQueryClient} from "react-query";
import {Endpoints} from "../../../services/endpoints";
import Api from "../../../services/api.service";
import {authService} from "../../../services/auth.service";
import RiskStepOne from "./RiskStepOne";
import RiskStepTwo from "./RiskStepTwo";
import RiskStepThree from "./RiskStepThree";
import RiskStepFour from "./RiskStepFour";
import RiskStepFive from "./RiskStepFive";
import {Rb, Ri, Rr} from "../../../models/Risk";
import {
    createChemicalRisk,
    getChemicalRisk,
    getGravity,
    getProbability,
    getRbFromRisk,
    getRiFromRisk,
    getRrFromRisk
} from "../../../helpers/riskHelpers";
import RemoveModal from "../RemoveModal";
import ArchiveModal from "./ArchiveModal";
import {ChemicalRisk} from "../../../models/ChemicalRisk";
import {DangerSubFamily} from "../../../models/DangerSubFamily";
import {Prefix} from "../../../models/Prefix";
import {cleanObj} from "../../../helpers/Helpers";
import RiskStepSixAndSeven from "./RiskStepSixAndSeven";
import {RiskExposition} from "../../../models/RiskExposition";

function getSteps() {
    return [
        "Choix d'une opération",
        "Choix d'une sous famille de danger",
        "Choix d'une date",
        "Informations complémentaires",
        "Risque Brut",
        "Risque Initial",
        "Risque Résiduel",
    ];
}


const RiskStepper = ({
                         toggleModal, path, item, method, number
                     }: { toggleModal: toggleModalType, path: string, item?: any, method: methodType, number?: number }) => {
    const api = new Api();
    const user = authService.getCurrentUser();
    const queryClient = useQueryClient();

    // Start at step 4 if edit
    const [activeStep, setActiveStep] = useState<number>(item ? 4 : 0);
    //States
    // STEP ONE
    const [site, setSite] = useState<number>(item ? item.operation.workUnit.installation.site.id :
        user.favoriteSite ? user.favoriteSite.id : 0);
    const [installation, setInstallation] = useState<number>(item ? item.operation.workUnit.installation.id : 0);
    const [workUnit, setWorkUnit] = useState<number>(item ? item.operation.workUnit.id : 0);
    const [operation, setOperation] = useState<number>(item ? item.operation.id : 0);

    //STEP TWO
    const [family, setFamily] = useState<number>(item ? item.dangerFamily.family.id : 0);
    const [subFamily, setSubFamily] = useState<DangerSubFamily>(item ? item.dangerFamily : {});

    //STEP THREE
    const [startDate, setStartDate] = useState<string>(item ? item.startDate.substr(0, 10) :
        new Date().toISOString().substr(0, 10));

    //STEP FOUR
    const [description, setDescription] = useState<string>(item ? item.description : '');
    const [workingCondition, setWorkingCondition] = useState<string>(item ? item.workingCondition : '');
    const [exposition, setExposition] = useState<RiskExposition>(item ? item.exposition : '');

    // STEP FIVE
    const [rb, setRb] = useState<Rb>(item ? getRbFromRisk(item) : {});
    //STEP SIX
    const [ri, setRi] = useState<Ri>(item ? getRiFromRisk(item) : {});
    //STEP SEVEN
    const [rr, setRr] = useState<Rr>(item ? getRrFromRisk(item) : {});

    //STEP CHEMICAL RISK
    const [chemicalRiskRb, setChemicalRiskRb] = useState<ChemicalRisk>(item && item.chemicalRisk && item.chemicalRisk.length ? getChemicalRisk(item, Prefix.RB) : {slug: Prefix.RB});
    const [chemicalRiskRi, setChemicalRiskRi] = useState<ChemicalRisk>(item && item.chemicalRisk && item.chemicalRisk.length ? getChemicalRisk(item, Prefix.RI) : {slug: Prefix.RI});
    const [chemicalRiskRr, setChemicalRiskRr] = useState<ChemicalRisk>(item && item.chemicalRisk && item.chemicalRisk.length ? getChemicalRisk(item, Prefix.RR) : {slug: Prefix.RR});

    const post = useMutation((postData: any) => api.post(Endpoints.RISKS, cleanObj(postData)), {
        onSuccess: () => {
            toggleModal({}, 'add_risk', 'Risque ajouté');
            return queryClient.invalidateQueries(path);
        }
    })

    const patch = useMutation((patchData: any) => api.patch(Endpoints.RISKS, patchData, item.id), {
        onSuccess: () => {
            toggleModal({}, 'edit_risk', 'Risque corrigé');
            return queryClient.invalidateQueries(path);
        }
    })

    // Only 4 steps when adding a risk
    const steps = getSteps();

    function getStepContent(step: number) {
        switch (step) {
            case 0:
                return <RiskStepOne site={site} setSite={setSite}
                                    installation={installation} setInstallation={setInstallation}
                                    workUnit={workUnit} setWorkUnit={setWorkUnit}
                                    operation={operation} setOperation={setOperation}/>;
            case 1:
                return <RiskStepTwo family={family} setFamily={setFamily}
                                    subFamily={subFamily} setSubFamily={setSubFamily}/>;
            case 2:
                return <RiskStepThree startDate={startDate} setStartDate={setStartDate}/>;
            case 3:
                return <RiskStepFour description={description} setDescription={setDescription}
                                     workingCondition={workingCondition} setWorkingCondition={setWorkingCondition}
                                     exposition={exposition} setExposition={setExposition}/>;
            case 4 :
                return <RiskStepFive rb={rb} setRb={setRb} subFamily={subFamily} chemicalRisk={chemicalRiskRb}
                                     setChemicalRisk={setChemicalRiskRb} exposition={exposition}/>
            case 5 :
                return <RiskStepSixAndSeven risk={ri} setRisk={setRi} subFamily={subFamily}
                                            chemicalRisk={chemicalRiskRi}
                                            exposition={exposition}
                                            setChemicalRisk={setChemicalRiskRi} prefix={Prefix.RI}/>
            case 6 :
                return <RiskStepSixAndSeven risk={rr} setRisk={setRr} subFamily={subFamily}
                                            chemicalRisk={chemicalRiskRr}
                                            exposition={exposition}
                                            setChemicalRisk={setChemicalRiskRr} prefix={Prefix.RR}/>
            default:
                return 'Erreur';
        }
    }


    const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
    const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);

    function isStepValid() {
        switch (activeStep) {
            case 0 :
                return operation;
            case 1 :
                return subFamily.id;
            case 2 :
                return startDate;
            case 3 :
                return true;
            case 4 :
                return true;
            case 5 :
                return true;
            case 6 :
                return true;
        }
    }

    function getData() {
        const isChemicalRisk = subFamily && subFamily.name ? subFamily.name.startsWith('Agent chimique') : false;
        const isAtex = subFamily && subFamily.name && subFamily.name.startsWith('Explosion ATEX');
        const data = {
            operation: 'api/operations/' + operation,
            dangerFamily: 'api/danger_sub_families/' + subFamily.id,
            description,
            workingCondition,
            exposition,
            startDate,
            rbInfos: rb.rbInfos,
            rbIntensity: Number(rb.rbIntensity),
            rbFrequency: Number(rb.rbFrequency),
            rbAtexZoning: isAtex ? rb.rbAtexZoning : '',
            rbPersistence: isAtex ? rb.rbPersistence : '',
            rbGravity: subFamily.isPainful || isAtex ?
                getGravity(Number(rb.rbIntensity), subFamily, isChemicalRisk ? chemicalRiskRb : null) :
                Number(rb.rbGravity),
            rbProbability: subFamily.isPainful || isAtex ?
                getProbability(Number(rb.rbFrequency), subFamily, isChemicalRisk ? chemicalRiskRb : null, rb)
                : Number(rb.rbProbability),
            riInfos: ri.riInfos,
            riOrgaPrev: ri.riOrgaPrev,
            riOrgaProt: ri.riOrgaProt,
            riCollPrev: ri.riCollPrev,
            riCollProt: ri.riCollProt,
            riIndivPrev: ri.riIndivPrev,
            riIndivProt: ri.riIndivProt,
            riFrequency: Number(ri.riFrequency),
            riIntensity: Number(ri.riIntensity),
            riMasterPrev: Number(ri.riMasterPrev),
            riMasterProt: Number(ri.riMasterProt),
            rrInfos: rr.rrInfos,
            riAtexZoning: isAtex ? ri.riAtexZoning : '',
            riPersistence: isAtex ? ri.riPersistence : '',
            rrOrgaPrev: rr.rrOrgaPrev,
            rrOrgaProt: rr.rrOrgaProt,
            rrCollPrev: rr.rrCollPrev,
            rrCollProt: rr.rrCollProt,
            rrIndivPrev: rr.rrIndivPrev,
            rrIndivProt: rr.rrIndivProt,
            rrFrequency: Number(rr.rrFrequency),
            rrIntensity: Number(rr.rrIntensity),
            rrMasterPrev: Number(rr.rrMasterPrev),
            rrMasterProt: Number(rr.rrMasterProt),
            rrAtexZoning: isAtex ? rr.rrAtexZoning : '',
            rrPersistence: isAtex ? rr.rrPersistence : '',
            //@ts-ignore
            ignitionSources: isAtex ? rb.ignitionSources?.concat(ri.ignitionSources, rr.ignitionSources) : [],
            chemicalRisk: isChemicalRisk ? [
                    cleanObj(chemicalRiskRb),
                    createChemicalRisk(cleanObj(chemicalRiskRi)),
                    createChemicalRisk(cleanObj(chemicalRiskRr))
                ]
                : []
        };
        return cleanObj(data);
    }


    return (
        <div>
            <Stepper nonLinear={method === "edit"} activeStep={activeStep}>
                {steps.map((step: string, index: number) => (
                        <Step key={step}>
                            {method === "edit" ?
                                <StepLabel className='cursor-pointer'
                                           onClick={() => setActiveStep(index)}>{step}</StepLabel>
                                : <StepLabel>{step}</StepLabel>}
                        </Step>
                    )
                )}
            </Stepper>
            <div>
                {activeStep === steps.length ? (
                        <div className="flex justify-around">
                            {!item && (<><h2>Cliquer sur 'Valider' pour valider la création du risque</h2><Button
                                isLoading={post.isLoading} trigger={() => post.mutate({...getData(), number})}
                                label={['Valider']}/>
                            </>)}
                            {item && (<><h2>Cliquer sur 'Corriger' pour valider la correction du risque</h2><Button
                                isLoading={patch.isLoading} trigger={() => patch.mutate(getData())}
                                label={['Corriger']}/>
                            </>)}
                        </div>) :
                    <div>
                        <div>{getStepContent(activeStep)}</div>
                        <div className="mt-4 flex justify-between">
                            <Button disabled={activeStep === 0} trigger={handleBack} label={['Précédent']}/>
                            <Button disabled={!isStepValid()} trigger={handleNext} label={['Suivant']}/>
                        </div>
                    </div>}
            </div>
        </div>
    );
}


export default function RiskModal({item, method, path, toggleModal, number}: ModalEditProps) {
    const label: string = 'UT' + item?.operation.workUnit.number + '-O' + item?.operation.number + '-R' + item?.number;
    switch (method) {
        case "edit":
            return <RiskStepper toggleModal={toggleModal} path={path} item={item} method={method}/>;
        case "add":
            return <RiskStepper toggleModal={toggleModal} path={path} method={method} number={number}/>;
        case "delete":
            return <RemoveModal label={label} item={item} path={path} toggleModal={toggleModal}>
                Êtes-vous sur de vouloir supprimer ce risque ?
            </RemoveModal>;
        case "archive":
            return <ArchiveModal item={item} path={path} toggleModal={toggleModal}/>
        case "evolve":
            return <ArchiveModal item={item} path={path} toggleModal={toggleModal} number={number} evolve={true}/>
        default:
            return null;
    }
}
