import React, { useState } from "react";
import { useMutation } from "react-query";
import Api from "../../services/api.service";
import { authService } from "../../services/auth.service";
import { Endpoints } from "../../services/endpoints";
import Button from "../utils/Inputs/Button";
import { download } from "../../helpers/Helpers";
import { Field } from "../utils/Inputs/Field";
import { Color } from "@material-ui/lab/Alert";
import { FieldRadio } from "../utils/Inputs/FieldRadio";
import { withSnackbar } from "../utils/blocks/SnackBar";

function DownloadDUWrapped({ toggleModal }: { toggleModal: (event?: object, reason?: string, message?: string, severity?: Color) => void }) {
    const api = new Api();
    const [date, setDate] = useState<string>("")
    const [format, setFormat] = useState<"xlsx" | "pdf">("xlsx")
    const user = authService.getCurrentUser()
    const xlsx = useMutation(() => api.getFile(Endpoints.EXCEL, [["date", date], ["format", format]]), {
        onSuccess: (result) => {
            download(result, "DocumentUnique_" + user?.id + "." + format)
            toggleModal({}, 'export_ressource', 'Document unique exporté avec succès!')
        }
    })

    return (
        <>
            <h1 className="font-bold text-2xl text-center mt-5">Export du Document Unique</h1>
            <Field name={"date"} type={"date"} value={date} onChange={(e) => setDate(e.target.value)}>Sélectionnez une date</Field>
            <h3 className="text-xl font-bold mb-2">Choisissez un format d'export</h3>
            <FieldRadio name={"xlsx"} value={"xlsx"} onChange={(e) => setFormat(e.target.value)} checked={format === "xlsx"}>Excel</FieldRadio>
            <FieldRadio name={"pdf"} value={"pdf"} onChange={(e) => setFormat(e.target.value)} checked={format === "pdf"}>PDF</FieldRadio>
            <div className="flex justify-end mx-5 mt-8">
                <Button disabled={date === ""} isLoading={xlsx.isLoading} trigger={xlsx.mutate}
                    label={["Exporter"]} />
            </div>
        </>
    );
}
const DownloadDU = withSnackbar(DownloadDUWrapped);
export default DownloadDU;