export interface EntityReference {
    title: string;
    tags: string[];
    values: string[];
}

export const References: EntityReference[] = [
    {
        title: "Site",
        tags: ["name", "description"],
        values: ["Nom du site*", "Description du site"],
    },
    {
        title: "Installation",
        tags: ["name", "site.id"],
        values: ["Nom de l'installation*", "Identifiant du site parent*"],
    },
    {
        title: "Unité de travail",
        tags: ["name", "installation.id", "number"],
        values: ["Nom de unité de travail*", "Identifiant de l'installation parente*", "Numéro de l'unité de travail*"],
    },
    {
        title: "Opération",
        tags: ["name", "workUnit.id", "number"],
        values: ["Nom de l'opération*", "Identifiant de l'unité de travail parente*", "Numéro de l'opération*"],
    },
    {
        title: "Services et département",
        tags: ["name"],
        values: ["Nom du service*"],
    },
    {
        title: "Registre du personnel",
        tags: [
            "last_name",
            "first_name",
            "gender",
            "birth_date",
            "nationality",
            "work_title_type",
            "work_title_nb",
            "address",
            "job",
            "qualification",
            "coefficient",
            "hiring_date",
            "start_date",
            "end_date",
            "contract_type",
            "work_condition",
            "worker_category",
            "temporary_work_company_contact",
            "employer_group_infos",
            "year",
            "annual_working_time",
            "time_spent_percentage",
            "cumulative_absence_time",
            "night_shifts_nb",
            "alternating_shifts_nb",
        ],
        values: [
            "Nom*",
            "Prénom*",
            "Sexe",
            "Date de naissance",
            "Nationnalité",
            "Type de titre de travail",
            "N° du titre de travail",
            "Adresse",
            "Emploi",
            "Qualification",
            "Coefficient",
            "Date d'embauche",
            "Date d'entrée",
            "Date de sortie",
            "Type de contrat",
            "Condition de travail",
            "Catégorie de travailleurs soumis à certaines dispositions",
            "Service / Département",
            "Coordonnées de l'entreprise de travail temporaire",
            "Dénomination et adresse du groupement d'employeurs en cas de mise à disposition du personnel",
            "Année",
            "Temps de travail annuel",
            "Pourcentage de temps de présence",
            "Nombre d'heure cumulée dans l'année",
            "Nombre de travail de nuit par année",
            "Nombre de travail en équipe alternante par année",
        ],
    },
    {
        title: "Produits chimiques",
        tags: [
            "index_nb",
            "chemical_id",
            "cas_nb",
            "ce_nb",
            "concentration_limits",
            "rating",
            "pictogram",
            "hazard_statement",
            "density",
            "mass",
            "flash_point",
            "melting_point",
            "boiling_point",
            "auto_ignition_temp",
            "critical_temp",
            "flammable_limit_lower",
            "flammable_limit_upper",
            "manufacturer_name",
            "safety_data_sheet",
            "chemical_name",
            "chemical_concentration",
        ],
        values: [
            "Numéro index international",
            "Identifiant Chimique*",
            "Numéro CAS*",
            "Numéro CE*",
            "Limite de concentration",
            "Notes",
            "Code(s) des pictogrammes, mentions d'avertissement",
            "Code(s) des mentions de dangers",
            "Masse volumique",
            "Masse ou poids moléculaire",
            "Point d'éclair",
            "Point de fusion",
            "Point d'ébullition",
            "Température d'auto-inflammation",
            "Température critique",
            "Limite d'inflammabilité - inférieur",
            "Limite d'inflammabilité - supérieur",
            "Nom du fabriquant",
            "Nom chimique",
            "Concentration chimique",
            "Fiche données sécurité",
        ],
    },
    {
        title: "Famille de dangers",
        tags: [
            "name",
        ],
        values: [
            "Nom de la famille de danger*",
        ]
    },
    {
        title: "Sous-famille de dangers",
        tags: [
            "name",
            "family.id",
            "frequency_unit",
            "intensity_unit",
            "is_painful",
        ],
        values: [
            "Nom de la sous-famille de danger*",
            "Identifiant de la famille de danger*",
            "Unité de fréquence",
            "Unité d'intensité",
            "Pénibilité de la Sous-famille de danger*",
        ]
    }
];
