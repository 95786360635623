import {Rb, Ri, Rr} from "../../models/Risk";
import {Prefix} from "../../models/Prefix";
import {useEffect, useState} from "react";
import {IgnitionSource} from "../../models/IgnitionSource";

const isThereSources = (sources: IgnitionSource[] | undefined, slug: Prefix): boolean => !!filterSources(sources, slug).length
const filterSources = (sources: IgnitionSource[] | undefined, slug: Prefix) => sources ? sources.filter(s => s.slug === slug) : []

const createSource = (slug: Prefix): IgnitionSource => {
    return {
        label: '',
        atexZone: [''],
        probability: '',
        slug
    }
}

export const useSources = (risk: Rb | Ri | Rr, setRisk: (value: Rb | Ri | Rr) => void, slug: Prefix) => {
    const getSource = (slug: Prefix) => {
        return isThereSources(risk.ignitionSources, slug) ?
            filterSources(risk.ignitionSources, slug) :
            [createSource(slug)]
    }

    const [sources, setSources] = useState<IgnitionSource[]>(getSource(slug));

    const addSource = () => setSources([...sources, createSource(slug)])
    const removeSource = (index: number) => {
        let temp = [...sources]
        temp.splice(index, 1);
        setSources(temp)
    }

    const handleChange = (value: any, index: number, key: string) =>
        setSources(sources.map((source: any, i: number) => index === i ? {...source, [key]: value} : source));

    useEffect(() => {
        setRisk({...risk, ignitionSources: sources})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sources])

    useEffect(() => {
        setSources(getSource(slug))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slug])

    return {sources, addSource, removeSource, handleChange}


}
