import React, {useState} from "react";
import Button from "./Inputs/Button"
import {DownloadIcon} from "../../Icons";
import ModalComponent from "../modals/Modal";
import {PathType} from "../../helpers/PathType";
import {Color} from "@material-ui/lab/Alert";

export default function ExportToXlsxPdf({snackbar}: { snackbar?: any }) {
    const [modalVisibility, setModalVisibility] = useState(false)

    function toggleModal(event?: object, reason?: string, message?: string, severity: Color = "success") {
        setModalVisibility(!modalVisibility);
        if (message) {
            snackbar(message, severity);
        }
    }

    return (<div title="Générer le document unique">
        <ModalComponent
            modalVisibility={modalVisibility}
            path={PathType.EXCEL}
            toggleModal={toggleModal}
            method={"downloadFile"}
        />
        <Button styles={"mx-2"} trigger={() => setModalVisibility(true)} label={[<DownloadIcon/>]}/>
    </div>)
}
