import React from "react";
import { User } from "../../models/User";
import { ErrorIcon } from "../../Icons";

interface UserCardContent {
    user: User | null | undefined;
    isLoading: boolean;
    isError: boolean;
}

const circleResponsive = "h-10 w-10 2xl:h-12 2xl:w-12";
const circleClasses = "inline-block rounded-full flex items-center justify-center " + circleResponsive;

export default function UserCard({ user, isLoading, isError }: UserCardContent) {
    return (
        <div className="px-5 min-h-14 w-full max-w-full">
            {(isLoading && <WaitingOn />) || (isError && <Error />) || (user && <Success user={user} />)}
        </div>
    );
}

function Success({ user }: { user: User | null | undefined }) {
    return (
        <div className="block flex-row items-center p-3 xl:flex">
            <div className={circleResponsive + " mx-auto 2xl:m-0"}>
                <div
                    className={circleClasses + " bg-blue-light-75"}>
                    <p className="text-white font-bold uppercase">{user?.username !== undefined && user?.username[0]}</p>
                </div>
            </div>
            <div className="text-center text-xl xl:text-left xl:text-lg 2xl:text-xl">
                <p className="text-gray-dark p-0 xl:pl-2">{user?.username}</p>
            </div>
        </div>
    );
}

const WaitingOn = () => (
    <div className="block flex-row items-center bg-gray-slight rounded-md p-3 xl:flex animate-pulse">
        <div
            className={circleClasses + " bg-gray-light mx-auto xl:m-0"}>
            <p className="text-black uppercase h-8"></p>
        </div>
        <div className="xl:ml-2 text-center flex flex-col items-center xl:block">
            <p className="bg-gray-light h-5 w-20 m-1 rounded"></p>
            <p className="bg-gray-light h-5 w-28 m-1 rounded"></p>
        </div>
    </div>
);

const Error = () => (
    <div className="bg-gray-slight flex items-end justify-center rounded-md p-4">
        <ErrorIcon />
        <p>Erreur de chargement</p>
    </div>
);
