import React from "react";
import ReactDOM from "react-dom";
import "./sass/style.scss";
import "./index.css";
import {QueryClient, QueryClientProvider} from "react-query";
import {ReactQueryDevtools} from 'react-query/devtools'
import {createTheme, ThemeProvider} from '@material-ui/core/styles';
import {MyRouter} from "./components/routing/MyRouter";
import {frFR} from "@material-ui/core/locale";
import {authService} from "./services/auth.service";


const theme = createTheme({
        palette: {
            primary: {
                main: "#3B9DC0",
            },
            secondary: {
                main: "#D3D3D3",
            },
        },
    },
    frFR);
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
});

const windowUrl = window.location.search;
const params = new URLSearchParams(windowUrl);
if (params.has('token')) {
    const token = params.get('token')!;
    authService.externalLogin(token).then(() => params.delete('token'))
}

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <MyRouter/>
            </ThemeProvider>
            <ReactQueryDevtools initialIsOpen={false}/>
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root")
);
