import React from "react";

interface ButtonProps {
    trigger?: () => void;
    label: any[];
    disabled?: boolean;
    isLoading?: boolean;
    styles?: string;
}

const Button = React.memo(function Button({trigger, label, disabled, isLoading, styles}: ButtonProps) {
    let classes = " btn flex items-center " + styles
    if (isLoading) {
        classes += " animate-pulse ";
    }
    const contentClasses = "flex items-center "
    return (
        <button disabled={disabled || isLoading} onClick={trigger}
                className={disabled || isLoading ? classes + " btn-disabled" : classes}>
            <span className={contentClasses}>
                {label && label.map((item: any, index: number) => <span key={index}
                                                                        className={label.length > 1 ? "mr-1" : ""}>{item}</span>)}
            </span>
        </button>
    )
})
export default Button;
