import Api from "../../../services/api.service";
import {useQuery} from "react-query";
import {Site} from "../../../models/Site";
import {Endpoints} from "../../../services/endpoints";
import MultiSelect from "../../utils/MultiSelect";
import {authService} from "../../../services/auth.service";
import {useNonInitialEffect} from "../../hooks/useNonInitialEffect";

export interface RiskStepOneProps {
    site: number,
    setSite: (value: number) => void,
    installation: number,
    setInstallation: (value: number) => void,
    workUnit: number,
    setWorkUnit: (value: number) => void,
    operation: number,
    setOperation: (value: number) => void,
}

export default function RiskStepOne({
                                        site,
                                        setSite,
                                        installation,
                                        setInstallation,
                                        workUnit,
                                        setWorkUnit,
                                        operation,
                                        setOperation
                                    }: RiskStepOneProps) {
    const api = new Api();
    const user = authService.getCurrentUser();
    //Queries
    const sites = useQuery<Site[]>(Endpoints.SITES + "_modal", () => api.getAll(Endpoints.SITES), {
        onSuccess: (result) => site === 0 && (user.favoriteSite ? user.favoriteSite.id : setSite(result[0].id ?? 0))
    })
    const installations = useQuery<Site[]>([Endpoints.INSTALLATIONS + "_modal", site], () => api.getAll(Endpoints.INSTALLATIONS, [["site.id", site + ""]]), {
        enabled: site !== 0,
        onSuccess: (result) => installation === 0 && result && result[0] && setInstallation(result[0].id ?? 0)
    })
    const workUnits = useQuery<Site[]>([Endpoints.WORK_UNIT + "_modal", installation], () => api.getAll(Endpoints.WORK_UNIT, [["installation.id", installation + ""]]), {
        enabled: installation !== 0,
        onSuccess: (result) => workUnit === 0 && result && result[0] && setWorkUnit(result[0].id ?? 0)
    })
    const operations = useQuery<Site[]>([Endpoints.OPERATIONS + "_modal", workUnit], () => api.getAll(Endpoints.OPERATIONS, [["workUnit.id", workUnit + ""]]), {
        enabled: workUnit !== 0,
        onSuccess: (result) => operation === 0 && result && result[0] && setOperation(result[0].id ?? 0)
    })

    useNonInitialEffect(() => {
        setInstallation(0);
        setWorkUnit(0);
        setOperation(0);
    }, [site])

    useNonInitialEffect(() => {
        setWorkUnit(0);
        setOperation(0);
    }, [installation])

    useNonInitialEffect(() => {
        setOperation(0);
    }, [workUnit])

    const selectsProps = [
        {
            name: "site",
            collection: sites,
            item: site,
            setter: setSite,
            placeHolder: false,
            label: "Choisissez un site"
        },
        {
            name: "installation",
            collection: installations,
            item: installation,
            setter: setInstallation,
            enabler: site === 0,
            placeHolder: false,
            label: "Choisissez une installation"
        },
        {
            name: "workUnit",
            collection: workUnits,
            item: workUnit,
            setter: setWorkUnit,
            enabler: site === 0 || installation === 0,
            placeHolder: false,
            label: "Choisissez une unité de travail"
        },
        {
            name: "operation",
            collection: operations,
            item: operation,
            setter: setOperation,
            enabler: site === 0 || installation === 0 || workUnit === 0,
            placeHolder: false,
            label: "Choisissez une opération"
        }
    ]

    return (
        <>
            <MultiSelect selects={selectsProps} cols={"grid-cols-2"}/>
        </>
    );
}
