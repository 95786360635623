import React, { useEffect, useState } from "react";
import "../../index.css";
import { cleanObj, formRequirementValidation } from "../../helpers/Helpers";
import ModalTabs from "./ModalTabs";
import { ChemicalProduct } from "../../models/ChemicalProduct";
import { ModalEditProps, post } from "./Modal";
import { chemicalColumns } from "../../helpers/DataColumns/ChemicalColumns";
import { ActionsModal, HeaderModal } from "../utils/ActionsModal";
import RemoveModal from "./RemoveModal";

export default function ChemicalProductsModal({ item, method, path, toggleModal }: ModalEditProps) {
    switch (method) {
        case "edit":
        case "add":
            return <EditModal item={item} path={path} toggleModal={toggleModal} method={method} />;
        case "delete":
            return <RemoveModal label={item?.chemicalId} item={item} path={path} toggleModal={toggleModal}>
                Êtes-vous sur de vouloir supprimer ce produit chimique ?
            </RemoveModal>;
        default:
            return null;
    }
}

const tabs = [
    { name: "Description", index: 1 },
    { name: "Pictogramme / Mention", index: 2 },
    { name: "Propriété phys-chim", index: 3 },
    { name: "Composition", index: 4 },
]

function EditModal({ item, path, toggleModal, method }: ModalEditProps) {
    const columns = cleanObj([...chemicalColumns]);
    const [isValidForm, setIsValidForm] = useState<boolean>(true);
    const [formData, setFormData] = useState<ChemicalProduct | any>(method === "edit" ? item : {})
    //PERFORM MUTATION
    const { isLoading, mutate } = post(path, formData, item?.id, toggleModal, method);

    useEffect(() => {
        setIsValidForm(formRequirementValidation(columns, formData))
    }, [columns, formData]);

    return (
        <div>
            <HeaderModal label={item?.name} method={method}>Ajout d'un produit chimique</HeaderModal>
            <ModalTabs tabs={tabs} inputs={columns} formData={formData} setFormData={setFormData} grid={true} />
            <ActionsModal disabled={!isValidForm} toggleModal={toggleModal} isLoading={isLoading} method={method}
                mutate={mutate} />
        </div>
    );
}
