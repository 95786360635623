import React from "react";
import {FieldTextArea} from "../../utils/Inputs/FieldTextArea";
import {useQuery} from "react-query";
import {Endpoints} from "../../../services/endpoints";
import {DangerConfiguration} from "../../../models/DangerConfiguration";
import Api from "../../../services/api.service";
import {Ri, Rr} from "../../../models/Risk";
import {DangerSubFamily} from "../../../models/DangerSubFamily";
import {FieldSelectArray} from "../../utils/Inputs/FieldSelectArray";
import RiskStepChemical from "./RiskStepChemical";
import {ChemicalRisk} from "../../../models/ChemicalRisk";
import {isRiskAtex, isRiskChemical} from "../../../helpers/riskHelpers";
import RiskStepAtex from "./RiskStepAtex";
import {Prefix} from "../../../models/Prefix";
import {Field} from "../../utils/Inputs/Field";
import {RiskExposition} from "../../../models/RiskExposition";

export interface RiskStepSixAndSevenProps {
    risk: Ri | Rr,
    setRisk: (value: Ri | Rr) => void,
    chemicalRisk: ChemicalRisk,
    setChemicalRisk: (value: ChemicalRisk) => void,
    subFamily: DangerSubFamily,
    prefix: Prefix,
    exposition: RiskExposition,
}

export default function RiskStepSixAndSeven({
                                                risk,
                                                setRisk,
                                                subFamily,
                                                chemicalRisk,
                                                setChemicalRisk,
                                                prefix,
                                                exposition
                                            }: RiskStepSixAndSevenProps) {
    const api = new Api();
    const isChemicalRisk = subFamily.name && isRiskChemical(subFamily.name, exposition);
    const isAtex = subFamily.name && isRiskAtex(subFamily.name);
    const configuration = useQuery<DangerConfiguration[]>(
        Endpoints.DANGER_CONFIGURATION,
        () => api.getAll(Endpoints.DANGER_CONFIGURATION, [["dangerSubFamily.id", subFamily.id + '']])
    )
    const displayM = !configuration.isLoading && configuration.data && !subFamily.isPainful && !isChemicalRisk && !isAtex;
    return (

        <div>
            <h3 className='text-lg text-center mb-5'>Mesure de maitrise des
                risques {prefix === Prefix.RI ? 'existants' : 'résiduels'}</h3>
            <div className='grid grid-flow-row grid-cols-5 grid-rows-3 gap-4'>
                {/*ROW 1*/}
                <h3/>
                <h3 className='text-lg text-center'>Organisationnelles</h3>
                <h3 className='text-lg text-center'>Collectives</h3>
                <h3 className='text-lg text-center'>Individuelles</h3>
                <h3/>
                {/*ROW 2*/}
                <h3 className='text-lg text-center'>Prevention</h3>
                <FieldTextArea
                    name={prefix + 'OrgaPrev'}
                    onChange={(e) => setRisk({...risk, [prefix + 'OrgaPrev' as keyof (Ri | Rr)]: e.target.value})}
                    value={risk[prefix + 'OrgaPrev' as keyof (Ri | Rr)] ?? ''}/>
                <FieldTextArea
                    name={prefix + 'CollPrev'}
                    onChange={(e) => setRisk({...risk, [prefix + 'CollPrev' as keyof (Ri | Rr)]: e.target.value})}
                    value={risk[prefix + 'CollPrev' as keyof (Ri | Rr)] ?? ''}/>
                <FieldTextArea
                    name={prefix + 'IndivPrev'}
                    onChange={(e) => setRisk({...risk, [prefix + 'IndivPrev' as keyof (Ri | Rr)]: e.target.value})}
                    value={risk[prefix + 'IndivPrev' as keyof (Ri | Rr)] ?? ''}/>
                {displayM ? <FieldSelectArray
                    className="mb-2"
                    name="preventionCriteria"
                    bubbleInfos={configuration.data![0].bubblesInfos}
                    options={configuration.data![0].coupleProbabilityGravity.G}
                    value={risk[prefix + 'MasterPrev' as keyof (Ri | Rr)] ?? ''}
                    onChange={(e) => setRisk({...risk, [prefix + 'MasterPrev' as keyof (Ri | Rr)]: e.target.value})}>
                    Cotation du niveau de maitrise
                </FieldSelectArray> : <h3/>}
                {/*ROW 3*/}
                <h3 className='text-lg text-center'>Protection</h3>
                <FieldTextArea
                    name={prefix + 'OrgaProt'}
                    onChange={(e) => setRisk({...risk, [prefix + 'OrgaProt' as keyof (Ri | Rr)]: e.target.value})}
                    value={risk[prefix + 'OrgaProt' as keyof (Ri | Rr)] ?? ''}/>

                <FieldTextArea
                    name={prefix + 'CollProt'}
                    onChange={(e) => setRisk({...risk, [prefix + 'CollProt' as keyof (Ri | Rr)]: e.target.value})}
                    value={risk[prefix + 'CollProt' as keyof (Ri | Rr)] ?? ''}/>

                <FieldTextArea
                    name={prefix + 'IndivProt'}
                    onChange={(e) => setRisk({...risk, [prefix + 'IndivProt' as keyof (Ri | Rr)]: e.target.value})}
                    value={risk[prefix + 'IndivProt' as keyof (Ri | Rr)] ?? ''}/>

                {displayM ? <FieldSelectArray
                    className="mb-2"
                    name="protectionCriteria"
                    bubbleInfos={configuration.data![0].bubblesInfos}
                    options={configuration.data![0].coupleProbabilityGravity.PF}
                    value={risk[prefix + 'MasterProt' as keyof (Ri | Rr)] ?? ''}
                    onChange={(e) => setRisk({...risk, [prefix + 'MasterProt' as keyof (Ri | Rr)]: e.target.value})}>
                    Cotation du niveau de maitrise
                </FieldSelectArray> : <h3/>}
            </div>

            {subFamily.isPainful && !isChemicalRisk && !isAtex &&
                <div className='flex flex-row m-3 justify-center flex-1'>
                    <div className='m-1'>
                        <Field type='number'
                               name={prefix + 'Frequency'}
                               onChange={(e) => setRisk({
                                   ...risk,
                                   [prefix + 'Frequency' as keyof (Ri | Rr)]: e.target.value
                               })}
                               value={risk[prefix + 'Frequency' as keyof (Ri | Rr)] ?? ''}>
                            Fréquence en {subFamily.frequencyUnit}
                        </Field>
                    </div>

                    <div className='m-1'>
                        <Field type='number'
                               name={prefix + 'Intensity'}
                               onChange={(e) => setRisk({
                                   ...risk,
                                   [prefix + 'Intensity' as keyof (Ri | Rr)]: e.target.value
                               })}
                               value={risk[prefix + 'Intensity' as keyof (Ri | Rr)] ?? ''}>
                            Intensité en {subFamily.intensityUnit}
                        </Field>
                    </div>

                </div>}

            {/*Informations complémentaires*/}
            {
                !isChemicalRisk && !isAtex &&

                <FieldTextArea
                    name={prefix + 'Infos'}
                    onChange={(e) => setRisk({...risk, [prefix + 'Infos' as keyof (Ri | Rr)]: e.target.value})}
                    value={risk[prefix + 'Infos' as keyof (Ri | Rr)] ?? ''}>
                    Informations complémentaires
                </FieldTextArea>
            }

            <div className="grid grid-cols-2 gap-4">
                {isChemicalRisk && <RiskStepChemical chemicalRisk={chemicalRisk} setChemicalRisk={setChemicalRisk}/>}
            </div>
            {isAtex && <RiskStepAtex risk={risk} setRisk={setRisk} slug={prefix}/>}
        </div>

    )
}
