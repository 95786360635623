import React from 'react'
import { parseIntIfNumber } from '../../helpers/Helpers'
import { FieldSelect } from './Inputs/FieldSelect'

type Entity = { name: string, collection: any, item: any, setter: (e: any) => void, label?: string, enabler?: boolean; placeHolder?: boolean }
export default function MultiSelect({ selects, cols = "grid-cols-3" }: { selects: any; cols?: string }) {
    return (
        <header className={"grid gap-4 h-1/6 " + cols}>
            {selects.map((entity: Entity, index: number) => (
                <FieldSelect
                    key={index}
                    name={entity.name}
                    isLoading={entity.collection.isLoading}
                    options={entity.collection.data}
                    value={entity.item}
                    placeHolder={entity.placeHolder}
                    onChange={(e) => entity.setter(parseIntIfNumber(e.target.value))}
                    disabled={entity.enabler}>
                    {entity.label}
                </FieldSelect>
            ))}
        </header>
    )
}

