import {authService} from "./auth.service";

export interface HttpOptions {
    headers: Headers;
}

class Api {
    httpOptions: HttpOptions;
    _entryPoint = process.env.REACT_APP_API;

    constructor() {
        this.httpOptions = {
            headers: new Headers({
                "Content-Type": "application/json",
                Accept: "application/json",
            }),
        };
    }

    build(params: [string, string][]): string {
        let r = "";
        if (params === null || params === undefined) {
            return r;
        }
        for (let i = 0, len = params.length; i < len; i++) {
            if (i === 0) {
                r = "?";
            }
            r += params[i][0] + "=" + encodeURIComponent(params[i][1]);
            if (i !== len - 1) {
                r += "&";
            }
        }
        return r;
    }

    /**
     * Get an entity from an id
     * @param path name of the group of entities asked
     * @param params array of parameters for the query
     * @param id
     * @returns Promise<any>
     */
    get(path: string, params: [string, string][] | null = null, id?: number): Promise<any> {
        const itemId = id ? "/" + id : ""
        // @ts-ignore
        const url = this._entryPoint + path + itemId + this.build(params);
        this.httpOptions.headers.set('Authorization', this.buildBearer());
        return fetch(url, this.httpOptions).then((res) => this.handleResponse(res));
    }

    /**
     * Get an array of asked entity
     * @param path name of the group of entities asked
     * @param params array of parameters for the query
     * @returns Promise<any[]>
     */
    getAll(path: string, params?: [string, string][] | null): Promise<any[]> {
        // @ts-ignore
        const url = this._entryPoint + path + this.build(params);
        this.httpOptions.headers.set('Authorization', this.buildBearer());
        return fetch(url, this.httpOptions).then((res) => this.handleResponse(res));
    }

    /**
     * Post data to api
     * @param path name of the group of entities asked
     * @param data object of data
     * @returns Promise<any>
     */
    post(path: string, data: object): Promise<any> {
        const url = this._entryPoint + path;
        this.httpOptions.headers.set('Authorization', this.buildBearer());
        return fetch(url, {
            ...this.httpOptions,
            method: "POST",
            body: JSON.stringify(data)
        }).then((res) => this.handleResponse(res));
    }

    /**
     * Modify an entity (entirely or partially) from an id
     * @param path name of the group of entities asked
     * @param data object of data
     * @param id id of the very entity asked
     * @returns Promise<any>
     */
    patch(path: string, data: object, id: number): Promise<any> {
        const url = this._entryPoint + path + "/" + id;
        this.httpOptions.headers.set('Authorization', this.buildBearer());
        return fetch(url, {
            ...this.httpOptions,
            method: "PATCH",
            body: JSON.stringify(data)
        }).then((res) => this.handleResponse(res));
    }

    /**
     * Delete an entity from an id
     * @param path name of the group of entities asked
     * @param id id of the very entity asked
     * @returns Promise<any>
     */
    remove(path: string, id: number | null = null): Promise<any> {
        const url = this._entryPoint + path + "/" + id;
        this.httpOptions.headers.set('Authorization', this.buildBearer());
        return fetch(url, {...this.httpOptions, method: "DELETE"}).then((res: Response) => this.handleResponse(res));
    }

    /**
     * Get a file
     * @param path name of the group of entities asked
     * @param params array of parameters for the query
     * @param id
     * @returns Promise<any>
     */
    getFile(path: string, params: [string, string][] | null = null, id?: number): Promise<any> {
        const itemId = id ? "/" + id : "";
        // @ts-ignore
        const url = this._entryPoint + path + itemId + this.build(params);
        this.httpOptions.headers.set("Authorization", this.buildBearer());
        return fetch(url, this.httpOptions).then((res: any) => {
            if (!res.ok) {
                this.handleError(res);
            }
            if (res.status === 204) {
                return Promise.resolve(true);
            }
            return res.blob()
        });
    }

    buildBearer(): string {
        // return authorization header with jwt token
        const currentUser = authService.getCurrentUser();
        if (currentUser && currentUser.token) {
            return 'Bearer ' + currentUser.token;
        } else {
            return '';
        }
    }

    private handleError(res: Response): Error {
        throw new Error('Une erreur est survenue');
    }

    private handleResponse(res: Response): Promise<any> {
        if (!res.ok) {
            this.handleError(res);
        }
        if (res.status === 204) {
            return Promise.resolve(true);
        }
        return res.json()
    }
}

export default Api;

