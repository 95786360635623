import {Field} from "../../utils/Inputs/Field";
import React from "react";

export default function RiskStepThree({
                                          startDate,
                                          setStartDate
                                      }: { startDate: string, setStartDate: (value: string) => void }) {
    return (
        <Field type='date'
               name='startDate'
               onChange={(e) => setStartDate(e.target.value)}
               value={startDate}>
            Date de début de période d'exposition
        </Field>
    );
}
