import React from 'react'
import { Link } from 'react-router-dom';
import { initPathName } from '../../helpers/Helpers';
import { WarningIcon } from '../../Icons';
import { Routes } from '../routing/Routes';

export default function HelpCSV({ setPath }: { setPath: (e: any) => void }) {
    setPath(initPathName());
    return (
        <div className="h-4/5 p-5 text-lg max-w-4xl">
            <p>Le document doit être présenté de cette manière :</p>
            <ul className="pl-5 mb-5">
                <li>- Une en-tête indiquant les labels de chaque colonne.</li>
                <li>- Une rangée par élément importé, les donnée respectant l'ordre présenté dans l'entête.</li>
            </ul>
            <p>Voici des exemples :</p>
            <div className="flex my-5 items-start">
                <img className="mx-1" src="/import-csv.png" alt="" />
                <img className="mx-1" src="/import-csv-2.png" alt="" />
            </div>
            <p className="my-5">Le séparateur est une virgule, et les données sont toujours entre guillemets.</p>
            <p className="my-5">Il n'y a pas d'ordre particulier à respecter, mais il est important de garder une même cohérence entre l'entête et les données, sinon il peut y avoir une erreur d'import ou des données qui ne seront pas correctement assignés.</p>
            <div className="my-5 flex items-center border-2 rounded-lg p-2"><div className="mx-1"><WarningIcon /> </div><p>Certaines informations sont obligatoire pour l'ajout de données, si certains de ces éléments ne sont pas renseignés, l'export ne fonctionnera pas (ceci est documenté dans le docuemnt de références).</p></div>
            <p className="flex items-end my-5">Pour voir toutes les références de chaque entité qu'il est possible d'importer en csv
                <Link to={Routes.CSV_REFERENCE} className="ml-1 font-semibold text-blue hover:text-blue-light-75" target="_blank">
                    cliquez ici
                </Link>.
            </p>
        </div>
    )
}
