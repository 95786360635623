import {User} from "../models/User";
import {NameType, PathType} from "./PathType";
import {DataColumn} from "../models/DataColumn";
import {Endpoints} from "../services/endpoints";
import {installations, operations, sites, work_units} from "./DataColumns/Columns";
import {Routes} from "../components/routing/Routes";

/**
 * Init the pathname on charge
 * @returns The name of the current page
 */
export function initPathName(): string {
    switch (window.location.pathname) {
        case Routes.COMPANY_DESCRIPTION:
            return "Description Entreprise";
        case Routes.SITES:
            return "Sites";
        case Routes.INSTALLATIONS:
            return "Installations";
        case Routes.WORK_UNIT:
            return "Unité de travail";
        case Routes.OPERATIONS:
            return "Opérations";
        case Routes.SERVICES:
            return "Services et départements";
        case Routes.EMPLOYEES:
            return "Registre du personnel";
        case Routes.CHEMICAL_PRODUCTS:
            return "Produits Chimiques"
        case Routes.COLUMNS_DU:
            return "Colonnes DU"
        case Routes.FAMILY_DANGER:
            return "Famille de dangers"
        case Routes.FAMILY_SUB_DANGER:
            return "Sous-famille de dangers"
        case Routes.COTATION:
            return "Cotation des risques"
        case Routes.COTATION_CONTROLED:
            return "Cotation des risques après maîtrise"
        case Routes.COUPLE_BUBBLES_INFOS:
            return "Infos Bulles"
        case Routes.COUPLE_PROBABILITY_GRAVITY:
            return "Couple probabilité/gravité"
        case Routes.HELP_CSV:
            return "Comment fonctionne l'import CSV ?"
        default:
            return "";
    }
}


/**
 * Init the favorite id for the current entity on DataTable
 * @param path
 * @param user : current user
 * @returns id : the favorite entity's id of the current user
 */
export function resetFavId(path: PathType, user: User): number {
    switch (path) {
        case PathType.SITES:
            return user.favoriteSite?.id;
        default:
            return 0;
    }
}

export function getParentPath(path: PathType): PathType | null {
    switch (path) {
        case PathType.SITES:
            return PathType.USERS;
        case PathType.INSTALLATIONS:
            return PathType.SITES;
        case PathType.WORK_UNITS:
            return PathType.INSTALLATIONS;
        case PathType.OPERATIONS:
            return PathType.WORK_UNITS;
        default:
            return null;
    }
}

export function getName(path: PathType | null): string {
    switch (path) {
        case PathType.SITES:
            return NameType.SITE;
        case PathType.INSTALLATIONS:
            return NameType.INSTALLATION;
        case PathType.WORK_UNITS:
            return NameType.WORK_UNIT;
        case PathType.OPERATIONS:
            return NameType.OPERATION;
        default:
            return NameType.USER;
    }
}

export function getDisplayName(path: PathType | null): string {
    switch (path) {
        case PathType.SITES:
            return "Site";
        case PathType.INSTALLATIONS:
            return "Installation";
        case PathType.WORK_UNITS:
            return "Unité de travail";
        case PathType.OPERATIONS:
            return "Opération";
        case PathType.CHEMICAL_PRODUCTS:
            return "Produit chimique";
        default:
            return "";
    }
}

export function buildUserPath(path: PathType | null): string {
    switch (path) {
        case PathType.SITES:
            return "user.id";
        case PathType.INSTALLATIONS:
            return "site.user.id";
        case PathType.WORK_UNITS:
            return "installation.site.user.id";
        case PathType.OPERATIONS:
            return "workUnit.installation.site.user.id";
        default:
            return "";
    }
}

export function groupBy(arr: any[], property: string | number) {
    return arr.reduce((acc, cur) => {
        acc[cur[property]] = [...(acc[cur[property]] || []), cur];
        return acc;
    }, {});
}

/**
 * // Clean null, undefined and NAN values
 * // Transform relation field into URI for API Platform (ex : family.XXXX: 2 --> api/danger_family/2)
 * // Use getRelationName to know name of relation
 * @param obj : FormData to convert into postData (body in post action)
 */
export function cleanObj(obj: any) {
    for (let propName in obj) {
        if (obj[propName] === null || obj[propName] === 'null' || obj[propName] === undefined || Number.isNaN(obj[propName])) {
            delete obj[propName];
        }
        const relation = propName.split(".");
        if (relation.length > 1) {
            const base = "api/" + getRelationName(relation[0]);
            obj[relation[0]] = base + obj[propName];
            delete obj[propName];
        }
    }
    return obj;
}

// Delete 'action' and 'favorite' type
export function cleanColumns(columns: DataColumn[]): DataColumn[] {
    return columns.filter((column) => column.type !== "favorite" && column.type !== "action");
}

export function addOptionsToSelect(results: any[], columns: DataColumn[] | undefined, key: any) {
    const current = columns && columns.find((column) => column.id === key);
    if (current) current.data = results;
}

export function getRelationName(name: string): string {
    switch (name) {
        case "family":
            return Endpoints.DANGER_FAMILIES + "/";
        case "workUnit":
            return Endpoints.WORK_UNIT + "/";
        default:
            return name + "s/";
    }
}

/**
 * Add data to local storage
 * @param name of the local object
 * @param key of the local object
 * @param value of the local object
 */
export function addToLocalStorageObject(name: string, key: string, value: string): void {
    // Get the existing data
    let existing = localStorage.getItem(name);
    // If no existing data, create an array
    // Otherwise, convert the localStorage string to an array
    existing = existing ? JSON.parse(existing) : {};
    // Add new data to localStorage Array
    // @ts-ignore
    existing[key] = value;
    // Save back to localStorage
    localStorage.setItem(name, JSON.stringify(existing));
}

/**
 * Validate a form if the required values are filled in.
 * @param columns Array of DataColumn
 * @param form Data form values
 * @returns True if all required values are filled in, else return false.
 */
export function formRequirementValidation(columns: DataColumn[] | any, form: any): boolean {
    let isValid = false;
    for (let column of columns) {
        if (column.required) {
            isValid = form[column.id] !== undefined && form[column.id] !== "";
            if (!isValid) break;
        }
    }
    return isValid;
}

export function getFacilitiesColumns(path: string): DataColumn[] | any {
    switch (path) {
        case "operations":
            return operations;
        case "work_units":
            return work_units;
        case "installations":
            return installations;
        case "sites":
            return sites;
        default:
            return null;
    }
}

export interface TabDataColumn {
    [key: string]: DataColumn[];
}

export function addActionToAllTab(object: TabDataColumn, action: DataColumn | undefined): TabDataColumn {
    const temp = {...object};
    Object.keys(temp).forEach((key: string) => {
        action && temp[key].push(action);
    });
    return temp;
}

export function buildDataGridCols(columns: DataColumn[]) {
    let cols: any[] = [];
    columns.forEach((column: DataColumn) => {
        if (!column.hideTh) {
            cols.push({
                field: column.id.split(".")[0],
                headerName: column.label,
                width: column.minWidth,
            });
        }
    });
    return cols;
}

export function buildDataGridRows(data: any) {
    // eslint-disable-next-line array-callback-return
    data.map((item: any) => {
        if (item) {
            Object.values(item).forEach((value) => {
                if (value && typeof value === "object") {
                    item.family = item.family.name;
                }
            });
        }
    });
    return data;
}

export function getValueByReduce(column: DataColumn, row: any) {
    return (column.id).split('.').reduce((value: any, el: string) => value[el] ?? '', row);
}

export function filterData(datas: any[], filterText: string, columns: DataColumn[]): any[] {
    return [...datas].filter((data: any) => {
        if (filterText === "") {
            return true;
        }
        let match = false;
        columns.forEach((column: DataColumn) => {
            if (match) {
                return;
            }
            const value = getValueByReduce(column, data);
            match = value && value.toString().toLowerCase().includes(filterText.toLowerCase());
        });
        return match;
    });
}


/**
 * If the value is a string constitute only with number, return the value as number typed value with parseInt
 * @param value: string
 * @returns value: string | number
 */
// TODO : Clean this function
export function parseIntIfNumber(value: any) {
    if (value.length === value.match(/[123456789]/g)?.length)
        return parseInt(value)
    if (value.length === 0)
        return 0
    return value
}

/**
 * Force the download of a given file
 * @param file
 * @param doctitle
 */
export function download(file: any, doctitle: string): void {
    const fileName = doctitle;
    const url = window.URL.createObjectURL(file);
    // @ts-ignore
    if (navigator.msSaveOrOpenBlob) {
        // @ts-ignore
        navigator.msSaveBlob(file.body, fileName);
    } else {
        const a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
}

/**
 * Return the given array after deleted the element at the given index
 * @param array any[] | Curernt array
 * @param index number[] | Index of the element deleted
 * @returns any[]
 */
export const arrayMinusElement = (array: any[], index: number[]) => {
    let temp = [...array];
    for (let i = 0; i <= index.length; i++) {
        delete temp[index[i]];
    }
    return temp;
}

/**
 * Callback on multiple select to get multiple values in array
 * @param e
 */
export function getMultipleSelectValues(e: any): any[] {
    return Array.from(e.target.selectedOptions, (option: any) => option.value)
}

export function orderPath(path: PathType): boolean {
    return path === PathType.INSTALLATIONS || path === PathType.WORK_UNITS || path === PathType.OPERATIONS;
}

/**
 * Decode UTF8 string
 * Return the string decoded or the string itself if error
 * @param s
 */
export function decode_utf8(s: string) {
    let decoded: string;
    let str = '';
    try {
        //Handle exceptions
        str = s.replace('â€¦)Â', '...)');
        str = str.replace('â€¦)', '...)');
        decoded = decodeURIComponent(escape(str));
    } catch (e) {
        decoded = s;
    }
    return decoded;
}

